import React, {Fragment, useEffect} from "react";
import {Router, Route, Switch} from "react-router-dom"
import CUpsells from "../../ccontainers/cpages/cupsells";
import CDraws from "../../ccontainers/cpages/cdraws";
import CPageContainer from "../../ccontainers/cbasic/cpage-container";
import history from './history';
import CProperty from "../../ccontainers/cpages/cproperty";
import CDraw from "../../ccontainers/cpages/user/draw/cdraw";
import CPchatRoute from "../../ccontainers/cbasic/cpchat-route";
import CDrawRoute from "../../ccontainers/cbasic/cdraw-route";
import Confirm from "./user/confirm/confirm";
import ListConfirm from "./user/list-confirm/list-confirm";
import Confirmed from "./user/confirmed/confirmed";
import { useDispatch } from "react-redux";
import { basic_set_window_size } from "actions/basic";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import CgModal from "custom-components/cg-modal/cg-modal";
import IframeModal from "custom-components/iframe-modal/iframe-modal";
import Cookies from "custom-components/cookies/cookies";
import LoginMultiGuest from "custom-components/LoginMultiGuest";
import RegisterGuest from "custom-components/RegisterGuest";

const Pages = () => {
  const dispatch = useDispatch()
  const handleWindowResize = () => {
    dispatch(basic_set_window_size({
      width: window.innerWidth,
      height: window.innerHeight
    }))
  };

  useEffect(() => {
    // document.title = "デジタルサイン"
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }

  }, [])
  return (
    <Fragment>
      <Router history={history}>
        <Switch>
          <CPageContainer>
            <CPchatRoute>
              <Route exact path="/" component={CUpsells}/>
              <Route exact path="/draws" component={CDraws}/>
              <Route exact path="/draws/:draw_id" component={CProperty}/>
              <Route exact path="/draws/:draw_id/details/:draw_detail_id" component={CProperty}/>
              <Route exact path="/draws/:draw_id/cg_property/:cg_id" component={CgModal}/>
              <Route exact path="/draws/:draw_id/frame_id/:frame_id" component={IframeModal}/>
            </CPchatRoute>
            
            <CDrawRoute>
              <Route exact path="/confirm/:draw_id" component={CDraw}/>
              <Route exact path="/confirm/:draw_id/user_group/:user_group_chat_id" component={CDraw}/>
              <Route exact path="/confirm/:draw_id/user_group/:user_group_chat_id/cg_property/:cg_id" component={CgModal}/>
              <Route exact path="/confirm/:draw_id/details/:draw_detail_id" component={Confirm} />
              <Route exact path="/confirm/:draw_id/details/:draw_detail_id/user_group/:user_group_chat_id" component={Confirm} />
              <Route exact path="/list-confirm/:draw_id" component={ListConfirm} />
              <Route exact path="/confirmed/:draw_detail_id" component={Confirmed} />
              <Route exact path="/confirm/:draw_id/user_group/:user_group_chat_id/frame_id/:frame_id" component={IframeModal}/>
            </CDrawRoute>
            <Route exact path="/cookie/:cookie/draw_cookies/:draw_id/user_group/:user_group_chat_id" component={Cookies}/>
            <Route exact path="/cookie/:cookie/draw_cookies/:draw_id" component={Cookies}/>
            <Route exact path="/room/:room_id" component={LoginMultiGuest}/>
            <Route exact path="/register/room/:room_id" component={RegisterGuest}/>
          </CPageContainer>
        </Switch>
      </Router>
    </Fragment>
  )
};
export default Pages;