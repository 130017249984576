import React from 'react';
import '../../assets/scss/shapespark/shapespark.scss';
import '../../assets/scss/shapespark/webwalk.scss';
// import CustomAccordion from "../../custom-components/CustomAccordion";
import { withRouter } from 'react-router-dom';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
// import CUser from "../../ccontainers/cpages/cuser";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import CDocuments from '../../ccontainers/common/cdocument';
import CDocumentContent from '../../ccontainers/common/cdocument-content';
import { Box, Button, Dialog, Stack, Tooltip } from '@mui/material';
import { clientUrl } from 'constants/utils';
import DropzoneCustom from 'containers/common/dropzoneCustom';
import LinkIcon from '@mui/icons-material/Link';
import SendAndClearRequest from './common/sendAndClearRequest';
import { TAB_DRAW_SCHEDULE, WIDTH_MOBILE } from '../../constants';
import { WIDTH_TABLET } from '../../constants';
import Schedule from './schedule';
import { PCHAT_DOMAIN } from 'constants/setting';
import CombineImageIntoPdf from 'custom-components/CombineImageIntoPdf';
import SidebarRight from 'custom-components/sidebarRight';
import CloseSidebar from 'custom-components/closeSidebar';
import CG3DForm from 'custom-components/CG3DForm/CG3DForm';
import { isMobile } from 'react-device-detect';
import MobileHeaderMenu from 'custom-components/mobile-header-menu/mobile-header-menu';

class Property extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: 'document',
      expanded_child: '',
      schedule_dialog: false,
      isOpenCgModal: false,
      input_url:null,
      isModalCombinePdf: false,
      isOpenIframeModal:false,
      isShowMobileMenu:true,
      isValidUser:false,
    };
  }

  async componentDidMount() {
    const { params } = this.props.match;
    const {history, me} = this.props;
    if (params.draw_id) {
      await this.props.onInit({drawId:params.draw_id, history, me, draw_detail_id: params.draw_detail_id});
    }
  }

  handleChangeExpanded(value) {
    let { expanded } = this.state;
    expanded = expanded === value ? '' : value;
    this.setState({ expanded: expanded });
  }

  handleChangeExpandedChild(value) {
    let { expanded_child } = this.state;
    expanded_child = expanded_child === value ? '' : value;

    this.setState({ expanded_child: expanded_child });
  }

  handleChangeScheduleDialog() {
    const { schedule_dialog } = this.state;
    this.setState({ schedule_dialog: !schedule_dialog });
    this.props.focusIframe();
  }


  render() {
    const { property_sidebar, onChangeSidebar, onChangeClose, focusIframe, onDropFiles, onDragFilesEnter, onDragFilesLeave, windowSize,handlePasteFile,loadUi} =
      this.props;
    const {width} = windowSize
    const isMobile = width < WIDTH_MOBILE ? true : false;
    const isTablet = width > WIDTH_MOBILE && width < WIDTH_TABLET ? true : false;
    const IS_MOBILE = window.innerWidth < WIDTH_MOBILE ? true : false;

    if (!property_sidebar) return <div />;
    const { status } = property_sidebar;
    const action = true;
    return (
          <DropzoneCustom 
          isMultiple={true}
          onDropFiles={(file) => onDropFiles(file, this.props.match.params.draw_id)}
          onDragFilesEnter={onDragFilesEnter}
          onDragFilesLeave={onDragFilesLeave}
          >
          <div id="shapespark"  className={isMobile ? "" : "w-100 position-fixed top-0"}
            ref={(ref) => {
              if (ref) {
                  ref.addEventListener('paste', handlePasteFile);
              }
            }}
          >
            {/* <main
              className={
                'main ' + (property_sidebar.type === 'user' ? 'd-none' : ' ')
              }
            > */}
            <main className='main'>
              { 
                  IS_MOBILE &&
                    <MobileHeaderMenu
                      history={this.props.history}
                      draw_id={this.props.draw_info?.id}
                      cg_id={this.props.drawDetail?.cg_property_id}
                      paramUrl={this.props.match.params}
                      handleOpenCgModal={() => this.setState({isOpenCgModal:true})}
                      isHostSide={true}
                      frame_list={this.props.frame_list}
                      onremoveItem={this.props.handleRemoveFrameItem}
                      // handleOpenIframeModal={() => this.setState({isOpenIframeModal:true})}
                      setIsShowModal={this.props.onCloseQuestion}
                      showMenu={this.state.isShowMobileMenu}
                      handleToggleMenu={()=> this.setState({isShowMobileMenu:!this.state.isShowMobileMenu})}
                    />
                }
              {/* {
                loadUi && 
              } */}
              {this.PropertyIframe()}
              {/* {status ? (
                <span className="close-sidebar">
                  <KeyboardDoubleArrowRightIcon
                    onClick={() => onChangeClose(property_sidebar, true)}
                  />
                </span>
              ) : (
                <span className="show-sidebar">
                  <KeyboardDoubleArrowLeftIcon
                    onClick={() => onChangeSidebar(property_sidebar, 'property')}
                  />
                </span>
              )} */}
    
                {!IS_MOBILE && loadUi && <CloseSidebar />}
                
              <div
                id="sidebar"
                className={'sidebar ' + (action ? '' : 'cursor-no-drop') + (this.props.isShowQuestion ? "question-sidebar":"")}
                onClick={() => focusIframe()}
                style={{
                  ...isMobile ? {} : {height: windowSize.height || window.innerHeight}
                }}
              >
                {this.PropertySidebar()}
              </div>
            </main>
            
          </div>
          
          </DropzoneCustom>
    );
  }

  PropertyHeader() {
    const { onChangeSidebar, property_sidebar } = this.props;
    const { type, status } = property_sidebar;

    const tab1_active = type === 'tab1' && status ? ' tab-link-active' : '';
    const tab2_active = type === 'tab2' && status ? ' tab-link-active' : '';
    const tab3_active = type === 'tab3' && status ? ' tab-link-active' : '';

    return (
      <header className="header">
        <div className="tabs" id="tabs">
          <button
            className={'tab-link border-right' + tab1_active}
            onClick={() => onChangeSidebar(property_sidebar, 'tab1')}
          >
            プレゼンボード
          </button>
          <button
            className={'tab-link border-right' + tab2_active}
            onClick={() => onChangeSidebar(property_sidebar, 'tab2')}
          >
            カラーセレクト
          </button>
          <button
            className={'tab-link' + tab3_active}
            onClick={() => onChangeSidebar(property_sidebar, 'tab3')}
          >
            プロフィール
          </button>
        </div>
      </header>
    );
  }

  PropertyIframe() {
    const { document_info, windowSize,draw_info,me,loadUi } = this.props;
    const {width} = windowSize
    const isMobile = width < WIDTH_MOBILE ? true : false;

    return (

        <div
          className="render d-flex justify-content-around content position-relative"
          id="render"
          style={{height: windowSize.height || window.innerHeight,opacity:`${loadUi ? '1' : '0'}`}}
        >
          {document_info && document_info.id ? (
            <CDocumentContent />
          ) : (
            <div style={{width:"100%"}}>
            {
              draw_info.from_user_id !== me.id && 
              <div style={{background:"#fcdad4",paddingBlock:"8px",border:"2px solid red",color:"red",width:"full",textAlign:"center"}}>
                <p>他のユーザーが作成したプロジェクトです。</p>
              </div>
            }
            <div className="text-center" style={{height:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
              <p style={{ marginBottom: '300px' }}>
                ファイルをアップロードもしくは選択してください
                </p>
            </div>
            </div>
          )}
        </div>
    
    );
  }

  PropertySidebar() {
    const {params} = this.props.match;
    const {
      property_sidebar,
      property_info,
      onChangeOpenRegisterUser,
      draw_info,
      onCopyUrl,
      selectedImageIds,
      windowSize,
      history,
      drawDetail,
      handleCreate3DUser,
      onCloseQuestion,
      loadUi
    } = this.props;
    const roomIsSelected = draw_info?.room_by_user?.find(({id}) => id === draw_info?.room_id);
    const {isModalCombinePdf, isCloseSidebar} = this.state;
    
    const { action } = property_sidebar;

    const create3DUser = (url)=>{
      handleCreate3DUser({url,history})
    }
    const handleCloseModal = ()=>{
      this.setState({isOpenCgModal:false})
    }
    return (
      <div
        className="position-relative property"
        style={{ height: '100vh', overflowY: 'hidden' ,opacity:`${loadUi ? '1':'0'}`}}
        onTouchMove={(e) => e.stopPropagation()}
      >
        <div
          className={
            'list-choose-colors ' + (action ? '' : 'pointer-events-none')
          }
          id="list-choose-colors"
        >
          <div id="choose-color">
            {/*Go bach to property list*/}
            {/*<Accordion>*/}
            {/*<AccordionSummary*/}
            {/*className="ac-parent"*/}
            {/*onClick={() => onChangePropertyList(this.props.history)}*/}
            {/*>*/}
            {/*<Typography className="ac-parent-title"> 物件一覧 </Typography>*/}
            {/*</AccordionSummary>*/}
            {/*</Accordion>*/}

            {/*Bundle data*/}
            {/* {this.Schedule()} */}

            {/*Show material and combos*/}
            {/* {this.MaterialAndCombo()} */}

            {/*Show material and combos*/}

            {/* {property_view.extension_existed ? this.Extension() : ''} */}

            {/*Show popup*/}
            {/* {this.Popup()} */}

            {/*Show profile user*/}
            {/* {this.Profile()} */}

            {/*Show distance*/}
            {/* {action_distance ? this.Distance() : null} */}

            {/*Show save and export data*/}
            {/* {this.SaveAndExport()} */}

            {/*Show filter document*/}
            {/* {this.FilterDocument()} */}
          </div>
        </div>
        {/* Show document */}
        <Accordion
        >
          <AccordionSummary className="ac-parent no-pointer border-bottom-0 btn-color-main" >
            <Typography className={"ac-parent-title "} style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'center'
              }}>
              {property_info.name}
            </Typography>
            <Tooltip placement="bottom" arrow title="チャット画面に移動します">
              <div 
                onClick={() =>{
                  const {id, property_id, property_job_type_id} = draw_info?.room ?? {};
                  if(!id) return alert("お部屋をお選びください")
                  const url = `${PCHAT_DOMAIN}/r/${property_id}/tab-id/${property_job_type_id}/general/${id}`
                  return window.open(url, "_blank")
                }}
              style={{display:"flex",alignItems:"center",marginRight:"10px"}}
              >
                {draw_info?.room?.id && <i className="fa-regular fa-comment-dots cursor-pointer fs-18px"></i>}
              </div>
            </Tooltip>
            
            {draw_info.userAddressBookId && 
            <Tooltip arrow title="お客様向けURLをコピーします">
              <div onClick={() => onCopyUrl(`${clientUrl}/room/${draw_info?.room?.id}`)}
               style={{ 
                    border: "1px solid #fff", 
                    borderRadius: "2px",
                    cursor: "pointer",
                  }}>
                <LinkIcon 
                  style={{
                    transform: "rotate(-45deg)",
                    margin:  "2px 4px",
                  }} 
                />
              </div>
            </Tooltip>}
          </AccordionSummary>
        </Accordion>
       <div className='d-flex'>
        <div className='sidebar-left w-100'>
          {/* {
           process.env.REACT_APP_CURR_ENV !== "production" && this.state.isOpenIframeModal && <CG3DForm isIframe={true} closeModal={()=>this.setState({isOpenIframeModal:false})} addNewFrame={this.props.handleAddNewFame}/>
          } */}
          {this.state.isOpenCgModal && <CG3DForm create3DUser={create3DUser} closeModal={handleCloseModal} addNewFrame={this.props.handleAddNewFame}/>}
          {this.ScheduleContainer()}
          {this.Document()}

          <div className='bottom-accordion-shape'>
              {!selectedImageIds?.length && <SendAndClearRequest />}
              <Button className='btn-under' onClick={() => this.setState({isModalCombinePdf: !isModalCombinePdf})}>PDFファイルダウンロード</Button>
              {!draw_info.userAddressBookId && <Button className='btn-under' onClick={() => onChangeOpenRegisterUser()}>ユーザー招待</Button>}
          </div>

          <CombineImageIntoPdf
            isModalCombinePdf={isModalCombinePdf}
            setModalCombinePdf={() => this.setState({isModalCombinePdf: !isModalCombinePdf})}
          />
        </div>
        {
          true &&
          <div className='sidebar-right'>
            <SidebarRight 
              history={history}
              draw_id={draw_info?.id}
              cg_id={drawDetail?.cg_property_id}
              paramUrl={params}
              handleOpenCgModal={() => this.setState({isOpenCgModal:true})}
              isHostSide={true}
              frame_list={this.props.frame_list}
              onremoveItem={this.props.handleRemoveFrameItem}
              // handleOpenIframeModal={() => this.setState({isOpenIframeModal:true})}
              setIsShowModal={onCloseQuestion}
            />
          </div>
        }
       </div>
      </div>
    );
  }

  Document = () => {
    const { expanded } = this.state;
    const expand = 'document';
    return (
      <Accordion
        expanded={expand === expanded}
        // sx={{ margin: 0, height: "100%" }}
        className={
          this.props.draw_info.userAddressBookId
            ? 'document-sidebar-invited'
            : 'document-sidebar-not-invited'
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="ac-parent"
          onClick={() => this.handleChangeExpanded(expand)}
        >
          <Typography className="ac-parent-title">ファイル</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CDocuments />
        </AccordionDetails>
      </Accordion>
    );
  };

  ScheduleContainer = () => {
    const { expanded } = this.state;
    const {draw_info, windowSize} = this.props;
    const expand = 'schedule';
    const {width} = windowSize;
    const isMobile = width < WIDTH_MOBILE ? true : false;
    return (
      <Accordion
        expanded={expand === expanded}
        className={
          'max-height-draw-schedule'
        }
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="ac-parent"
          onClick={() => this.handleChangeExpanded(expand)}
        >
          <Typography className="ac-parent-title">{isMobile ? 'やる事' : 'やる事・期限'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Schedule draw_id={draw_info.id} room_id={draw_info.room_id} roleUser={TAB_DRAW_SCHEDULE.HOST} />
        </AccordionDetails>
      </Accordion>
    );
  }

  // CG3DForm = () => {
  //   const {drawDetail} = this.props;
  //   const url = window.location.href;
  //   return (
  //     <> 
  //       {
  //         this.state.isOpenCgModal && !drawDetail.cg_property_id && 
  //         <div
  //         className='cg-dialog'
  //         >
  //           <div className='cg-dialog-child'>
  //             <div className='cg-modal'>
  //               <p className='cg-modal-title'>URL :</p>
  //               <div className='cg-modal-form'>
  //                 <input className='cg-modal-input' type='text' placeholder='モデルのURLを入力' onChange={(e)=>{
  //                   this.setState({input_url:e.target.value})
  //                 }}/>
  //                 <button className='cg-modal-confirm' onClick={()=>this.props.handleCreate3DUser(this.state.input_url)}>登録</button>
  //               </div>
  //             </div>
  //             <div className='cg-close' onClick={()=> this.setState({isOpenCgModal:false})}>X</div>
  //           </div>
  // CG3DForm = () => {
  //   const {drawDetail, history} = this.props;
  //   const url = window.location.href;
  //   return (
  //     <>
  //       {
  //         this.state.isOpenCgModal && !drawDetail.cg_property_id && 
  //         <div
  //         className='cg-dialog'
  //         >
  //           <div className='cg-dialog-child'>
  //             <div className='cg-modal'>
  //               <p className='cg-modal-title'>URL :</p>
  //               <div className='cg-modal-form'>
  //                 <input className='cg-modal-input' type='text' placeholder='モデルのURLを入力' onChange={(e)=>{
  //                   this.setState({input_url:e.target.value})
  //                 }}/>
  //                 <button className='cg-modal-confirm' onClick={()=>this.props.handleCreate3DUser({url: this.state.input_url, history})}>登録</button>
  //               </div>
  //             </div>
  //             <div className='cg-close' onClick={()=> this.setState({isOpenCgModal:false})}>X</div>
  //           </div>
          
  //      </div>
  //       }
  //     </>
  //   );
  // }


}

export default withRouter(Property);
