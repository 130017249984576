import { connect } from 'react-redux';
import {
  basic_set_me_draw,
  basic_set_me_step,
  basic_set_open_snackbar,
} from '../../actions/basic';
import {
  checkLoginWithOtherGuest,
  clientUrl,
  createClientDraw,
  getCookie,
  getErrorMessage,
  redirectToLoginPage,
  removeCookie,
  removeHyphenPhoneNumber,
  requestGraphQL,
  setCookie,
} from '../../constants/utils';
import DrawRoute from '../../containers/basic/draw-route';
import { GET_EMAIL_NOT_LOGIN_FOR_DRAW, GET_ME_DRAW } from '../../share/query';
import moment from 'moment';
import { SEND_PASSWORD_DRAW, SEND_SMS_DRAW, SIGN_IN_DRAW } from '../../share/mutation';
import history from 'containers/pages/history';
import { COOKIE_DOMAIN, COOKIE_EXPIRES_IN } from 'constants/setting';
import { SMS_METHOD } from '../../constants';
import withParams from 'containers/pages/withParams';

const x_draw = 'x-token';
const me_draw_default = { step: 1 };

const mapStateToProps = (state) => {
  const { me_draw } = state.basic;
  const me_step = state.basic.me_step;

  return {
    me_draw,
    me_step,
  };
};

const mapDispatchToProps = (dispatch) => {
  const reloadDraw = async ({ expired, meDraw, user_not_login_for_draw }) => {
    if (!expired) return;
    if (meDraw.id !== user_not_login_for_draw.id) {
      const isContinue = await checkLoginWithOtherGuest();
      if (isContinue) {
        removeCookie(x_draw, COOKIE_DOMAIN);
        return window.location.reload();
      }
      // const arrayConfirm = history.location.pathname.split('/');
      history.push('/draws');
      return window.location.reload();
    }
  };

  const checkLogin = async ({draw_id, user_group_chat_id}) => {
    const getTokenDraw = getCookie(x_draw);
    const client = createClientDraw();
    // Not login
    // if(!draw_id.includes('-')) {
    //   return;
    // }
    const { getEmailNotLoginForDraw } = await client.request(
      GET_EMAIL_NOT_LOGIN_FOR_DRAW,
      { draw_id, user_group_chat_id }
    );
    if (!getTokenDraw) {
      const {email, fax, userName, ipPhone, userTel} = getEmailNotLoginForDraw ?? {};
      me_draw_default.email = email;
      me_draw_default.fax = fax;
      me_draw_default.userTel = userTel;
      me_draw_default.userName = userName;
      me_draw_default.ipPhone = ipPhone;
      dispatch(basic_set_me_draw(me_draw_default));
      return;
    }
    // Login
    await client.request(GET_ME_DRAW).then((data) => {
      const { meDraw } = data;
      dispatch(basic_set_me_draw(meDraw));
      if(getEmailNotLoginForDraw?.id !== meDraw?.id) {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: "You don't have permission to access!!" })
        );
        removeCookie('x-token', COOKIE_DOMAIN);
        window.location.reload();
      }
      // const { temporary_date } = meDraw;
      // const milliseconds =
      //   parseInt(moment(temporary_date) - moment()) > 2147483647
      //     ? 2147483647
      //     : parseInt(moment(temporary_date) - moment());
      // if (milliseconds > 0) {
      //   dispatch(basic_set_me_draw(meDraw));
      //   setTimeout(() => {
      //     return reloadDraw({
      //       expired: true,
      //       meDraw,
      //       user_not_login_for_draw: getEmailNotLoginForDraw,
      //     });
      //   }, milliseconds);
      // } else {
      //   return reloadDraw({
      //     expired: true,
      //     meDraw,
      //     user_not_login_for_draw: getEmailNotLoginForDraw,
      //   });
      // }
    })
    .catch((e) => {
      dispatch(
        basic_set_open_snackbar({ t: 'error', m: getErrorMessage(e) })
      );
      removeCookie('x-token', COOKIE_DOMAIN);
    });
  };

  const sendPasswordDraw = ({ me_draw, user_group_chat_id, draw_id }) => {
    const client = createClientDraw();
    client
      .request(SEND_PASSWORD_DRAW, { draw_id, user_group_chat_id })
      .then((data) => {
        dispatch(
          basic_set_open_snackbar({
            t: 'success',
            m: 'ワンタイムパスワードが送信されました',
          })
        );
        // me_draw.step = 2;
        // dispatch(basic_set_me_draw(me_draw));
        dispatch(basic_set_me_step({step: 2}));
      })
      .catch((e) => {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: getErrorMessage(e) })
        );
      });
  };

  const signInDraw = async ({ email, temporary_pwd, finalTel, draw_id, user_group_chat_id }) => {
    const client = createClientDraw();
    await client
      .request(SIGN_IN_DRAW, { email, temporary_pwd, finalTel, draw_id })
      .then((data) => {
        const { signInDraw } = data;
        setCookie(
          x_draw,
          signInDraw.token,
          COOKIE_EXPIRES_IN,
          COOKIE_DOMAIN
        );
        history.push(`/confirm/${draw_id}/user_group/${user_group_chat_id}`);
        window.location.reload();
      })
      .catch((e) => {
        dispatch(
          basic_set_open_snackbar({ t: 'error', m: getErrorMessage(e) })
        );
      });
  };

  const onSendSMS = async ({ draw_id, tel, method, me_draw }) => {
    try {
      const resTel = removeHyphenPhoneNumber(tel);
      await requestGraphQL(SEND_SMS_DRAW, { draw_id, tel: resTel, method });
      if(method === SMS_METHOD.SMS) {
        dispatch(basic_set_open_snackbar({
          t: 'success',
          m: 'SMSに認証コードが送信されました',
        }))
      }
      // me_draw.step = 2;
      // dispatch(basic_set_me_draw(me_draw));
      dispatch(basic_set_me_step({step: 2}));
    } catch(error) {
      throw error;
    }
  }

  const onSetStep = ({step}) => {
    dispatch(basic_set_me_step({step}));
  }

  return {
    checkLogin,
    sendPasswordDraw,
    signInDraw,
    onSendSMS,
    onSetStep,
  };
};

const CDrawRoute = connect(mapStateToProps, mapDispatchToProps)(DrawRoute);
// const CDrawRoute = connect()(DrawRoute);

export default withParams(CDrawRoute);
