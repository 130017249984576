import { Button, FormControl, FormControlLabel, FormLabel, ImageList, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';
import { clearConfirmAction, handleSendMessage, handleSendMessageForGroup, sendConfirmDrawAction, updateUserConfirmDraw } from 'actions/draw';
import { openHOCModalAction } from 'actions/hoc-modal';
import { TEXT_LIST } from '../../../constants';
import { findProcessDrawDetail, processRequestConfirm, requestGraphQL } from 'constants/utils';
import { QRDialog } from 'containers/common/document';
import { ImagePreview } from 'custom-components/KonvaCanvas/Konva.components';
import ProcessLabel from 'custom-components/process-label/process-label';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ROOM_INFO_BY_DRAW_ID } from 'share/query';
import { basic_loading } from 'actions/basic';
import { UPDATE_TEXT_CONFIRM_POSITION } from 'share/mutation';
import ListUserForConfirm from './listUserForConfirm';


const SendAndClearRequest = () => {
  const dispatch = useDispatch();
  const { list } = useSelector((a) => a.document);
  const { info } = useSelector((a) => a.draw);
  const room_select = useSelector(a => a.draw.room_select);
  const user_group_chats = useSelector(a => a.draw.info?.room?.user_group_chats);
  // const arraySplitDraw = window.location.pathname.split('/');
  // const drawId = arraySplitDraw[arraySplitDraw.length - 1];
  // const valueSelectRoom = (room_select || []).find((item) => item.draw_id === drawId);

  const processRequest = findProcessDrawDetail('request');

  const sendList = useMemo(
    () =>
      list.filter(
        (item) =>
          !item.from_confirmed &&
          (item.process === processRequest.type ||
            item.process === processRequestConfirm.type)
      ),
    [list, processRequest, processRequestConfirm]
  );

  const onCheckedValue = async(e,id)=>{
    dispatch(basic_loading(true));
    if(e.target.value && e.target.value.length === 2 && id){
      const value = e.target.value
      try {
        await requestGraphQL(UPDATE_TEXT_CONFIRM_POSITION,{draw_detail_id:id,position:String(value)});
        dispatch(basic_loading(false));
      } catch (error) {
        dispatch(basic_loading(false));
      }
    }
    
  }

    const SellerPreviewDialog = () => {
      const [radioBtn, setRadioBtn] = useState('1');
      const [forceReloadCoppy, setForceReloadCoppy] = useState(0);
      const [listUserMulti, setListUserMulti] = useState([]);
      const [listUserData, setListUserData] = useState([]);
      useEffect(() => {
        const data = sendList?.map((item) => {
          return {
            drawDetailId: item.id,
            user_group_chats: user_group_chats?.map((item) => {
              return {
                ...item,
                checked: false,
              }
            }) ?? []
          }
        });
        setListUserMulti(data);

        const data2 = user_group_chats?.map((item) => {
          return {
            ...item,
            checked: false,
          }
        }) ?? []
        setListUserData(data2);
      }, [user_group_chats, sendList]);


      const listUniqueUserChecked = useMemo(() => {
        let res = [];
        if(radioBtn === "1") {
          return listUserData?.filter(({checked}) => checked);
        } else {
          const dataList = listUserMulti?.map(({user_group_chats}) => {
            const data = user_group_chats?.filter(({checked}) => checked);
            res.push(...data);
          });
          return [...new Map(res.map(item => [item['id'], item])).values()]
        }
      }, [radioBtn, listUserData, listUserMulti]);

      const handleListUserMulti = useCallback(({data, listUserMulti}) => {
        const res = listUserMulti?.map((item) => {
          if(item?.drawDetailId === data?.drawDetailId) {
            return data;
          } else {
            return item;
          }
        })
        setListUserMulti(res);
      }, []);
      const handleListUserData = useCallback((data) => {
        setListUserData(data);
      }, []);

      const checkBeforeRequest = useMemo(() => {
        if(!user_group_chats?.length) return sendList?.length;
        if(radioBtn === "1") {
          const finded = listUserData?.find(({checked}) => checked);
          if(finded) return true;
          return false;
        } else {
          const res = listUserMulti?.map(({user_group_chats}) => {
            return !!user_group_chats?.find(({checked}) => checked);
          }).every((item) => item);
          return res;
        }
        
      }, [radioBtn, listUserData, listUserMulti]);
      return (
        <>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            fontWeight="700"
            textAlign="center"
            marginY={'12px'}
          >
            依頼確認
          </Typography>
          <div className="container">
            {
              !!user_group_chats?.length &&
              <div className='d-flex align-items-center flex-column'>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={radioBtn}
                    name="radio-buttons-group"
                    onChange={(e) => {
                      setRadioBtn(e.target.value)
                    }}
                  >
                    <FormControlLabel value={"1"} control={<Radio />} label="全て依頼" />
                    <FormControlLabel value={"2"} control={<Radio />} label="個別依頼" />
                  </RadioGroup>
                </FormControl>
                <div className='mw-50' style={{minWidth: '30%'}}>
                  {
                    radioBtn === "1" &&
                    <ListUserForConfirm 
                      listUser={listUserData}
                      setListUser={handleListUserData}
                      user_group_chats={user_group_chats}
                      isAll={true}
                    />
                  }
                </div>
              </div>
            }
            
            <Typography>以下の依頼を送信します。</Typography>
            <div
              className="mt-3"
            >
              <ImageList cols={4} gap={15} sx={{ overflowY: 'visible' }}>
                {sendList.map((image, index) => {
                  const { process, base64, elements, id } = image;
                  const processing = findProcessDrawDetail(process);
                  const listUserConfirm = listUserMulti?.find(({drawDetailId}) => id === drawDetailId);
                  return (
                    <ImageListItem key={index}>
                      <ImagePreview src={base64} elements={elements} />
                      {process ? <ProcessLabel processing={processing} /> : null}
                      <div>
                        {
                          radioBtn === "2" && !!user_group_chats?.length && 
                          <ListUserForConfirm 
                            listUser={listUserConfirm?.user_group_chats ?? []}
                            setListUser={(data) => handleListUserMulti({data, listUserMulti})}
                            user_group_chats={user_group_chats}
                            isAll={false}
                            drawDetailId={listUserConfirm?.drawDetailId}
                            listUserMulti={listUserMulti}
                          />
                        }
                      </div>

                      <div style={{display:'flex',gap:'10px',alignItems:'center'}}>
                        <div style={{fontSize:'12px'}}>確認日付の位置</div>
                        <div className='radio-check-time-position' onChange={(e)=>onCheckedValue(e,image.id)}>
                          {/* top left  */}
                          <div style={{display:'flex',alignItems:'center'}} className='radio-check-tl'>
                            <label style={{fontSize:'10px',marginRight:'3px'}} for={`${image.id}左上`}>左上</label>
                            <input type="radio" id={`${image.id}左上`} name={image.id} value="tl" />
                          </div>
                          <div className='radio-line'></div>

                          {/* top right  */}
                          <div style={{display:'flex',alignItems:'center'}} className='radio-check-tr'>
                            <input type="radio" id={`${image.id}右上`} name={image.id} value="tr" />
                            <label style={{fontSize:'10px'}} for={`${image.id}右上`}>右上</label>
                          </div>

                          {/* right bottom  */}
                          <div style={{display:'flex',alignItems:'center',position:'relative'}} className='radio-check-rb'>
                            <input type="radio" id={`${image.id}右下`} name={image.id} value="rb" defaultChecked={true}/>
                             <label style={{fontSize:'10px'}} for={`${image.id}右下`}>右下</label>
                            <div className='radio-ver-line1'></div>
                          </div>
                          <div className='radio-line'></div>

                          {/* left bottom  */}
                          <div style={{display:'flex',alignItems:'center',position:'relative'}} className='radio-check-lb'>
                            <label style={{fontSize:'10px',marginRight:'3px'}} for={`${image.id}左下`}>左下</label>
                            <input type="radio" id={`${image.id}左下`} name={image.id} value="lb" />
                            <div className='radio-ver-line2'></div>
                          </div>
                        </div>
                      </div>
                    </ImageListItem>
                  );
                })}
              </ImageList>
            </div>
            <Typography textAlign="center" marginTop={'15px'}>
              依頼を送信するとお客様がアクセスする依頼確認用のURLが表示されます。
            </Typography>
            <div className="d-flex justify-content-center">
              <Tooltip title={`${checkBeforeRequest ? "" : "依頼先を選択してください"}`}>
              <div
                className={
                  'button-confirm' +
                  (checkBeforeRequest ? '' : ' cursor-no-drop opacity-50')
                }
                onClick={async () => {
                  if (checkBeforeRequest) {
                    const roomData = await requestGraphQL(GET_ROOM_INFO_BY_DRAW_ID, {id: info.id});
                    if(roomData.getRoomInfoByDrawId !== null){
                      if(radioBtn === "1") {
                        for(let list of sendList) {
                          console.log({list})
                          dispatch(updateUserConfirmDraw({ drawDetailId: list?.id, listUser: listUserData}));
                        }
                      } else {
                        for(let item of listUserMulti) {
                          const {drawDetailId, user_group_chats} = item ?? {};
                          dispatch(updateUserConfirmDraw({drawDetailId, listUser: user_group_chats}));
                        }
                      }
                      const result = await dispatch(sendConfirmDrawAction(info.id,true));
                      setForceReloadCoppy(forceReloadCoppy + 1);
                      if (result && result.url)
                      {!user_group_chats?.length && await handleSendMessage(info,result.url,roomData.getRoomInfoByDrawId, room_select,info)}
                      {!!user_group_chats?.length && await handleSendMessageForGroup({link: result.url, room: roomData.getRoomInfoByDrawId, listUniqueUserChecked,draw_info:info})}
                      dispatch(
                        openHOCModalAction(
                          <QRDialog url={result.url} reload={forceReloadCoppy} listUser={listUniqueUserChecked} />
                        )
                      );
                    }else{
                      const result = await dispatch(sendConfirmDrawAction(info.id,true));
                      setForceReloadCoppy(forceReloadCoppy + 1);
                      if (result && result.url)
                      dispatch(
                        openHOCModalAction(
                          <QRDialog url={result.url} reload={forceReloadCoppy} listUser={listUniqueUserChecked} />
                        )
                      );
                    }

                    
                    
                  }
                }}
              >
                {TEXT_LIST.send_request}
              </div>
              </Tooltip>
            </div>
          </div>
        </>
      );
    };
  return (
    <div className='w-100 d-flex justify-content-center'>
      {info.userAddressBookId && sendList.length ? (
        <Button
          variant='outlined'
          className="button_invite"
          onClick={() => {
            dispatch(openHOCModalAction(<SellerPreviewDialog />));
          }}
        >
          <span className="button_invite_label">{TEXT_LIST.send_request}</span>
        </Button>
      ) : (
        <Tooltip
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              {!info.userAddressBookId
                ? '未招待なので依頼送信できません'
                : '要記入・要確認ファイルがありません'}
            </div>
          }
          placement="top"
          arrow
        >
          <Button variant='outlined' className="button_invite send-and-request-unactive">
            <span className="button_invite_label">
              {TEXT_LIST.send_request}
            </span>
          </Button>
        </Tooltip>
      )}
      {sendList.length ? (
        <Button
          variant='outlined'
          className="button_invite"
          onClick={() => {
            dispatch(clearConfirmAction(info.id));
          }}
        >
          <span className="button_invite_label">依頼クリア</span>
        </Button>
      ) : (
        <Button variant='outlined' className="button_invite send-and-request-unactive">
          <span className="button_invite_label">依頼クリア</span>
        </Button>
      )}
    </div>
  );
};
export default SendAndClearRequest;
