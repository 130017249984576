let const_HOST_ADDR;
let const_WS_HOST_ADDR;
let const_COOKIE_DOMAIN;
let const_PCHAT_DOMAIN;
let const_HOST_ADDR_3D;
let const_WS_HOST_ADDR_3D;
let const_COOKIE_EXPIRES_IN;

if (process.env.REACT_APP_CURR_ENV === "production"){
  const_HOST_ADDR = process.env.REACT_APP_HOST_ADDR_PRODUCTION;
  const_WS_HOST_ADDR = process.env.REACT_APP_WS_HOST_ADDR_PRODUCTION;
  const_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
  const_PCHAT_DOMAIN = process.env.REACT_APP_PCHAT_PRODUCTION;
  const_COOKIE_EXPIRES_IN = process.env.REACT_APP_COOKIE_EXPIRES_IN;
  // CG 3D DOMAIM 
  const_HOST_ADDR_3D = process.env.REACT_APP_HOST_ADDR_3D_PRODUCTION;
  const_WS_HOST_ADDR_3D = process.env.REACT_APP_WS_HOST_ADDR_3D_PRODUCTION;
}
else if (process.env.REACT_APP_CURR_ENV === "staging") {
  const_HOST_ADDR = process.env.REACT_APP_HOST_ADDR_STAGING;
  const_WS_HOST_ADDR = process.env.REACT_APP_WS_HOST_ADDR_STAGING;
  const_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
  const_PCHAT_DOMAIN = process.env.REACT_APP_PCHAT_STAGING;
  const_COOKIE_EXPIRES_IN = process.env.REACT_APP_COOKIE_EXPIRES_IN;
  // CG 3D DOMAIM 
  const_HOST_ADDR_3D = process.env.REACT_APP_HOST_ADDR_3D_STAGING;
  const_WS_HOST_ADDR_3D = process.env.REACT_APP_WS_HOST_ADDR_3D_STAGING;
}
else {
  // const_HOST_ADDR = "http://192.168.1.37:4000";
  const_HOST_ADDR = process.env.REACT_APP_HOST_ADDR_DEVELOPMENT;
  const_WS_HOST_ADDR = process.env.REACT_APP_WS_HOST_ADDR_DEVELOPMENT;
  const_COOKIE_DOMAIN = 'localhost';
  const_PCHAT_DOMAIN = process.env.REACT_APP_PCHAT_DEVELOPMENT;
  const_COOKIE_EXPIRES_IN = process.env.REACT_APP_COOKIE_EXPIRES_IN;
  // CG 3D DOMAIM 
  const_HOST_ADDR_3D = process.env.REACT_APP_HOST_ADDR_3D_DEVELOPMENT;
  const_WS_HOST_ADDR_3D = process.env.REACT_APP_WS_HOST_ADDR_3D_DEVELOPMENT;
}

export const HOST_ADDR = const_HOST_ADDR;
export const WS_HOST_ADDR = const_WS_HOST_ADDR;
export const COOKIE_DOMAIN = const_COOKIE_DOMAIN;
export const PCHAT_DOMAIN = const_PCHAT_DOMAIN;
export const COOKIE_EXPIRES_IN = const_COOKIE_EXPIRES_IN;

export const HOST_ADDR_3D = const_HOST_ADDR_3D;
export const WS_HOST_ADDR_3D = const_WS_HOST_ADDR_3D;
