import { Avatar, Box, Modal, Stack, TextField } from '@mui/material';
import {
  processAgreed,
  processCancelRequest,
  processCancelRequestConfirm,
  processConfirmed,
  processRequest,
  processRequestConfirm,
} from 'constants/utils';
import * as React from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const ListUserConfirmedAndUnconfirm = ({
  modalConfirmedAndUnconfirmUser,
  setModalConfirmedAndUnconfirmUser,
  document_info,
}) => {
  const { user_confirm_draws } = document_info ?? {};
  const [listUser, setListUser] = React.useState(user_confirm_draws);
  const [processing, setProcessing] = React.useState('');
  React.useEffect(() => {
    const { process, user_confirm_draws } = document_info ?? {};
    if (process === processRequest.type || process === processAgreed.type) {
      setProcessing('red');
    } else if (
      process === processRequestConfirm.type ||
      process === processConfirmed.type
    ) {
      setProcessing('yellow');
    }
    setListUser(user_confirm_draws ?? []);
  }, [
    document_info,
    processRequest,
    processAgreed,
    processRequestConfirm,
    processConfirmed,
  ]);
  return (
    <Modal
      open={modalConfirmedAndUnconfirmUser}
      onClose={setModalConfirmedAndUnconfirmUser}
    >
      <Stack className="mt-2" sx={{ ...style }}>
        <Box className="d-flex align-items-center justify-content-between">
          <Box className="d-flex align-items-center ">
            <p className="mr-2">依頼先:</p>
            <TextField
              onChange={(e) => {
                const data = user_confirm_draws.filter((item) => {
                  const { user, user_address_book } = item ?? {};
                  if (
                    user?.email.includes(e.target.value) ||
                    user?.name.includes(e.target.value) ||
                    user_address_book?.user_name.includes(e.target.value)
                  ) {
                    return item;
                  }
                });
                if (!e.target.value) {
                  setListUser(user_confirm_draws);
                } else {
                  setListUser(data);
                }
              }}
              style={{ fontSize: '12px' }}
              size="small"
              label="検索"
              variant="outlined"
            />
          </Box>
        </Box>
        <Box sx={{ maxHeight: '200px', overflow: 'scroll' }}>
          {listUser &&
            listUser?.map((item) => {
              const { id, user, user_address_book, to_confirmed, process } = item ?? {};
              const userName = user_address_book?.user_name ?? user?.name;
              return (
                <Box
                  maxHeight={'400'}
                  key={id}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  marginBottom={'5px'}
                >
                  <Avatar
                    className="mr-2"
                    sx={{ width: '30px', height: '30px' }}
                  ></Avatar>
                  <p className="fs-12px mr-2">{userName}</p>
                  <p className="fs-12px mr-2">{user?.email}</p>
                  <p className="w-50px text-center">
                    {/* {to_confirmed
                      ? '済'
                      : processing === 'red'
                      ? '記入中'
                      : '確認中'} */}
                    {process === processCancelRequest.type || process === processCancelRequestConfirm.type ? '差戻し' : ''}
                    {to_confirmed ? '済' : ''}
                  </p>
                </Box>
              );
            })}
        </Box>
      </Stack>
    </Modal>
  );
};
export default React.memo(ListUserConfirmedAndUnconfirm);
