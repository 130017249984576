import { swalFire } from 'constants/swal';
import { requestGraphQL, setCookieXToken } from 'constants/utils';
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CHECK_PERMISSION_USER_ACCESS_TO_DRAW } from 'share/query';
export default function Cookies() {
    const { cookie, draw_id, user_group_chat_id } = useParams();
    useEffect(async () => {
      const {checkPermissionUserAccessToDraw} = await requestGraphQL(CHECK_PERMISSION_USER_ACCESS_TO_DRAW,{draw_id});
      if(!checkPermissionUserAccessToDraw) {
        return swalFire({
          title: `このURLにはアクセスできません。`,
          icon: "warning",
          timer: "99999999",
          showConfirmButton: false,
        })
      }
      if(cookie && draw_id){
        const isSetCookies = setCookieXToken(cookie)
        if(isSetCookies){
            if(user_group_chat_id) {
              window.location.replace(`${window.location.origin}/confirm/${draw_id}/user_group/${user_group_chat_id}`)
            } else {
              window.location.replace(`${window.location.origin}/confirm/${draw_id}`)
            }

        }
        
      }
    }, [cookie, draw_id, user_group_chat_id])
    
  return (
    <div></div>
  )
}
