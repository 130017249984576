import React, { useCallback } from 'react';
import {
  COLOR_TAGS,
  findProcessDrawDetail,
  processAgreed,
  processCancelRequest,
  processCancelRequestConfirm,
  processConfirmed,
} from '../../constants/utils';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import "./document-content.css"
import {
  ModeEditOutlineRounded,
  CheckRounded,
  CloseRounded,
  CallReceivedRounded,
  CircleOutlined,
  CropDinOutlined,
} from '@mui/icons-material';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import NearMeIcon from '@mui/icons-material/NearMe';
import moment from 'moment';
import KonvaCanvas from '../../custom-components/KonvaCanvas/KonvaCanvas';
import { TOOL } from '../../reducers/konva-canvas/konva-canvas';
import IconWarningRed from '../../assets/images/warning_red.png';
import IconWarningYellow from '../../assets/images/warning_yellow.png';
import {
  Box,
  Button,
  DialogContentText,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Pagination,
  Stack,
  Tooltip,
} from '@mui/material';
import './document.css';
import { sortDocuments } from '../../constants/services';
import { WIDTH_MOBILE, WIDTH_TABLET } from '../../constants/index';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { isMobile } from 'react-device-detect';
import CustomProcessCancel from './customProcessCancel';
const checks = [
  {
    title: '住宅設備、建具、 床をCGサンプル・プレゼンシートで確認しました。',
    value: 'agreed1',
  },
  {
    title: '工事確認書 (写) を受領しました。',
    value: 'agreed2',
  },
  {
    title:
      '着工後は、設計変更、 オプション追加などができないことを確認しました。',
    value: 'agreed3',
  },
  {
    title: '着工後、3か月ほどが完成予定日と確認しました。',
    value: 'agreed4',
  },
];

class DocumentContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      confirm_dialog: false,
      color_dialog: false,
      openConfirmList: false,
      fontSize: 16,
      width: window.innerWidth,
      anchorEl: null,
      endUser:"",
      infoIndex: 0,
      isShowNavbar: true,
      isDialogComplain: false,
      complainContent: "",
    };
    this.scrollRef = React.createRef();
  }

  handleWindowResize = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    localStorage.removeItem("draw-color")
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    localStorage.removeItem("draw-color")
  }
 
  handleClickFocus(name) {
    const getId = document.getElementById(name);
    if (getId) {
      window.setTimeout(() => getId.focus(), 0);
      return false;
    }
  }

  handleChangeConfirmDialog() {
    let { confirm_dialog } = this.state;
    confirm_dialog = !confirm_dialog;
    this.setState({ confirm_dialog: confirm_dialog });
  }

  handleChangeColorDialog() {
    let { color_dialog } = this.state;
    color_dialog = !color_dialog;
    this.setState({ color_dialog: color_dialog });
  }

  sortDocumentByDate () {
    const { document_list } = this.props;
    let sortDocument = [];
    for (const document of document_list) {
      const date = moment(document.date).format('YYYY/MM/DD');
      const index = sortDocument.findIndex((s) => s.date === date);
      if (index > -1) {
        sortDocument[index].images.push(document);
      } else {
        sortDocument.push({ date: date, images: [document] });
      }
    }
    const result = sortDocument.map((sortByDate) => {
      return sortDocuments(sortByDate.images, this.props.me.id);
    });

    return result.flat();
  };

  

  myEventHandler = async(event)=>{
    if(event.key === "ArrowLeft" || event.key === 'ArrowRight'){
    const checkConfirm = await this.props.onSaveDocument(this.props.document_info.id)
    if(checkConfirm){
      let currentPage ;
      if(event.key === "ArrowLeft"){
        currentPage = this.state.infoIndex - 1 >= 0 ? this.state.infoIndex - 1 : 0 ;
      }else if(event.key === 'ArrowRight'){
        const maxLength = this.props.document_list.length -1;
        currentPage = this.state.infoIndex + 1 <= maxLength ? this.state.infoIndex + 1 : maxLength ;
      }
      const data = this.sortDocumentByDate()
      this.props.onChangeInfo(data[currentPage])
      this.setState({infoIndex:currentPage})
    }
      
    }
  }

  componentDidMount() {
    this.props.onInit();
    this.getUserAddressBookData()
    localStorage.removeItem("draw-color")
    localStorage.removeItem("draw-tool")
    window.removeEventListener("keydown",this.myEventHandler);
    window.addEventListener("keydown",this.myEventHandler);
    // this.props.getUserAddressBook(id)
  }
  componentDidUpdate(prevProps, prevState){
    
    if(prevProps.document_list !== this.props.document_list || this.props.document_info !== prevProps.document_info){
      window.removeEventListener("keydown",this.myEventHandler);
      window.addEventListener("keydown",this.myEventHandler);
      const index = this.sortDocumentByDate().findIndex((item) => item.id === this.props.document_info.id);
      if (index === -1) return null;
      if(this.state.infoIndex !== index){
        this.setState({infoIndex:index})
      }    
    }
  }

  handleChangeTab(value) {
    this.setState({ tab: value });
  }

  onDropFilesLocal(files) {
    this.props.onDropFiles(this.props.document_list, files);
  }
  /**
   * Show document dialog
   * @returns {XML}
   */
  render() {
    const { document_info } = this.props;
    if (!document_info || !document_info.id) return null;

    const {isShowNavbar} = this.state;

    const {
      onChangeDialog,
      onChangeTool,
      onChangeElementsKonva,
      duplicateDrawDetail,
      document_list,
      onChangeInfo,
      saveDocumentInfoCanvas,
      document_scale,
      changeScale,
      onSaveDrawDetail,
      konva_element,
      document_tool,
      onCloseDrawDetail,
      konva_step,
      changeKonvaStep,
      konva_history,
      me,
      onChangeValue,
      document_draw,
      sub_draw,
      onChangeSubDraw,
      changeSubDrawInfo
    } = this.props;

    
    // window.addEventListener("keydown",(event)=>this.myEventHandler(event,infoIndex,sortDocumentByDate));

    const color = this.getColor();
    const fontSize = this.getFontSize();
    const tab = this.state.tab;
    const drag_active = document_tool === TOOL.DRAG;
    let render_tab = '';
    const processing = findProcessDrawDetail(document_info.process);

    switch (tab) {
      /**
       * @author quyennguyen
       */
      case 0:
        render_tab = (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <div className="d-flex flex-column align-items-center w-100">
                <div
                  ref={this.scrollRef}
                  style={{
                    maxHeight: window.innerHeight - 120,
                    overflow: 'auto',
                    border: '1px solid rgba(0,0,0,0.2)',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                  }}
                  className="w-100 show-scroll-bar"
                >
                  <KonvaCanvas scrollRef={this.scrollRef} />
                </div>
              </div>
            </div>
          </div>
        );
        break;
      default:
        render_tab = (
          <img src={document_info.base64} width={'100%'} alt="Missing file!" />
        );
        break;
    }

    const processRequest = findProcessDrawDetail('request');
    const processRequestConfirm = findProcessDrawDetail('request-confirm');
    const headerBackGroundColor = processing?.color || '#dff2ff';
    const pen_active = document_tool === TOOL.PEN;
    const arrow_active = document_tool === TOOL.ARROW;
    const straight_line_active = document_tool === TOOL.STRAIGHT_LINE;
    const check_active = document_tool === TOOL.CHECK;
    const text_active = document_tool === TOOL.TEXT;
    const square_active = document_tool === TOOL.SQUARE;
    const circle_active = document_tool === TOOL.CIRCLE;


    const renderFileNavbar = ()=>{
      return (
        <>
        {
          Object.keys(sub_draw).length > 0 && 
          <Button variant='contained' className='f-btn-navbar' 
            onClick={() => this.setState({isShowNavbar: !isShowNavbar})}>
            サムネイル <i className={`ml-1 fa-solid fa-chevron-${isShowNavbar ? 'up' : 'down'}`}></i>
          </Button>   
        }
        {
          isShowNavbar && Object.keys(sub_draw).length > 0 && 
          <div className='f-navbar'>
            <ul className='f-list'>
              
            { 
              Object.entries(sub_draw).map((drawItem,index)=>{
                const {url, url_resize, file_alias} = drawItem[1]?.attachment ?? {};
                return (<li key={drawItem[1].id} className={`f-item ${drawItem[1].id === document_info.id && "f-active"}`}>
                  <img onClick={()=>onChangeSubDraw(document_info.id,drawItem[1])} src={url_resize ?? url} alt={file_alias}/>
                </li>)
              })
            }
            </ul>
          </div>
        }
        </>
      )
    }

    const renderFooter = () => {
      const hasProcess =
        document_info.process === processConfirmed.type ||
        document_info.process === processAgreed.type;

      const hasRequest =
        document_info.process === processRequestConfirm.type ||
        document_info.process === processRequest.type;

      const renderProcess = () => {
        return (
          <>
            {!hasProcess && <div style={{ width: isMobile ? 10 : 120 }}></div>}
              {hasProcess && (
                <div
                  className="text-white text-center "
                  style={{
                    backgroundColor: processing.color,
                    border: '2px solid white',
                    width: 120,
                    padding: '5px',
                  }}
                >
                  {processing?.title}
                </div>
              )}
          </>
        )
      }
      return (
        <div
        
          className={`w-100 ${isMobile ? 'canvas-footer-mobile' :'position-absolute'} bottom-0`}
          // className="w-100 position-fixed"
          style={{
            bottom: 0,
            left: 0,
          }}
        >
          {/* {document_info.to_confirmed && (
            <div
              className="text-right font-weight-normal"
              style={{ marginRight: '35px', marginBottom: '10px', color: headerBackGroundColor }}
            >
              {document_draw?.endUserName.length > 0 ? (
                <span className="pr-3 font-weight-bold">
                  {document_draw?.endUserName} 様
                </span>
              ):<span className="pr-3 font-weight-bold">
              {this.state.endUser} 様
            </span>}
              <span className="pr-2">{processing.title}</span>
              {moment(new Date(Date.parse(document_info.processedAt))).format(
                'YYYY-MM-DD HH:mm:ss'
              ) ?? ''}
            </div>
          )} */}
          <div
          
            className={
              `d-flex w-100 align-items-center justify-content-between px-2`
            }
            style={{
              backgroundColor: headerBackGroundColor,
              paddingTop: 10,
              paddingBottom: 10,
              bottom: 0,
              left: 0,
            }}
            id='nav-bottom-host'
          >
            <Box className='d-flex justify-content-between mb-2'>
              <Stack  direction="row" spacing={1}>
                <Stack
                  sx={{
                    width: '40px',
                    height: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border:
                      hasProcess || hasRequest
                        ? '2px solid rgba(255,255,255,0.7)'
                        : '2px solid rgba(0,0,0,0.5)',
                  }}
                  onClick={() => {
                    changeScale(document_scale * 1.2);
                  }}
                >
                  <Tooltip placement="top" arrow title="拡大">
                    <ZoomInIcon
                      className="icon_filter_default"
                      fontSize="large"
                      sx={{
                        color: hasProcess || hasRequest ? '#fff' : '#000',
                      }}
                    />
                  </Tooltip>
                </Stack>

                <Stack
                  sx={{
                    width: '40px',
                    height: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    border:
                      hasProcess || hasRequest
                        ? '2px solid rgba(255,255,255,0.7)'
                        : '2px solid rgba(0,0,0,0.5)',
                  }}
                  onClick={() => {
                    changeScale(document_scale / 1.2);
                  }}
                >
                  <Tooltip placement="top" arrow title="縮小">
                    <ZoomOutIcon
                      className="icon_filter_default"
                      fontSize="large"
                      sx={{
                        color: hasProcess || hasRequest ? '#fff' : '#000',
                      }}
                    />
                  </Tooltip>
                </Stack>
              </Stack>
              {/* {isMobile && renderProcess()} */}
              {/* {renderProcess()} */}
            </Box>
            
            <Pagination
              className="pagination-draw"
              count={this.sortDocumentByDate().length}
              color="primary"
              page={this.state.infoIndex + 1}
              onChange={async(event, page) => {
               const check =await this.props.onSaveDocument(document_info.id)
               if(check){
                saveDocumentInfoCanvas(this.sortDocumentByDate().id);
                onChangeInfo(this.sortDocumentByDate()[page - 1]);
                changeSubDrawInfo(this.sortDocumentByDate()[page - 1])
                this.setState({infoIndex:page - 1})
               }
                
              }}
              size={isMobile ? "medium" : "large"}
              siblingCount={isMobile ? 0 : 1}
            />
            {renderProcess()}
          </div>
        </div>
      );
      // }
      // return null;
    };


    const { width } = this.state;

    const isTablet =
      width > WIDTH_MOBILE && width < WIDTH_TABLET ? true : false;

    let footerHeight = 82;
    let headerHeight = width < WIDTH_TABLET ? 80 : 70;
    const bottomEl = document.getElementById('nav-bottom-host');
    if(bottomEl && width <= WIDTH_MOBILE){
      footerHeight = bottomEl.offsetHeight
    }

    return (
      <div
        className="text-center w-100 position-absolute top-0"
        style={{height: isMobile ? '95%' : '100%'}}

        id="document-content"
      >
        <div
          className="document-dialog position-relative"
          style={{height: isMobile ? '95%' : '100%'}}
          open={true}
          onClose={() => {
            saveDocumentInfoCanvas(document_info.id);
            onChangeDialog();
          }}
        >
          {
           document_draw.from_user_id === me.id ?   
          <DialogTitle
            className="d-flex"
            sx={{
              backgroundColor: headerBackGroundColor,
              // overflowY: "hidden"
            }}
          >
            <div className="div-left">
              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" title="線描画" arrow>
                    <ModeEditOutlineRounded />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (pen_active ? ' icon-active' : '')}
                  onClick={() => {
                    this.handleChangeTab(0);
                    onChangeTool(pen_active ? null : TOOL.PEN);
                  }}
                >
                  <Tooltip placement="top" title="線描画" arrow>
                    <ModeEditOutlineRounded />
                  </Tooltip>
                </div>
              )}

              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" title="矢印" arrow>
                    <CallReceivedRounded />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (arrow_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(arrow_active ? null : TOOL.ARROW);
                  }}
                >
                  <Tooltip placement="top" title="矢印" arrow>
                    <CallReceivedRounded />
                  </Tooltip>
                </div>
              )}

              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" title="矢印" arrow>
                    <HorizontalRuleOutlinedIcon className='skew-icon' />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (straight_line_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(straight_line_active ? null : TOOL.STRAIGHT_LINE);
                  }}
                >
                  <Tooltip placement="top" title="直線" arrow>
                    <HorizontalRuleOutlinedIcon className='skew-icon' />
                  </Tooltip>
                </div>
              )}

              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" title="テキスト" arrow>
                    <span>あ</span>
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (text_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(text_active ? null : TOOL.TEXT);
                  }}
                >
                  <Tooltip placement="top" title="テキスト" arrow>
                    <span>あ</span>
                  </Tooltip>
                </div>
              )}

              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" title="四角" arrow>
                    <CropDinOutlined />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (square_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(square_active ? null : TOOL.SQUARE);
                  }}
                >
                  <Tooltip placement="top" title="四角" arrow>
                    <CropDinOutlined />
                  </Tooltip>
                </div>
              )}

              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" title="丸" arrow>
                    <CircleOutlined />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (circle_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(circle_active ? null : TOOL.CIRCLE);
                  }}
                >
                  <Tooltip placement="top" title="丸" arrow>
                    <CircleOutlined />
                  </Tooltip>
                </div>
              )}

              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" arrow title="確認スタンプ">
                    <CheckRounded />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (check_active ? ' icon-active' : '')}
                  onClick={() => {
                    onChangeTool(check_active ? null : TOOL.CHECK);
                  }}
                >
                  <Tooltip placement="top" arrow title="確認スタンプ">
                    <CheckRounded />
                  </Tooltip>
                </div>
              )}

              <div
                className={'icon' + (drag_active ? ' icon-active' : '')}
                onClick={() => {
                  onChangeTool(drag_active ? null : TOOL.DRAG);
                }}
              >
                <Tooltip placement="top" title="選択" arrow>
                  <NearMeIcon
                    style={{ WebkitTransform: 'scaleX(-1)' }}
                    className={
                      drag_active ? 'text-primary' : 'icon_filter_default'
                    }
                  />
                </Tooltip>
              </div>

              <div style={{ maxWidth: '25px', height: '25px' }}>
                <div
                  className={'icon ' + (tab === 4 ? ' icon-active' : '')}
                  style={{ maxWidth: '25px', backgroundColor: localStorage.getItem("draw-color") ? localStorage.getItem("draw-color") : color }}
                  onClick={() =>
                    document_info.process ? '' : this.handleChangeColorDialog()
                  }
                ></div>
                {this.colorDialog()}
              </div>

              <Stack
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  marginLeft: '5px',
                }}
              >
                <Input
                  type="number"
                  size="md"
                  defaultValue={16}
                  value={fontSize}
                  disabled={
                    document_info.process || document_info.from_confirmed
                      ? true
                      : false
                  }
                  slotProps={{
                    input: {
                      min: 1,
                      max: 100,
                      step: 1,
                    },
                  }}
                  onChange={(e) => {
                    if (e?.target?.value) {
                      onChangeValue(document_info, 'fontSize', e.target.value);
                    }
                  }}
                  sx={{
                    input: {
                      padding: 0,
                    },
                  }}
                />
              </Stack>
              <div className="ms-2"></div>
              {/* undo */}
              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" arrow title="元に戻す">
                    <UndoIcon />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (check_active ? ' icon-active' : '')}
                  onClick={() => {
                    changeKonvaStep(konva_step ? konva_step - 1 : 0);
                  }}
                >
                  <Tooltip placement="top" arrow title="元に戻す">
                    <UndoIcon />
                  </Tooltip>
                </div>
              )}

              {/* redo */}
              {document_info.process || document_info.from_confirmed ? (
                <div className={'icon icon-disable'}>
                  <Tooltip placement="top" arrow title="やり直す">
                    <RedoIcon />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={'icon' + (check_active ? ' icon-active' : '')}
                  onClick={() => {
                    changeKonvaStep(
                      konva_step < konva_history.length - 1
                        ? konva_step + 1
                        : konva_step
                    );
                  }}
                >
                  <Tooltip placement="top" arrow title="やり直す">
                    <RedoIcon />
                  </Tooltip>
                </div>
              )}

              <Tooltip title="画像の複製ツールボタン">
                <Stack
                  onClick={(e) =>
                    this.setState({ ...this.state, anchorEl: e.currentTarget })
                  }
                  className="cursor-pointer"
                >
                  <MoreHorizIcon />
                </Stack>
              </Tooltip>

              <Menu
                anchorEl={this.state.anchorEl}
                open={!!this.state.anchorEl}
                onClose={() => this.setState({ ...this.state, anchorEl: null })}
                autoFocus={false}
              >
                <MenuItem>
                  <Stack
                    className={'icon icon-text'}
                    onClick={() => duplicateDrawDetail(document_info.id)}
                    style={{
                      fontSize: isTablet ? '11px' : '',
                    }}
                  >
                    元画像複製
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack
                    className={'icon icon-text'}
                    onClick={() => duplicateDrawDetail(document_info.id, true)}
                    style={{
                      fontSize: isTablet ? '11px' : '',
                    }}
                  >
                    編集後複製
                  </Stack>
                </MenuItem>
              </Menu>
            </div>

            <div className="div-right">
              {document_info.from_confirmed ? (
                <FormControlLabel
                  className="icon-disable"
                  control={
                    <Checkbox
                      checked={
                        document_info.process === processRequest.type ||
                        document_info.process === processAgreed.type
                      }
                      className="opacity-50"
                      disabled={true}
                    />
                  }
                  label={
                    <span className='font-weight-bold'
                    >
                      {processRequest.title}
                    </span>
                  }
                  sx={{
                    height: 25,
                  }}
                />
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={document_info.process === processRequest.type}
                      onChange={() => {
                        onChangeInfo({
                          ...document_info,
                          elements: konva_element,
                          change_process: true,
                          process:
                            document_info.process === processRequest.type
                              ? null
                              : processRequest.type,
                        });
                        onChangeTool(TOOL.DRAG);
                      }}
                    />
                  }
                  label={
                    <span className='font-weight-bold'
                    >
                      {processRequest.title}
                    </span>
                  }
                  sx={{ height: 25 }}
                />
              )}

              {document_info.from_confirmed ? (
                <FormControlLabel
                  className="icon-disable"
                  control={
                    <Checkbox
                      checked={
                        document_info.process === processRequestConfirm.type ||
                        document_info.process === processConfirmed.type
                      }
                      className="opacity-50"
                      disabled={true}
                    />
                  }
                  label={
                    <span className='font-weight-bold'
                    >
                      {processRequestConfirm.title}
                    </span>
                  }
                  sx={{ height: 25 }}
                />
              ) : (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        document_info.process === processRequestConfirm.type
                      }
                      onChange={() => {
                        onChangeInfo({
                          ...document_info,
                          elements: konva_element,
                          change_process: true,
                          process:
                            document_info.process === processRequestConfirm.type
                              ? null
                              : processRequestConfirm.type,
                        });
                        onChangeTool(TOOL.DRAG);
                      }}
                    />
                  }
                  label={
                    <span className='font-weight-bold'
                    >
                      {processRequestConfirm.title}
                    </span>
                  }
                  sx={{ height: 25 }}
                />
              )}

              {document_info.process || document_info.from_confirmed ? (
                <Stack
                  className={'icon icon-text icon-disable'}
                  style={{
                    fontSize: isTablet ? '11px' : '',
                  }}
                >
                  {' '}
                  保存
                </Stack>
              ) : (
                <Stack
                  className={
                    'icon icon-text' + (tab === 7 ? ' icon-active' : '')
                  }
                  onClick={() => {
                    onSaveDrawDetail(document_info.id);
                    // onChangeTool();
                  }}
                  style={{
                    fontSize: isTablet ? '11px' : '',
                  }}
                >
                  保存
                </Stack>
              )}

              <div
                className="icon"
                onClick={() => {
                  onCloseDrawDetail(document_info);
                }}
              >
                <CloseRounded />
              </div>
            </div>
          </DialogTitle>
          : <div style={{background:"#fcdad4",paddingBlock:"8px",border:"2px solid red",color:"red"}}>
            <p>他のユーザーが作成したプロジェクトです。</p>
          </div>
          }
          <DialogContent
            style={{
              color: color,
              // height: 'calc(100vh - 60px)',
            }}
            className="m-0"
          >
            {render_tab}
            {/* {document_info.to_confirmed && ( */}
            <div
              className="text-right font-weight-normal"
              style={{ marginRight: '35px', marginBottom: '10px', color: headerBackGroundColor,position:"absolute" , top:`${document_info.text_position === 'tl' || document_info.text_position === 'tr'?`${headerHeight}px`:'unset'}`,bottom:`${document_info.text_position === 'rb' || document_info.text_position === 'lb'?`${footerHeight}px`:'unset'}`,left:`${document_info.text_position === 'lb' || document_info.text_position === 'tl'?`8px`:'unset'}`,right:`${document_info.text_position === 'rb' || document_info.text_position === 'tr'?`8px`:'unset'}`}}
            >
              {
                !!document_info?.user_confirm_draws ?
                document_info?.user_confirm_draws?.map((item) => {
                  let isWarningRed = false;
                  const {to_confirmed, processedAt, user, user_address_book, process, complain} = item ?? {};
                  const userName = user_address_book?.user_name ?? user?.name;
                  if(process === processCancelRequest.type) isWarningRed = true;
                  if(process === processCancelRequestConfirm.type) isWarningRed = false;
                  const processing = findProcessDrawDetail(process);
                  if(process === processAgreed.type || process === processConfirmed.type || process === processCancelRequest.type || process === processCancelRequestConfirm.type) {
                    return (
                      <div className="d-flex align-items-center">
                        <span className="pr-3 font-weight-bold">
                          {userName} 様
                        </span>
                        <span className="pr-2">{processing?.title}</span>
                        {
                          (process === processCancelRequest.type || process === processCancelRequestConfirm.type) &&
                          <Tooltip title={"コメント内容"} placement="top">
                            <span className="pr-2 cursor-pointer" 
                              onClick={() => this.setState({isDialogComplain: !this.state.isDialogComplain, complainContent: complain})}>
                              <img
                                src={isWarningRed ? IconWarningRed : IconWarningYellow}
                                alt="warning"
                                width={20}
                              />
                          </span>
                          </Tooltip>
                        }
                        <span>
                          {moment(new Date(Date.parse(processedAt))).format(
                            'YYYY-MM-DD HH:mm:ss'
                          ) ?? ''}
                        </span>
                      </div>
                    )
                  }
                })
                :
                <>
                  {
                    document_draw?.endUserName?.length > 0 ? (
                      <span className="pr-3 font-weight-bold">
                        {document_draw?.endUserName} 様
                      </span>
                    )
                    :
                    <span className="pr-3 font-weight-bold">
                      {this.state.endUser} 様
                    </span>
                  }
                  <span className="pr-2">{processing?.title}</span>
                  {moment(new Date(Date.parse(document_info.processedAt))).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ) ?? ''}
                </>
              }
            </div>
          {/* )} */}
          </DialogContent>
          {renderFooter()}
        </div>
        {this.documentConfirmDialog()}
        
        {renderFileNavbar()}

        {this.dialogComplain()}
      </div>
    );
  }

  documentDescription() {
    const { document_info, onChangeValue } = this.props;
    if (!document_info) return;
    const { description } = document_info;
    const color = this.getColor();

    return (
      <textarea
        id="area-description"
        rows={20}
        className="p-2"
        placeholder="確認する内容をご記載ください"
        style={{
          width: '100%',
          height: '100%',
          color: color,
          padding: '0.5rem',
        }}
        value={description || ''}
        onClick={() => this.handleClickFocus('area-description')}
        onChange={(e) =>
          onChangeValue(document_info, 'description', e.target.value)
        }
      />
    );
  }

  /**
   * Show document confirm dialog
   * @returns {XML}
   */
  documentConfirmDialog() {
    const { confirm_dialog } = this.state;
    if (!confirm_dialog) return null;

    const { onChangeValue, document_info, onChangeConfirm } = this.props;
    const { text_confirm } = document_info;

    return (
      <div className="document-confirm">
        <Dialog className="document-confirm-dialog" open={confirm_dialog}>
          <DialogContent className="text-center">
            <h6>同意依頼を行いますか？</h6>
            <br />
            <textarea
              id="area-confirm"
              rows={7}
              placeholder="確認する内容をご記載ください"
              className="p-2"
              value={text_confirm || ''}
              onClick={() => this.handleClickFocus('area-confirm')}
              onChange={(e) =>
                onChangeValue(document_info, 'text_confirm', e.target.value)
              }
            />
            <br />
            <br />
            <div className="text-center">
              <button
                className="float-left"
                onClick={() => this.handleChangeConfirmDialog()}
              >
                キャンセル
              </button>

              <button
                className="float-right"
                onClick={() => onChangeConfirm(document_info, text_confirm)}
              >
                同意依頼
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  /**
   * Show or hide color dialog
   */
  colorDialog() {
    const { color_dialog } = this.state;
    const { document_info, onChangeValue } = this.props;

    if (!document_info || !color_dialog) return null;

    const color = this.getColor();

    return (
      <div className={'color-dialog'}>
        <div className="close">
          <span onClick={() => this.handleChangeColorDialog()}>×</span>
        </div>
        <p className="text-center">カラー選択</p>
        <ul>
          {COLOR_TAGS.map((color_tag, key) => {
            return (
              <li key={key}>
                <span
                  style={{
                    backgroundColor: color_tag ? color_tag : color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: color_tag === 'transparent' ? '1px solid #000' : 0,
                  }}
                  onClick={() => {
                    localStorage.setItem("draw-color", color_tag);
                    onChangeValue(document_info, 'color', color_tag);
                  }}
                >
                  {/* {
                    localStorage.getItem("draw-color") ?
                  } */}
                  {localStorage.getItem("draw-color") && localStorage.getItem("draw-color") === color_tag ? (
                    <CheckRounded
                      className={
                        color_tag === 'transparent' ? 'text-dark' : 'text-white'
                      }
                      fontSize="small"
                      alt="icon checked"
                    />
                  ) :
                  <>
                  {
                  !localStorage.getItem("draw-color") && color === color_tag ? (
                      <CheckRounded
                        className={
                          color_tag === 'transparent' ? 'text-dark' : 'text-white'
                        }
                        fontSize="small"
                        alt="icon checked"
                      />
                    ):null
                  }
                  </>
                  }
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  /**
   * Show form agreed
   */
  documentAgreed() {
    const { document_info, onChangeAgreed } = this.props;
    if (!document_info) return;
    const { agreed } = document_info;

    return (
      <div className="document-agreed">
        <h6 className="text-center p-2">打合せ確認書</h6>
        下記項目について確認しました。
        <br />
        <FormGroup className="check-list">
          {checks.map((check, key) => {
            const checked = !!(agreed && agreed[check.value]);
            return (
              <FormControlLabel
                key={key}
                control={<Checkbox color="default" checked={checked} />}
                label={check.title}
                onChange={(e) =>
                  onChangeAgreed(document_info, check.value, e.target.checked)
                }
              />
            );
          })}
        </FormGroup>
        <div className="text-1">完成予定日 令和 年 月 日 頃</div>
        <br />
        今和 年 月 日
        <div className="w-100 d-inline-flex text-2">
          <div className="w-50 align-self-center text-right">施主樣氏名</div>
          <div className="w-50 align-self-center text-center">山田太郎</div>
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }

  getColor() {
    const { document_info } = this.props;
    return document_info && document_info.color ? document_info.color : '#000';
  }

  // getStroke() {
  //   const { document_info } = this.props;
  //   return document_info && document_info.stroke
  //     ? document_info.stroke
  //     : '#000';
  // }
  getFontSize() {
    const { document_info } = this.props;
    return document_info && document_info?.fontSize
      ? document_info.fontSize
      : 16;
  }
  async getUserAddressBookData(){
    const endUserName = await this.props.getUserAddressBook()
    this.setState({...this.state, endUser : endUserName})
  }

  dialogComplain() {
    const {isDialogComplain, complainContent} = this.state;
    const handleClose = () => {
      this.setState({isDialogComplain: !isDialogComplain, complainContent: ""});
    };
    return (
      <Dialog
        open={isDialogComplain}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{minWidth: '300px', minHeight: '50px', textAlign: 'center'}}>
          <DialogContentText id="alert-dialog-description">
            {complainContent}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    )
  }
}

export default DocumentContent;
