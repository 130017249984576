export const BASIC_LOADING = 'BASIC_LOADING';
export const BASIC_LOADING_STATUS = 'BASIC_LOADING_STATUS';
export const BASIC_FIRST_LOAD = 'BASIC_FIRST_LOAD';
export const BASIC_SNACKBAR = 'BASIC_SNACKBAR';
export const BASIC_OPEN_SNACKBAR = 'BASIC_OPEN_SNACKBAR';
export const BASIC_CLOSE_SNACKBAR = 'BASIC_CLOSE_SNACKBAR';
export const BASIC_SET_ME = 'BASIC_SET_ME';
export const BASIC_ME = 'BASIC_ME';
export const BASIC_ME_DRAW = 'BASIC_ME_DRAW';
export const BASIC_ME_STEP = 'BASIC_ME_STEP';
export const BASIC_SET_WINDOW_SIZE = 'BASIC_SET_WINDOW_SIZE';
export const BASIC_MODAL_QUESTION = 'BASIC_MODAL_QUESTION';

export const UPSELL_INFO = 'UPSELL_INFO';

/**
 * @author quyengnguyen
 */
export const PROPERTY_SIDEBAR = 'PROPERTY_SIDEBAR';
export const PROPERTY_LIST = 'PROPERTY_LIST';
export const PROPERTY_INFO = 'PROPERTY_INFO';
export const PROPERTY_VIEW = 'PROPERTY_VIEW';
export const DRAG_FILE = 'DRAG_FILE';

export const DOCUMENT_FILTER = 'DOCUMENT_FILTER';
export const DOCUMENT_LIST = 'DOCUMENT_LIST';
export const DOCUMENT_LIST_STORAGE= 'DOCUMENT_LIST_STORAGE';
export const DOCUMENT_INFO = 'DOCUMENT_INFO';
export const DOCUMENT_DIALOG = 'DOCUMENT_DIALOG';

export const KONVA_INIT = 'KONVA_INIT';
export const KONVA_ELEMENT = 'KONVA_ELEMENT';
export const KONVA_ELEMENTS = 'KONVA_ELEMENTS';
export const KONVA_CHANGE_TOOL = 'KONVA_CHANGE_TOOL';
export const KONVA_ADD_TEXT = 'KONVA_ADD_TEXT';
export const KONVA_CHANGE_ELEMENT = 'KONVA_CHANGE_ELEMENT';
export const KONVA_CHANGE = 'KONVA_CHANGE';
export const KONVA_CHANGE_STEP = 'KONVA_CHANGE_STEP';
export const KONVA_CHANGE_HISTORY = 'KONVA_CHANGE_HISTORY';
export const KONVA_REFRESH_POSITION = 'KONVA_REFRESH_POSITION';
export const KONVA_SCALE_SCREEN = 'KONVA_SCALE_SCREEN';
export const KONVA_IS_CHANGED = 'KONVA_IS_CHANGED';

export const SUB_DRAW_INFO = 'SUB_DRAW_INFO';
export const DRAW_FRAME_LIST = 'DRAW_FRAME_LIST';
export const DRAW_LIST = 'DRAW_LIST';
export const DRAW_INFO = 'DRAW_INFO';
export const DRAW_FILTER = 'DRAW_FILTER';
export const DRAW_PAGING = 'DRAW_PAGING';
export const DRAW_REGISTER_DRAW = 'DRAW_REGISTER_DRAW';
export const DRAW_REGISTER_USER = 'DRAW_REGISTER_USER';
export const DRAW_SELECT_IMAGES = "DRAW_SELECT_IMAGES";
export const ROOM_SELECT = "ROOM_SELECT";
export const ROOM_SELECT_LIST = "ROOM_SELECT_LIST";

export const CLOSE_HOC_MODAL = 'CLOSE_HOC_MODAL';
export const CHANGE_HOC_MODAL = 'CHANGE_HOC_MODAL';

export const DRAW_DETAIL = 'DRAW_DETAIL';
export const CONFIRM_LIST = 'CONFIRM_LIST';
export const SET_CONFIRM_ID = 'SET_CONFIRM_ID';
export const SET_OPEN_CONFIRM_NOTIFICATION = 'SET_OPEN_CONFIRM_NOTIFICATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const GET_ALL_USER_BELONG_DRAW_CONST = 'GET_ALL_USER_BELONG_DRAW_CONST';
export const GET_LIST_DRAW_SCHEDULE_CONST = 'GET_LIST_DRAW_SCHEDULE_CONST';
export const STATUS_LIST_DRAW_SCHEDULE = 'STATUS_LIST_DRAW_SCHEDULE';

export const USER_CONFIRM_DRAWS = 'USER_CONFIRM_DRAWS';