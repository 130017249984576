import { BASIC_ME_STEP } from '../../constants/store';

export const me_step = (state = { step: 1 }, action) => {
  switch (action.type) {
      case BASIC_ME_STEP:
      return action.me_step === null ? null : Object.assign({}, action.payload);
    default:
      return state;
  }
};
