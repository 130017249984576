import React,{ memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CallReceivedRounded,
  CheckRounded,
  CircleOutlined,
  CropDinOutlined,
  ModeEditOutlineRounded,
  SquareOutlined,
} from '@mui/icons-material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import NearMeIcon from '@mui/icons-material/NearMe';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
  Button,
  Input,
  Pagination,
  PaginationItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  setConfirmedIdAction,
  setListConfirmAction,
} from '../../../../actions/confirm';
import {
  confirmDrawByUserAction,
  sub_draw_set_info,
  userGetDrawDetailsAction,
} from '../../../../actions/draw';
import { userGetDrawDetailByIdAction } from '../../../../actions/draw-detail';
import { TEXT_LIST, WIDTH_MOBILE, WIDTH_TABLET } from '../../../../constants';
import { swalRollBackFile , swalQuestion ,swalPopupRuleConfirm} from '../../../../constants/swal';
import {
  COLOR_TAGS,
  processAgreed,
  processCancelRequest,
  processCancelRequestConfirm,
  processConfirmed,
  processRequest,
  processRequestConfirm,
  resetRoute,
  userRequestGraphQL,
} from '../../../../constants/utils';
import { TOOL } from '../../../../reducers/konva-canvas/konva-canvas';
import { ImageConfirm } from './confirm.components';
import './confirm.scss';
import { document_set_info } from 'actions/document';
import { changeElementKonvaAction } from 'actions/konva-canvas';
import BACK_ICON from '../../../../assets/images/back-icon.png';

const Confirm = memo(() => {
  const { draw_detail_id, draw_id } = useParams();
  const historyRoute = useHistory();
  const isMobile = useMediaQuery(`(max-width:${WIDTH_MOBILE}px)`);
  const isTablet = useMediaQuery(`(max-width:${WIDTH_TABLET}px)`);

  const drawDetail = useSelector((a) => a.drawDetail);
  const document_list = useSelector((a) => a.document).list;
  const { list: confirmList, confirmedId } = useSelector((a) => a.confirm);
  const me = useSelector((a) => a.basic.me ?? a.basic.me_draw);
  const dispatch = useDispatch();

  const [isShowNavbar, setShowNavbar] = useState(true);
  const [current, setCurrent] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [listSubDrawChange, setListSubDrawChange] = useState([]);
  const [scale, setScale] = useState(1.2);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [limitZoomIn, setLimitZoomIn] = useState(false);
  const [sizeCanvas, setSizeCanvas] = useState({
    width:0,
    height:0
  });
  const [tool, setTool] = useState(TOOL.DRAG);
  const [fontSize, setFontSize] = useState(16);
  const [elementActive, setElementActive] = useState();
  const scrollRef = useRef()

  // feature undo and redo
  const [historys, setHistory] = useState([]);
  const [step, setStep] = useState(0);
  const initCanvas = useCallback(() => {
    setStep(0);
    setHistory([
      {
        elementsCanvas,
        stageX: 0,
        stageY: 0,
        stageScale: 1,
      },
    ]);
  }, []);

  const [isOpenPopup, setisOpenPopup] = useState(true);

  let elementsCanvas = historys[step]?.elementsCanvas ?? [];
  const [open, setOpen] = useState(false);
  const [colorDialog, setColorDialog] = useState(false);
  const popupColorRef = useRef(null);
  let document_info = useSelector((a) => a.document.info);
  const user_group_chats = useSelector((a) => a.draw?.info?.room?.user_group_chats);
  const userPrimary = user_group_chats?.find((item) => item?.user?.id === me?.id) ?? me;
  const color =
  document_info && document_info.color ? document_info.color : '#000';
  const {
    element,
    history
  } = useSelector((a) => a.konvaCanvas);
  const {sub_draw} = useSelector((a) => a.draw);
  const [currentSubDraw, setCurrentSubDraw] = useState({});
  const konvaElements = useMemo(
    () => history[step]?.elements ?? [],
    [history, step]
  );
  const setElementCanvas = (payload) => {
    const previousStep = historys[step];
    const { newStep, ..._ } = payload;
    const currentStep = payload.newStep ? step + 1 : step;
    const thisStep = {
      ...previousStep,
      ..._,
    };
    let newHistory = historys.slice(0, currentStep);
    newHistory = [...newHistory, thisStep];
    setStep(currentStep);
    setHistory(newHistory);
  };

  const requestConfirmList = useMemo(() => {
    if (confirmList.length) return confirmList;

    let result = document_list?.map((item) => {
      if(!!item?.user_confirm_draws?.length) {
        if(item.from_confirmed) {
          if(item?.user_confirm_draws?.find((t) => (t?.user?.id === me?.id && !t?.to_confirmed && t?.process !== processCancelRequest.type && t?.process !== processCancelRequestConfirm.type))) {
            return item;
          }
        }
      } else {
        if(item.from_confirmed && !item.to_confirmed && item?.process !== processCancelRequest.type && item?.process !== processCancelRequestConfirm.type) {
          return item;
        }
      }
    })
    result = result?.filter((item) => item !== undefined)
    result = result.map((item) => {
      return {
        ...item,
        elementsCanvas: [],
      };
    });
    return result;
  }, [document_list, confirmList, processCancelRequest, processCancelRequestConfirm, me]);

  const checkConfirmed = useMemo(() => {
    return requestConfirmList[current] ? requestConfirmList[current].confirmed : false
  }, [requestConfirmList, current]);


  const renderProcessCancel = () => {
    if(requestConfirmList[current]?.process === processRequest?.type || requestConfirmList[current]?.process === processAgreed?.type || requestConfirmList[current]?.process === processCancelRequest?.type) {
      return processCancelRequest.type;
    } else if(requestConfirmList[current]?.process === processRequestConfirm?.type || requestConfirmList[current]?.process === processConfirmed?.type || requestConfirmList[current]?.process === processCancelRequestConfirm?.type) {
      return processCancelRequestConfirm.type;
    }
  };

  const confirmText = (elementsCanvas) => {
    if(!elementsCanvas?.length) return true;
    let checkTextNotNull = false;
    const item = elementsCanvas?.filter((item) => {
      if(item.type === TOOL.TEXT && item?.text === '') {
        return item
      }else{
        checkTextNotNull = true
      }
    })
    if(checkTextNotNull && item?.length > 0) return 0;
    return item?.length;
  }

  const transferPageFromRequestConfirmList = useMemo(() => {
    const result = requestConfirmList.map((item, index) => {
      return { ...item, page: index + 1 };
    });
    return result;
  }, [requestConfirmList]);

  const checkDrawDetail = requestConfirmList.find(
    (item) => item.id === draw_detail_id
  );
  if (!checkDrawDetail && requestConfirmList.length) {
    if(userPrimary?.user) {
      resetRoute(`/confirm/${draw_id}/user_group/${userPrimary?.id}`);
    } else {
      resetRoute(`/confirm/${draw_id}`);
    }
  }

  const resetState = useCallback(() => {
    setScale(1);
    setStageX(0);
    setStageY(0);
    initCanvas();
  }, []);

  useEffect(() => {
    if(isOpenPopup){
      swalPopupRuleConfirm({
        html: `<div class="popup-cancel-title">【重要】確認・記入について</div><p class="popup-rule-text-first">「確認依頼」「記入依頼」それぞれのデータを確認して、次の操作を行ってください</p><p class="popup-rule-text">・「確認依頼」⇒「確認完了」をクリック</p><p class="popup-rule-text">・「記入依頼」⇒「サイン」を入力もしくは記入して「記入完了」をクリック</p>
        <p class="popup-rule-text">・「差戻し」⇒修正事項のあるデータを、確認相手に通知する場合「差戻し」をクリック</p>`,
      }).then((result) => {
        if (result.isDismissed) {
          setisOpenPopup(false)
        }
      });
    }
  }, [])
  
  useEffect(() => {
    dispatch(userGetDrawDetailsAction(draw_id));
    setCurrent(0);
  }, [draw_id]);

  useEffect(() => {
    dispatch(userGetDrawDetailByIdAction(draw_detail_id));
    setCurrent(requestConfirmList.findIndex((a) => a.id === draw_detail_id));
    setCurrentSubDraw({})
    setIsChanged(false)
    setListSubDrawChange([])
    resetState();
  }, [draw_detail_id, requestConfirmList]);

  useEffect(() => {
    setTool(TOOL.DRAG);
  }, [drawDetail])
  

  useEffect(() => {
    if (confirmedId) {
      requestConfirmList[current].confirmed = true;
      transferPageFromRequestConfirmList[current].confirmed = true;
      dispatch(setConfirmedIdAction(null));
      goForward();
    }
  }, [confirmedId]);

  useEffect(() => {
    if (
      document.getElementsByTagName("canvas")[0]?.width
    ) {
      setSizeCanvas({
        width:document.getElementsByTagName("canvas")[0]?.width,
        height : document.getElementsByTagName("canvas")[0]?.height
      })

      if (document.getElementsByTagName("canvas")[0]?.width*document.getElementsByTagName("canvas")[0]?.height - 16777216 > 0) {
        setScale(scale/1.2)
        setLimitZoomIn(true)
      } 
    }
  }, [scale]);

  useEffect(() => {
    if(drawDetail.id && Object.keys(sub_draw).length > 0 && !currentSubDraw.id){
      if(drawDetail.id === draw_detail_id){
        setCurrentSubDraw(drawDetail);
      }
    }
  }, [drawDetail,sub_draw,currentSubDraw,draw_detail_id])

  const drawIndex = requestConfirmList.findIndex((a) => a.id === drawDetail.id);
  
  const onChangeValue = (name, value) => {
    document_info[name] = value;
    dispatch(document_set_info(document_info));

    if (name === 'color' && element && element.key > -1) {
      const findElement = konvaElements.find((ele) => ele.key === element.key);
      if (element) {
        dispatch(
          changeElementKonvaAction({
            key: element.key,
            element: {
              ...findElement,
              color: value,
            },
            newStep: true,
          })
        );
      }
    }

    if (name === 'fontSize' && element && element.key > -1) {
      const findElement = elements.find((ele) => ele.key === element.key);
      if (element) {
        dispatch(
          changeElementKonvaAction({
            key: element.key,
            element: {
              ...findElement,
              fontSize: value,
            },
            newStep: true,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (drawDetail.process === processRequest.type) {
      if (
        requestConfirmList[drawIndex] &&
        requestConfirmList[drawIndex].elementsCanvas
      )
        setElementCanvas({
          elementsCanvas: requestConfirmList[drawIndex].elementsCanvas,
        });
    }
  }, [drawDetail]);

  if (!drawDetail.id) return null;
  const { attachment, elements } = drawDetail;

  const CustomZoomInIcon = () => {
    const condition = () => {
      if (!isMobile && !isTablet ) {
        return true
      } else {
        if (sizeCanvas.width*sizeCanvas.height - 16777216 < 0) {
          return true
        }else{
          return false
        } 
      } 

    }

    return <div
              className={isMobile?"":"icon"}
              onClick={() => {
                if (condition()&&!limitZoomIn) {
                  setScale(scale*1.2)
                }
              }}
              style={{
                opacity:condition()&&!limitZoomIn?1:0.2
              }}
            >
              <ZoomInIcon
                className={`icon_filter_default ${isMobile || isTablet ? "color-white":"" } `}
                sx={{
                  fontSize: isMobile || isTablet ? "34px" : "24px"
                }}
              />
            </div>
  }

  const renderCanvas = () => {
    
    if (
      drawDetail.process === processRequestConfirm.type ||
      drawDetail.process === processConfirmed.type ||
      drawDetail.process === processCancelRequestConfirm.type
    ) {
      return (
        <ImageConfirm
          base64={currentSubDraw.attachment ? currentSubDraw.attachment.url : attachment.url}
          elements={currentSubDraw.elements ? currentSubDraw.elements : elements}
          tool={tool}
          stageX={stageX}
          stageY={stageY}
          setStageX={setStageX}
          setStageY={setStageY}
          stageScale={scale}
          id={draw_detail_id}
          elementsCanvas={elementsCanvas}
          setElementCanvas={setElementCanvas}
          scrollRef={scrollRef}
          setElementActive={setElementActive}
          fontSize={fontSize}
        />
      );
    } else if (
      drawDetail.process === processRequest.type ||
      drawDetail.process === processAgreed.type ||
      drawDetail.process === processCancelRequest.type
    ) {
        let el =[]
        let currentEls = elements ? elements : []
          el = !currentSubDraw.id ? currentEls : currentSubDraw.elements ? currentSubDraw.elements:[]
        return (
          <ImageConfirm
            base64={currentSubDraw.attachment ? currentSubDraw.attachment.url : attachment.url}
            elements={[
              ...el
            ]}
            tool={tool}
            setTool={setTool}
            stageX={stageX}
            stageY={stageY}
            setStageX={setStageX}
            setStageY={setStageY}
            stageScale={scale}
            id={draw_detail_id}
            allowEditing={true}
            elementsCanvas={elementsCanvas}
            setElementCanvas={setElementCanvas}
            step={step}
            setStep={setStep}
            scrollRef={scrollRef}
            setElementActive={setElementActive}
            fontSize={fontSize}
          />
        );
    }
    return null;
  };

  const saveCanvasAgree = () => {
    if (drawDetail.process === processRequest.type || drawDetail.process === processAgreed.type) {
      const drawIndex = requestConfirmList.findIndex(
        (a) => a.id === drawDetail.id
      );
      requestConfirmList[drawIndex].elementsCanvas = elementsCanvas;
    }
  };

  const goForward = (redirect) => {
    if (current < requestConfirmList.length - 1) {
      if(userPrimary?.user) {
        historyRoute.replace(
          `/confirm/${draw_id}/details/${requestConfirmList[current + 1].id}/user_group/${userPrimary?.id}`
        );
      } else {
        historyRoute.replace(
          `/confirm/${draw_id}/details/${requestConfirmList[current + 1].id}`
        );
      }
      
      // setTool(TOOL.DRAG)
    } else {
      if (!requestConfirmList.find((item) => !item.confirmed)) {
        redirectListConfirm();
      }
    }
  };

  const redirectListConfirm = () => {
    dispatch(setListConfirmAction(requestConfirmList));
    historyRoute.push(`/list-confirm/${draw_id}`);
  };

  const renderFooter = () => {
    if (
      drawDetail.process === processRequestConfirm.type ||
      drawDetail.process === processConfirmed.type ||
      drawDetail.process === processCancelRequestConfirm.type
    ) {
      return isMobile ? (
        <Stack
          sx={{
            backgroundColor: processRequestConfirm.color,
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'fixed',
            width: '100vw',
            bottom: 0,
            paddingBottom:"4px",
            // paddingTop:"7px"
          }}
          direction="row"
          spacing={1}
          py={1}
        >
          <div className='wp-confirm-tool-y'>
            <Stack
              sx={{
                // backgroundColor: 'white',
                alignItems: 'center',
                ml: 1,
              }}
              direction="row"
            >
              {/* <CustomZoomInIcon/> ss  */}
              <div
                onClick={() => {
                    setScale(scale*1.2)
                }}
              >
                <ZoomInIcon
                  className={`icon_filter_default`}
                  sx={{
                    color: '#ffff',
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setScale(scale / 1.2);
                  setLimitZoomIn(false)
                }}
              >
                <ZoomOutIcon
                  className="icon_filter_default"
                  sx={{
                    color: '#ffff',
                  }}
                />
              </div>
              <div
                onClick={() => {
                  setTool(TOOL.DRAG);
                }}
              >
                <NearMeIcon
                  style={{ WebkitTransform: 'scaleX(-1)' }}
                  className={
                    tool === TOOL.DRAG ? 'text-primary' : 'icon_filter_default'
                  }
                />
              </div>

              <div className={`${
                checkConfirmed
                ? 'cancel-confirm-btn confirmed-gray'
                : 'cancel-confirm-btn'}`}
                onClick={() => {
                if(checkConfirmed) return;
                swalRollBackFile({
                  html: `<div class="popup-cancel-title">【重要！】差戻しについて <img class="popup-cancel-icon" src="https://draw.test-mina-toku.jp/static/media/warning_red.0aedf72f.png"/></div><p class="popup-cancel-text">「差戻し」をクリックすると、「確認」・「記入」の依頼者に対して、「差戻し」が通知されます。</p>`,
                  input: "text",
                  inputPlaceholder: 'コメントを入力してください',
                }).then((result) => {
                  const {value: complain, isConfirmed} = result ?? {};
                  if(isConfirmed) {
                    dispatch(confirmDrawByUserAction({
                      confirmedItem: requestConfirmList[current],
                      process: renderProcessCancel(),
                      me,
                      complain,
                    }))
                    requestConfirmList[current].process = renderProcessCancel();
                  }
                });
                  
                }}>
                  <img width={22} src={BACK_ICON} alt='back'/>
                  <span>差戻し</span>
              </div>
            </Stack>
            <Typography
              sx={{
                justifyContent: 'space-between',
                fontWeight: 'bold',
                fontSize: '9px',
                color: 'white',
                textAlign: 'justify',
              }}
              direction="row"
            >
              内容に同意いただける場合は「確認完了」をタップ
            </Typography>
          </div>

          <Button
            sx={{
              border: '1px solid black',
              borderRadius: '0px!important',
              padding: '6px 10px',
              mr: '15px!important',
              ml:"25px !important",
              opacity:'0',
            }}
            disabled
          >
            <Typography
            >
              hidden
            </Typography>
          </Button>
          <div className='wp-btn-confirmed-y'>
            
          

            <Button
              sx={{
                backgroundColor: 'yellow',
                border: '1px solid black',
                borderRadius: '0px!important',
              }}
              className={
                checkConfirmed &&
                'confirmed-gray'
              }
              onClick={async () => {
                if (
                  !checkConfirmed
                ) {
                  dispatch(confirmDrawByUserAction({confirmedItem: requestConfirmList[current], me}));
                }
              }}
            >
              <Typography
                sx={{
                  flex: 'none',
                  color: 'black',
                  fontSize: '12px',
                }}
              >
                {checkConfirmed
                  ? '確認済'
                  : '確認完了'}
              </Typography>
            </Button>
          </div>
        </Stack>
      ) : (
        <div
          className="row w-100 align-items-center confirm-footer mx-0"
          style={{
            backgroundColor: processRequestConfirm.color,
          }}
        >
          <div
            className="col-4 d-flex justify-content-center align-items-center text-white text-bold"
            style={{
              fontSize: '13px',
            }}
          >
            <CustomZoomInIcon/>
            <div
              className="icon"
              onClick={() => {
                setScale(scale / 1.2);
        setLimitZoomIn(false)

              }}
            >
              <ZoomOutIcon
                className="icon_filter_default"
                sx={{
                  color: '#000',
                }}
              />
            </div>
            <div
              className={'icon' + (tool === TOOL.DRAG ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.DRAG);
              }}
            >
              <NearMeIcon style={{ WebkitTransform: 'scaleX(-1)' }} />
            </div>
            内容に同意いただける場合は「確認完了」をタップ
          </div>
          <div className="col-4 d-flex justify-content-center no-pointer">
            <button
              className={
                checkConfirmed
                  ? 'confirmed-gray btn-modal btn-confirm'
                  : 'btn-modal btn-confirm'
              }
              onClick={async () => {
                if (
                  !checkConfirmed
                ) {
                  dispatch(
                    confirmDrawByUserAction({confirmedItem: requestConfirmList[current], me})
                  );
                }
              }}
            >
              {checkConfirmed
                ? '確認済'
                : '確認完了'}
            </button>
          </div>
          <div className="col-4 d-flex justify-content-center">
            
          <div className={`${
            checkConfirmed
            ? 'cancel-confirm-btn confirmed-gray'
            : 'cancel-confirm-btn'}`}
            onClick={() => {
              if(checkConfirmed) return;
            swalRollBackFile({
              html: `<div class="popup-cancel-title">【重要！】差戻しについて <img class="popup-cancel-icon" src="https://draw.test-mina-toku.jp/static/media/warning_red.0aedf72f.png"/></div><p class="popup-cancel-text">「差戻し」をクリックすると、「確認」・「記入」の依頼者に対して、「差戻し」が通知されます。</p>`,
              input: "text",
              inputPlaceholder: 'コメントを入力してください',
            }).then((result) => {
              const {value: complain, isConfirmed} = result ?? {};
              if(isConfirmed) {
                  dispatch(confirmDrawByUserAction({
                  confirmedItem: requestConfirmList[current],
                  process: renderProcessCancel(),
                  me,
                  complain,
                }))
                requestConfirmList[current].process = renderProcessCancel();
              }
            });
              
            }}>
              <img width={22} src={BACK_ICON} alt='back'/>
              <span>差戻し</span>
          </div>
          </div>
        </div>
      );
    } else if (
      drawDetail.process === processRequest.type ||
      drawDetail.process === processAgreed.type ||
      drawDetail.process === processCancelRequest.type
    ) {
      return isMobile ? (
        <Stack
          sx={{
            backgroundColor: processRequest.color,
            p: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'fixed',
            bottom: 0,
            width: '100vw',
            padding: '8px',
            display:"flex",
            justifyContent:"center",
          }}
          direction="row"
        >
          <Stack sx={{
            width:"100%",
            paddingInline:"10px"
          }}>
          <div className='m-resize-wp'>
            <Stack
              direction="row"
              className='mb-1'
              sx={{
                display:"flex",
                gap:"5px"
              }}
            >
              <CustomZoomInIcon/>
              <div
                onClick={() => {
                  setScale(scale / 1.2);
                  setLimitZoomIn(false)
                }}
              >
                <ZoomOutIcon
                  className="icon_filter_default color-white"
                  sx={{fontSize:"34px"}}
                />
              </div>
              <div
                onClick={() => {
                  setTool(TOOL.DRAG);
                }}
              >
                <NearMeIcon
                  style={{ WebkitTransform: 'scaleX(-1)',fontSize:"34px" }}
                  className={
                    tool === TOOL.DRAG ? 'text-primary' : 'icon_filter_default'
                  }
                />
              </div>
            </Stack>

            <div style={{marginBottom:'5px'}} className={`${
              checkConfirmed
            ? 'cancel-confirm-btn bg-linear-mb confirmed-gray '
            : 'cancel-confirm-btn bg-linear-mb'}`}
            onClick={() => {
              if(checkConfirmed) return;
              swalRollBackFile({
                html: `<div class="popup-cancel-title">【重要！】差戻しについて <img class="popup-cancel-icon" src="https://draw.test-mina-toku.jp/static/media/warning_red.0aedf72f.png"/></div><p class="popup-cancel-text">「差戻し」をクリックすると、「確認」・「記入」の依頼者に対して、「差戻し」が通知されます。</p>`,
                input: "text",
                inputPlaceholder: 'コメントを入力してください',
              }).then((result) => {
                const {value: complain, isConfirmed} = result ?? {};
                if(isConfirmed) {
                  dispatch(confirmDrawByUserAction({
                    confirmedItem: requestConfirmList[current],
                    process: renderProcessCancel(),
                    me,
                    complain,
                  }))
                  requestConfirmList[current].process = renderProcessCancel();
                }
              });
            }}>
              <img width={22} src={BACK_ICON} alt='back'/>
              <span>差戻し</span>
            </div>
            <Button
              id="m-confirm-btn"
              sx={{
                backgroundColor: 'yellow',
                border: '1px solid black',
                borderRadius: '0px!important',
              }}
              className={`${
                checkConfirmed || confirmText(elementsCanvas) && !isChanged
                  ? 'confirmed-gray cursor-no-drop'
                  : ''
            }`}
              onClick={() => {
                if (!confirmText(elementsCanvas) || isChanged) {
                  if (
                    !checkConfirmed
                  ) {
                    swalQuestion({
                      html: `記入を完了すると、内容を修正することはできません。<br/>この内容で記入を完了してよろしいですか？`,
                      confirmButtonText: TEXT_LIST.btn_confirm,
                      cancelButtonText: TEXT_LIST.btn_cancel,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleConfirmedDocuments()
                        // saveCanvasAgree();
                        // dispatch(
                        //   confirmDrawByUserAction(requestConfirmList[current])
                        // );
                      }
                    });
                  }
                }
              }}
            >
              <Typography
                sx={{
                  flex: 'none',
                  color: 'black',
                  fontSize: '20px',
                }}
              >
                {requestConfirmList[current] &&
                checkConfirmed
                  ? '記入済み'
                  : '記入完了'}
              </Typography>
            </Button>
          </div>

          <Stack
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              color: 'white',
              gap:"5px"
            }}
            direction="row"
          >
            <div style={{height:"45px",maxWidth:"45px"}}>
                  <div
                    className={'icon'}
                    style={{ backgroundColor: localStorage.getItem("draw-color") ? localStorage.getItem("draw-color") : color }}
                    onClick={() => {
                      setColorDialog(!colorDialog);
                    }}
                  ></div>
                  {colorDialog && (
                    <div className={'color-dialog'} ref={popupColorRef} style={{top:"-130px"}}>
                      <div className="close">
                        <span onClick={() => setColorDialog(!colorDialog)}>
                          ×
                        </span>
                      </div>
                      <p className="text-center">カラー選択</p>
                      <ul>
                        {COLOR_TAGS.map((color_tag, key) => {
                          return (
                            <li key={key}>
                              <span
                                style={{
                                  backgroundColor: color_tag,
                                  cursor: 'pointer',
                                  border:
                                    color_tag === 'transparent'
                                      ? '1px solid #000'
                                      : 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={() => {
                                  localStorage.setItem("draw-color", color_tag);
                                  onChangeValue('color', color_tag);
                                }}
                              >
                                {
                                  localStorage.getItem("draw-color") && localStorage.getItem("draw-color") === color_tag ? <CheckRounded
                                  className={
                                    color_tag === 'transparent'
                                      ? 'text-dark'
                                      : 'text-white'
                                  }
                                  fontSize="small"
                                  alt="icon checked"
                                /> :
                                <>
                                {
                                 !localStorage.getItem("draw-color") && color === color_tag && (
                                    <CheckRounded
                                      className={
                                        color_tag === 'transparent'
                                          ? 'text-dark'
                                          : 'text-white'
                                      }
                                      fontSize="small"
                                      alt="icon checked"
                                    />
                                  )
                                }
                                </>
                                }

                               
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
            </div>
            <div
              className={'icon' + (tool === TOOL.PEN ? ' active' : '')}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setTool(TOOL.PEN);
              }}
            >
              <Tooltip placement="top" title="線描画" arrow>
                <ModeEditOutlineRounded />
              </Tooltip>
            </div>
            
            {/* <div
              className={'icon' + (tool === TOOL.ARROW ? ' active' : '')}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setTool(TOOL.ARROW);
              }}
            >
              <Tooltip placement="top" title="矢印" arrow>
                <CallReceivedRounded />
              </Tooltip>
            </div> */}
            {/* <div
              className={'icon' + (tool === TOOL.STRAIGHT_LINE ? ' active' : '')}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setTool(TOOL.STRAIGHT_LINE);
              }}
            >
              <Tooltip placement="top" title="直線" arrow>
                <HorizontalRuleOutlinedIcon style={{transform: "rotate(-45deg)"}} />
              </Tooltip>
            </div> */}
            <div
              className={'icon' + (tool === TOOL.TEXT ? ' active' : '')}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setTool(TOOL.TEXT);
              }}
            >
              <Tooltip placement="top" title="テキスト" arrow>
                <span>あ</span>
              </Tooltip>
            </div>
            <div
              className={'icon' + (tool === TOOL.CHECK ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.CHECK);
              }}
            >
              <Tooltip placement="top" arrow title="確認スタンプ">
                <CheckRounded />
              </Tooltip>
            </div>
            <Stack
                  className='icon'
                  sx={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    // marginRight: '1px',
                  }}
                >
                  <Input     
                    type="number"
                    size="md"
                    value={fontSize}
                    slotProps={{
                      input: {
                        min: 1,
                        max: 100,
                        step: 1,
                      },
                    }}
                    onChange={(e) => {
                      const {value} = e.target;
                      const eleCanvasArr = elementsCanvas.map((item) => {
                        if((item?.key === elementActive.key)) {
                          return {
                            ...item,
                            fontSize: value,
                          }
                        }
                        return item;
                      })
                      setFontSize(value);
                      setElementCanvas({ elementsCanvas: [...eleCanvasArr] })
                    }}
                    sx={{
                      input: {
                        padding: 0,
                        height:"44px"
                      },
                    }}
                  />
            </Stack>
            {/* <div
              className={'icon' + (tool === TOOL.SQUARE ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.SQUARE);
              }}
            >
              <Tooltip placement="top" arrow title="四角">
                <SquareOutlined />
              </Tooltip>
            </div> */}
            {/* <div
              className={'icon' + (tool === TOOL.CIRCLE ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.CIRCLE);
              }}
            >
              <Tooltip placement="top" arrow title="丸">
                <CircleOutlined />
              </Tooltip>
            </div> */}
            {/* <div
              className={'icon' + (tool === TOOL.CHECK ? ' active' : '')}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setTool(TOOL.CHECK);
              }}
            >
              <Tooltip placement="top" arrow title="確認スタンプ">
                <CheckRounded />
              </Tooltip>
            </div> */}
            <div
              className={'icon'}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setStep(step ? step - 1 : 0);
              }}
            >
              <Tooltip placement="top" arrow title="元に戻す">
                <UndoIcon />
              </Tooltip>
            </div>
            <div
              className={'icon'}
              style={{
                marginRight: '0',
              }}
              onClick={() => {
                setStep(step < historys.length - 1 ? step + 1 : step);
              }}
            >
              <Tooltip placement="top" arrow title="やり直す">
                <RedoIcon />
              </Tooltip>
            </div>
          </Stack>
          </Stack>
          

          {/* <Button
            id="m-confirm-btn"
            sx={{
              backgroundColor: 'yellow',
              border: '1px solid black',
              borderRadius: '0px!important',
            }}
            className={`${
              requestConfirmList[current]?.process === processAgreed.type || confirmText(elementsCanvas) && !isChanged
                ? 'confirmed-gray cursor-no-drop'
                : ''
            }`}
            onClick={() => {
              if (!confirmText(elementsCanvas) || isChanged) {
                if (
                  requestConfirmList[current].process !== processAgreed.type
                ) {
                  swalQuestion({
                    html: `記入を完了すると、内容を修正することはできません。<br/>この内容で記入を完了してよろしいですか？`,
                    confirmButtonText: TEXT_LIST.btn_confirm,
                    cancelButtonText: TEXT_LIST.btn_cancel,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleConfirmedDocuments()
                      // saveCanvasAgree();
                      // dispatch(
                      //   confirmDrawByUserAction(requestConfirmList[current])
                      // );
                    }
                  });
                }
              }
            }}
          >
            <Typography
              sx={{
                flex: 'none',
                color: 'black',
                fontSize: '12px',
              }}
            >
              {requestConfirmList[current] &&
              requestConfirmList[current].process === processAgreed.type
                ? '記入済み'
                : '記入完了'}
            </Typography>
          </Button> */}
        </Stack>
      ) : (
        <div
          className="row w-100 align-items-center confirm-footer mx-0"
          style={{
            backgroundColor: processRequest.color,
          }}
        >
          <div className="col-4 d-flex justify-content-center text-white text-bold">
            <CustomZoomInIcon/>
            <div
              className="icon mr-5px"
              onClick={() => {
                setScale(scale / 1.2);
                setLimitZoomIn(false)

              }}
            >
              <ZoomOutIcon
                className="icon_filter_default"
                sx={{
                  color: '#000',
                }}
              />
            </div>
            <div
              className={'icon mr-5px' + (tool === TOOL.DRAG ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.DRAG);
              }}
            >
              <NearMeIcon style={{ WebkitTransform: 'scaleX(-1)' }} />
            </div>
            <div
              className={'icon mr-5px' + (tool === TOOL.PEN ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.PEN);
              }}
            >
              <Tooltip placement="top" title="線描画" arrow>
                <ModeEditOutlineRounded />
              </Tooltip>
            </div>
            {/* <div
              className={'icon mr-5px' + (tool === TOOL.ARROW ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.ARROW);
              }}
            >
              <Tooltip placement="top" title="矢印" arrow>
                <CallReceivedRounded />
              </Tooltip>
            </div> */}
            {/* <div
              className={'icon mr-5px' + (tool === TOOL.STRAIGHT_LINE ? ' active' : '')}
              style={{
                // marginRight: '1px',
              }}
              onClick={() => {
                setTool(TOOL.STRAIGHT_LINE);
              }}
            >
              <Tooltip placement="top" title="直線" arrow>
                <HorizontalRuleOutlinedIcon style={{transform: "rotate(-45deg)"}} />
              </Tooltip>
            </div> */}
            <div
              className={'icon mr-5px' + (tool === TOOL.TEXT ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.TEXT);
              }}
            >
              <Tooltip placement="top" title="テキスト" arrow>
                <span>あ</span>
              </Tooltip>
            </div>
            <Stack
              className='mr-5px'
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
              }}
            >
              <Input     
                type="number"
                size="medium"
                value={fontSize}
                slotProps={{
                  input: {
                    min: 1,
                    max: 100,
                    step: 1,
                  },
                }}
                onChange={(e) => {
                  const {value} = e.target;
                  const eleCanvasArr = elementsCanvas.map((item) => {
                    if((item?.key === elementActive.key)) {
                      return {
                        ...item,
                        fontSize: value,
                      }
                    }
                    return item;
                  })
                  setFontSize(value);
                  setElementCanvas({ elementsCanvas: [...eleCanvasArr] })
                }}
                sx={{
                  input: {
                    padding: 0,
                    minWidth: '40px',
                    height:"28px"
                  },
                }}
              />
            </Stack>
            {/* <div
              className={'icon mr-5px' + (tool === TOOL.SQUARE ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.SQUARE);
              }}
            >
              <Tooltip placement="top" arrow title="四角">
                <CropDinOutlined />
              </Tooltip>
            </div> */}
            {/* <div
              className={'icon mr-5px' + (tool === TOOL.CIRCLE ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.CIRCLE);
              }}
            >
              <Tooltip placement="top" arrow title="丸">
                <CircleOutlined />
              </Tooltip>
            </div> */}
            <div
              className={'icon mr-5px' + (tool === TOOL.CHECK ? ' active' : '')}
              onClick={() => {
                setTool(TOOL.CHECK);
              }}
            >
              <Tooltip placement="top" arrow title="確認スタンプ">
                <CheckRounded />
              </Tooltip>
            </div>
            <div
              className={'icon'}
              onClick={() => {
                setStep(step ? step - 1 : 0);
              }}
            >
              <Tooltip placement="top" arrow title="元に戻す">
                <UndoIcon />
              </Tooltip>
            </div>
            <div
              className={'icon mr-5px'}
              onClick={() => {
                setStep(step < historys.length - 1 ? step + 1 : step);
              }}
            >
              <Tooltip placement="top" arrow title="やり直す">
                <RedoIcon />
              </Tooltip>
            </div>

            <div style={{height:"30px",maxWidth:"32px"}}>
                  <div
                    className={'icon'}
                    style={{ backgroundColor: localStorage.getItem("draw-color") ? localStorage.getItem("draw-color") : color }}
                    onClick={() => {
                      setColorDialog(!colorDialog);
                    }}
                  ></div>
                  {colorDialog && (
                    <div className={'color-dialog'} ref={popupColorRef} style={{top:"-130px"}}>
                      <div className="close">
                        <span onClick={() => setColorDialog(!colorDialog)}>
                          ×
                        </span>
                      </div>
                      <p className="text-center">カラー選択</p>
                      <ul>
                        {COLOR_TAGS.map((color_tag, key) => {
                          return (
                            <li key={key}>
                              <span
                                style={{
                                  backgroundColor: color_tag,
                                  cursor: 'pointer',
                                  border:
                                    color_tag === 'transparent'
                                      ? '1px solid #000'
                                      : 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={() => {
                                  localStorage.setItem("draw-color", color_tag);
                                  onChangeValue('color', color_tag);
                                }}
                              >
                                {
                                  localStorage.getItem("draw-color") && localStorage.getItem("draw-color") === color_tag ? <CheckRounded
                                  className={
                                    color_tag === 'transparent'
                                      ? 'text-dark'
                                      : 'text-white'
                                  }
                                  fontSize="small"
                                  alt="icon checked"
                                /> :
                                <>
                                {
                                 !localStorage.getItem("draw-color") && color === color_tag && (
                                    <CheckRounded
                                      className={
                                        color_tag === 'transparent'
                                          ? 'text-dark'
                                          : 'text-white'
                                      }
                                      fontSize="small"
                                      alt="icon checked"
                                    />
                                  )
                                }
                                </>
                                }

                               
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
            </div>

          </div>
          <div className="col-4 d-flex justify-content-center">
            <button
              className={`${
                checkConfirmed || confirmText(elementsCanvas) && !isChanged
                  ? 'btn-modal btn-confirm confirmed-gray cursor-no-drop'
                  : 'btn-modal btn-confirm'
              }`}
              onClick={() => {
                if (!confirmText(elementsCanvas) || isChanged) {
                  if (
                    !checkConfirmed
                  ) {
                    swalQuestion({
                      html: `記入を完了すると、内容を修正することはできません。<br/>この内容で記入を完了してよろしいですか？`,
                      confirmButtonText: TEXT_LIST.btn_confirm,
                      cancelButtonText: TEXT_LIST.btn_cancel,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        handleConfirmedDocuments()
                      }
                    });
                  }
                }
              }}
            >
              {requestConfirmList[current] &&
              checkConfirmed
                ? '記入済み'
                : '記入完了'}
            </button>
          </div>
          <div className="col-4 d-flex justify-content-center">
            <div className={`${
              checkConfirmed
              ? 'cancel-confirm-btn confirmed-gray '
              : 'cancel-confirm-btn'}`}
              onClick={() => {
              if(checkConfirmed) return;
              swalRollBackFile({
                html: `<div class="popup-cancel-title">【重要！】差戻しについて <img class="popup-cancel-icon" src="https://draw.test-mina-toku.jp/static/media/warning_red.0aedf72f.png"/></div><p class="popup-cancel-text">「差戻し」をクリックすると、「確認」・「記入」の依頼者に対して、「差戻し」が通知されます。</p>`,
                input: "text",
                inputPlaceholder: 'コメントを入力してください',
              }).then((result) => {
                const {value: complain, isConfirmed} = result ?? {};
                if(isConfirmed) {
                  dispatch(confirmDrawByUserAction({
                    confirmedItem: requestConfirmList[current],
                    process: renderProcessCancel(),
                    me,
                    complain,
                  }))
                  requestConfirmList[current].process = renderProcessCancel();
                }
              });
            }}>
              <img width={22} src={BACK_ICON} alt='back'/>
              <span>差戻し</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const style = () => {
    // if (
    //   requestConfirmList[current] &&
    //   (requestConfirmList[current].process === processRequest.type ||
    //     requestConfirmList[current].process === processAgreed.type)
    // ) {
    //   return {
    //     position: 'relative',
    //     top: open ? '270px' : '62px',
    //     paddingBottom: '60px',
    //   };
    // } else
    //   return {
    //     position: 'relative',
    //     top: open ? '174px' : '62px',
    //     paddingBottom: '60px',
    //   };
      return {
        position: 'relative',
        top: '62px',
        paddingBottom: '62px',
      };
  };

  const handleConfirmedDocuments =async ()=>{
    let tempList = [...listSubDrawChange];
    if(elementsCanvas.length > 0){
      if(currentSubDraw.id){
        let index = tempList.findIndex((data)=> data.id === currentSubDraw.id)
        if(index > -1){
          tempList[index].elements = tempList[index].elements.concat(elementsCanvas)
        }else{
          let e = currentSubDraw.elements ? currentSubDraw.elements.concat(elementsCanvas) : elementsCanvas
          tempList.push({id: currentSubDraw.id,elements: e})
        }
      }
    } 
    saveCanvasAgree();
    dispatch(
      confirmDrawByUserAction({confirmedItem: requestConfirmList[current],listSubDrawChange: tempList, me})
    );   
    // setCurrentSubDraw({})                 
    setListSubDrawChange([]) 
    // initCanvas()     
                                
  }

  const handleChangeSubdraw = async (subDrawInfo)=>{
    if(elementsCanvas.length > 0){
      let els = !currentSubDraw.id ? elements : currentSubDraw.elements ? currentSubDraw.elements:[]
      const totalElements = els.concat(elementsCanvas);
      if(Object.keys(sub_draw).length > 0){
        let subListTemp = [...listSubDrawChange];
        for (let i=0; i< Object.keys(sub_draw).length; i++){
          if(sub_draw[i].id === currentSubDraw.id){
            sub_draw[i].elements = totalElements;
            let index = subListTemp.findIndex((data)=> data.id === sub_draw[i].id)
            if(index > -1){
              subListTemp[index].elements = totalElements;
              setListSubDrawChange(subListTemp)
            }else{
              setListSubDrawChange([...listSubDrawChange,{id:sub_draw[i].id,elements:totalElements}])
            }
          }
          // else if(sub_draw[i].id === drawDetail.id && !currentSubDraw.id){
          //   sub_draw[i].elements = totalElements
          //   let index = listSubDrawChange.findIndex((data)=> data.id === sub_draw[i].id)
          //   if(index > -1){
          //     listSubDrawChange[index].elements = totalElements;
          //   }else{
          //     setListSubDrawChange([...listSubDrawChange,{id:sub_draw[i].id,elements:totalElements}])
          //   }
          // }
        }
      }
      dispatch(sub_draw_set_info(sub_draw))
      setIsChanged(true);
      setCurrentSubDraw(subDrawInfo);
      initCanvas()
    }else{
      setCurrentSubDraw(subDrawInfo);
      initCanvas()
    }
  }

  const renderFileNavbar = ()=>{
    return (
      <>
      {
        Object.keys(sub_draw).length > 0 && 
        <Button variant='contained' className='f-btn-navbar' 
          onClick={() => setShowNavbar(!isShowNavbar)}>
          サムネイル <i className={`ml-1 fa-solid fa-chevron-${isShowNavbar ? 'up' : 'down'}`}></i>
        </Button>   
      }
      {
        isShowNavbar && Object.keys(sub_draw).length > 0 && 
        <div className='cf-navbar'>
          <ul className='cf-list'>
          { 
            Object.entries(sub_draw).map((drawItem,index)=>{
              const {url, url_resize, file_alias} = drawItem[1]?.attachment ?? {};
              return (<li key={drawItem[1].id} className={`cf-item ${drawItem[1].id === drawDetail.id && !currentSubDraw.id && "cf-active"} ${drawItem[1].id === currentSubDraw.id  && "cf-active"}`}>
                <img onClick={()=>{
                  handleChangeSubdraw(drawItem[1])

                }} src={url_resize ?? url} alt={file_alias}/>
              </li>)
            })
          }
          </ul>
        </div>
      }
      </>
    )
  }


  return (
    <div
      className={
        'confirm-screen'
      }
    >
      {isMobile ? (
        <Stack
          direction="row"
          // spacing={1}
          px={1}
          my={1}
          sx={{
            position: 'fixed',
            top: 0,
            width: '100vw',
            zIndex: 2,
            // backgroundColor: 'white',
          }}
        >
          {requestConfirmList[current] &&
            (requestConfirmList[current].process === processRequest.type ||
              requestConfirmList[current].process === processAgreed.type) ? (
            <Stack
              sx={{
                padding: '5px 15px',
                border: '1.5px solid rgba(0,0,0,0.5)',
                background: 'white'
              }}
              spacing={1}
              flex={1}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => setOpen(!open)}
              >
                <Typography
                  variant="h5"
                  fontWeight={'700'}
                  sx={{
                    flex: 'none',
                    fontSize: '1.15rem',
                  }}
                >
                  説明
                </Typography>
                {open ? (
                  <KeyboardArrowUpRoundedIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownRoundedIcon fontSize="large" />
                )}
              </Stack>
              {open && (
                <>
                  <Stack direction="row">
                    <Typography
                      sx={{
                        flex: 'none',
                        fontSize: '13px',
                      }}
                    >
                      左下の
                    </Typography>
                    <div className={'icon note no-pointer'}>
                      <ModeEditOutlineRounded />
                    </div>
                    <Typography
                      sx={{
                        fontSize: '13px',
                      }}
                    >
                      {' '}
                      を選択してマウス等で記入して下さい。
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    <div className={'icon note no-pointer'}>
                      <span>あ</span>
                    </div>
                    <Typography
                      sx={{
                        fontSize: '13px',
                      }}
                    >
                      を選択することで文字を入力することもできます。
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    <div className={'icon note no-pointer'}>
                      <CheckRounded />
                    </div>
                    <Typography
                      sx={{
                        fontSize: '13px',
                      }}
                    >
                      を選択してクリックすることでチェックマークをつけることができます。
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      sx={{
                        flex: 'none',
                        fontSize: '13px',
                      }}
                    >
                      記入が全て終わったら
                    </Typography>
                    <Typography
                      sx={{
                        flex: 'none',
                        width: 'max-content',
                        border: '1px solid black',
                        backgroundColor: 'yellow',
                        fontSize: '13px',
                      }}
                    >
                      記入完了
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: '13px',
                    }}
                  >
                    ボタンを押してください。
                  </Typography>
                </>
              )}
            </Stack>
          ) : (
            <Stack
              sx={{
                padding: '5px 15px',
                border: '1.5px solid rgba(0,0,0,0.5)',
                background: 'white',
              }}
              spacing={1}
              flex={1}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => setOpen(!open)}
              >
                <Typography
                  variant="h5"
                  fontWeight={'700'}
                  sx={{
                    flex: 'none',
                    fontSize: '1.15rem',
                  }}
                >
                  説明
                </Typography>
                {open ? (
                  <KeyboardArrowUpRoundedIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownRoundedIcon fontSize="large" />
                )}
              </Stack>
              {open && (
                <>
                  <Typography>
                    画像に記載されている内容の確認をお願いします。
                  </Typography>

                  <span>
                    確認が完了したら、
                    <span
                      style={{
                        background: 'yellow',
                        flex: 'none',
                        width: 'max-content',
                        border: '1px solid black',
                        backgroundColor: 'yellow',
                        fontSize: '13px',
                      }}
                    >
                      確認完了
                    </span>{' '}
                    ボタン を押してください。
                  </span>
                </>
              )}
            </Stack>
          )}
          <button
            style={{height: '47px'}}
            className="btn-modal"
            onClick={() => {
              dispatch(setListConfirmAction([]));
              if(userPrimary?.user) {
                resetRoute(`/confirm/${draw_id}/user_group/${userPrimary?.id}`);
              } else {
                resetRoute(`/confirm/${draw_id}`);
              }
            }}
          >
            <CloseIcon sx={{color:"#1582ff"}} />
          </button>
        </Stack>
      ) : (
        <div className="d-flex w-100 p-2 justify-content-end align-items-center">
          <button
            className="btn-modal"
            onClick={() => {
              dispatch(setListConfirmAction([]));
              if(userPrimary?.user) {
                resetRoute(`/confirm/${draw_id}/user_group/${userPrimary?.id}`);
              } else {
                resetRoute(`/confirm/${draw_id}`);
              }
            }}
          >
            <CloseIcon sx={{color:"#1582ff"}} />
          </button>
        </div>
      )}

      {isMobile ? (
        <Stack style={style()}>
          <div className="d-flex justify-content-center align-items-center w-100 px-0">
            <div
              style={{
                width: '100vw',
                height: window.innerHeight - 220,
                overflowY: 'scroll',
                margin: '0 8px',
              }}
              ref={scrollRef}
            >
              {renderCanvas()}
            </div>
            {renderFileNavbar()}
          </div>

          <div className="d-flex justify-content-center mt-1">
            <Pagination
              className="mt-2 pagination-draw-mobile"
              count={requestConfirmList.length}
              variant="outlined"
              siblingCount={0}
              color="primary"
              page={current + 1}
              renderItem={(item) => {
                const requestConfirmed = transferPageFromRequestConfirmList
                  .filter((request) => request.confirmed === true)
                  .map((item) => item.page);
                return (
                  <PaginationItem
                    slots={{
                      previous: ArrowBackIosNewRoundedIcon,
                      next: ArrowForwardIosRoundedIcon,
                    }}
                    {...item}
                    page={
                      requestConfirmed.includes(item.page) ? '済' : item.page
                    }
                  />
                );
              }}
              onChange={(_event, page) => {
                setCurrentSubDraw({})
                const url = transferPageFromRequestConfirmList.find(
                  (item) => item.page === page
                );
                if(userPrimary?.user) {
                  historyRoute.push(`/confirm/${draw_id}/details/${url.id}/user_group/${userPrimary?.id}`);
                } else {
                  historyRoute.push(`/confirm/${draw_id}/details/${url.id}`);
                }
                // setTool(TOOL.DRAG)
              }}
              size={'medium'}
            />
          </div>
        </Stack>
      ) : (
        <>
          <div className="d-flex justify-content-center align-items-stretch w-100 px-3 box-detail-wp">
            {requestConfirmList[current] &&
            (requestConfirmList[current].process === processRequest.type ||
              requestConfirmList[current].process === processAgreed.type) ? (
              <Box
                flex={1}
                sx={{
                  padding: '15px',
                  border: '2px solid black',
                  // height: isTablet ? '80vh' : '',

                  flexBasis: '8%',
                }}
              >
                <Typography variant="h5" fontWeight={'700'} marginBottom={4}>
                  説明
                </Typography>
                <div className="mb-3">
                  左下の
                  {
                    <div className={'icon note no-pointer'}>
                      <ModeEditOutlineRounded />
                    </div>
                  }
                  を選択してマウス等で記入して下さい。
                </div>
                <div className="mb-3">
                  {
                    <div className={'icon note no-pointer'}>
                      <span>あ</span>
                    </div>
                  }
                  を選択することで文字を入力することもできます。
                </div>
                <div className="mb-3">
                  {
                    <div className={'icon note no-pointer'}>
                      <CheckRounded />
                    </div>
                  }
                  を選択してクリックすることでチェックマークをつけることができます。
                </div>
                <div>
                  記入が全て終わったら{' '}
                  <div
                    className="btn-modal btn-confirm d-inline-block no-pointer"
                    style={{ width: 'auto' }}
                  >
                    記入完了
                  </div>{' '}
                  ボタンを押してください。
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  padding: '15px',
                  // height: isTablet ? '80vh' : '',
                  border: '2px solid black',
                  flexBasis: '8%',
                }}
                flex={1}
              >
                <Typography variant="h5" fontWeight={'700'} marginBottom={4}>
                  説明
                </Typography>
                <div className="mb-3">
                  画像に記載されている内容の確認をお願いします。
                </div>
                <div>
                  確認が完了したら、
                  <div
                    className="btn-modal btn-confirm d-inline-block no-pointer"
                    style={{ width: 'auto' }}
                  >
                    確認完了
                  </div>{' '}
                  ボタン を押してください。
                </div>
              </Box>
            )}
            
            <div style={{
              position:"relative",
              // width: 1050,
              height: isTablet ? window.innerHeight - 170 : window.innerHeight - 170,
                // height: isTablet ? '80vh' : window.innerHeight - 160,
              overflowY: 'auto',
              // marginInline: '30px',
              // border: isTablet ? '1px solid rgba(0,0,0,0.3)' : '',
              // flex: isTablet ? 4 : 5,
            }}>
            <div
              style={{
                // width: 1050,
                height: isTablet ? window.innerHeight - 170 : window.innerHeight - 170,
                // height: isTablet ? '80vh' : window.innerHeight - 160,
                overflowY: 'auto',
                marginInline: '30px',
                border: isTablet ? '1px solid rgba(0,0,0,0.3)' : '',
                // flex: isTablet ? 4 : 5,
                display:"flex",
                // alignItems:"center"
              }}
              ref={scrollRef}
            >
              {renderCanvas()}
              
            </div>
            {renderFileNavbar()}
            </div>
            {/* {!isTablet && <Box flexShrink={1} flexBasis={'15%'} />} */}
          </div>

          <div
            className={
              isTablet
                ? 'd-flex justify-content-center mt-0'
                : 'd-flex justify-content-center mt-2'
            }
          >
            <Pagination
              className="mt-2 pagination-draw"
              count={requestConfirmList.length}
              variant="outlined"
              siblingCount={1}
              color="primary"
              page={current + 1}
              renderItem={(item) => {
                const requestConfirmed = transferPageFromRequestConfirmList
                  .filter((request) => request.confirmed === true)
                  .map((item) => item.page);
                return (
                  <PaginationItem
                    slots={{
                      previous: ArrowBackIosNewRoundedIcon,
                      next: ArrowForwardIosRoundedIcon,
                    }}
                    {...item}
                    page={
                      requestConfirmed.includes(item.page) ? '済' : item.page
                    }
                  />
                );
              }}
              onChange={(_event, page) => {
                setCurrentSubDraw({})
                const url = transferPageFromRequestConfirmList.find(
                  (item) => item.page === page
                );
                if(userPrimary?.user) {
                  historyRoute.push(`/confirm/${draw_id}/details/${url.id}/user_group/${userPrimary?.id}`);
                } else {
                  historyRoute.push(`/confirm/${draw_id}/details/${url.id}`);
                }
                // setTool(TOOL.DRAG)
              }}
              size={'large'}
            />
          </div>
        </>
      )}
      <Box position={"fixed"} bottom={0} width={"100%"}>
      {renderFooter()}
      </Box>
    </div>
  );
});

export default Confirm;
