import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { PureComponent, Fragment } from 'react';
import { METHOD_RECEIVE_OTP, SMS_METHOD, TEXT_LIST, WIDTH_MOBILE } from '../../constants';
import { getUA } from 'react-device-detect';
import axios from 'axios';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { getUserGroupChatId, hideMail, hideTelNumber, isShowTel, validatePhoneNumber } from 'constants/utils';
import Swal from 'sweetalert2';

let draw_id = null;
let user_group_chat_id = null;
let tab_active = null;
export default class DrawRoute extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      temporary_pwd: '',
      width: window.innerWidth,
      ip: '',
      openPop: true,
      tabActive: METHOD_RECEIVE_OTP.EMAIL,
      tel: null,
      isCall: true,
      user_group_chat_id: null,
    };
  }

  handleWindowResize = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  handleChangePop = () => {
    this.setState({ ...this.state, openPop: !this.state });
  };

  componentDidMount() {
    const current_url = window.location.href;
    const { checkLogin, onSetStep, me_draw } = this.props;
    window.addEventListener('resize', this.handleWindowResize);
    const {SMS, EMAIL} = METHOD_RECEIVE_OTP;

    if (
      !current_url.match(/\/confirm/) &&
      !current_url.match(/\/list-confirm/) &&
      !current_url.match(/\/confirmed/)
    ) {
      return null;
    }

    const splitUrl = current_url.split('/');
    let isUserGroup = false;
    if(splitUrl.includes('user_group')) {
      isUserGroup = true;
    }
    if(isUserGroup) {
      user_group_chat_id = getUserGroupChatId(String(window.location.href))
    }
    if (current_url.match(/\/confirm\/.+\/details\/.+/) || current_url.match(/\/confirm\/.+\/cg_property\/.+/)) {
      draw_id = splitUrl[splitUrl.length - (isUserGroup ? 5 : 3)];
    } else if(current_url.match(/\/confirm\/.+\/tab_active\/.+/)) {
      draw_id = splitUrl[4];
      user_group_chat_id = splitUrl[6];
      const [tabActive, emailString] = splitUrl[8]?.split('?');
      if(tabActive === SMS || tabActive === EMAIL) {
        this.setState({tabActive})
        onSetStep({step: 2})
      }
    } 
    else {
      draw_id = splitUrl[splitUrl.length - (isUserGroup ? 3 : 1)];
    }

    checkLogin({draw_id, user_group_chat_id});
    this.setState({user_group_chat_id});

    this.getIP();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getIP = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      this.setState({ ...this.state, ip: res.data.IPv4 });
    } catch (err) {
      console.log(err)
    }
  };

  dialogSendPassWord = () => {
    const { me_draw, sendPasswordDraw, signInDraw, onSendSMS, onSetStep, me_step } = this.props;
    const {step} = me_step ?? {};
    if (me_draw && me_draw.id) return null;
    const { temporary_pwd, width, tabActive, tel, user_group_chat_id } = this.state;
    const { email, fax, userName, ipPhone, userTel } = me_draw;
    // const email_protect = email.replace(/@.*/g, '@****');
    if (![1, 2].includes(step)) return;
    const isMobile = width < WIDTH_MOBILE ? true : false;
    // const finalTel = tel ?? fax;
    // const telFromUsb = fax ?? '';
    const finalTel = tel ?? userTel;
    const userNameFromUsb = userName ?? '';
    const {SMS, EMAIL} = METHOD_RECEIVE_OTP;
    return (
      <div>
        <Dialog
          open={true}
          sx={{
            textAlign: 'center',
          }}
          className='modal-otp'
        >
          {step === 1 ? (
            <DialogContent className="m-4 border-btn">
              <h5 className='fw-bold'>プレミアムチャット</h5>
              <br />
              <h5 className='fs-1rem'>ワンタイムパスワードを受信する方法を選択</h5>
              <br />
              {
                isShowTel(finalTel) &&
                <Tabs variant="fullWidth" value={tabActive === SMS ? 0 : 1} onChange={(_, tabActive) => this.setState({tabActive: tabActive === 0 ? SMS : EMAIL})} >
                  <Tab label={<div className='d-flex align-items-center'><Checkbox className='mr-1' checked={tabActive === SMS ? true : false} /> 携帯電話(SMS)で受信</div>} />
                  <Tab label={<div className='d-flex align-items-center'><Checkbox className='mr-1' checked={tabActive === SMS ?false : true} /> メールで受信</div>} />
                </Tabs>
              }
              <br />
              {
                tabActive === SMS &&
                <>
                  {
                    isShowTel(userTel) ?
                    <p className="fs-20px">{hideTelNumber(userTel)}</p>
                      :
                      <div className="">
                      <Typography className="mb-2 fs-20px">{userNameFromUsb}</Typography>
                      <TextField
                        type="tel"
                        required
                        placeholder="携帯電話番号を入力してください"
                        defaultValue={userTel}
                        value={this.state.tel}
                        onChange={(e) => this.setState({tel: e.target.value})}
                        variant='standard'
                      />
                    </div>
                  }
                  <br />
                  <br/>
                  <button
                    className={`btn-pchat border-0 bg-grey pointer-events-none ${validatePhoneNumber(finalTel) && 'bg-pchat pointer-events-all'}`}
                    style={{padding: '15px'}}
                    onClick={() => {
                      if (validatePhoneNumber(finalTel)) {
                        onSendSMS({ draw_id, tel: finalTel, method: SMS_METHOD.SMS, me_draw });
                      } else {
                        return Swal.fire({
                          title: '<h6>正しい電話番号を入力してください</h6>',
                          icon: 'error',
                          time: 2000,
                        });
                      }
                    }}
                  >
                    SMSに認証コードを送信
                  </button>
                </>
              }
              {
                tabActive === EMAIL &&
                <>
                  <p className='fs-20px'>{hideMail(email)} </p>
                  <br />
                  <br />
                  <button
                    className={`btn-pchat border-0`}
                    style={{padding: '15px'}}
                    onClick={() => sendPasswordDraw({ me_draw, draw_id, user_group_chat_id })}
                  >
                    ワンタイムパスワード 送信
                  </button>
                </>
              }
            </DialogContent>
          ) : (
            <DialogContent
              className="m-4 border-btn"
              sx={{
                padding: isMobile ? '10px' : '20px 24px',
              }}
            >
              <h4 className="mb-3 mt-2 fw-bold">認証する</h4>
              <div>
                <div>認証コードを送信しました</div>
                <div>以下に入力してログインして下さい</div>
              </div>
              {
              tabActive === SMS &&
              <div className="mt-10px d-flex flex-column justify-content-center align-items-center">
                <h6 className=" my-2 fw-bold"> {'電話番号'}</h6> 
                <div className="d-flex align-item-center">
                  <Typography className="mr-3 fs-20px">{isShowTel(userTel) ? hideTelNumber(finalTel) : finalTel}</Typography>
                  {!isShowTel(userTel) && <button  className="c-pointer border-btn" onClick={() => onSetStep({me_draw, step: 1})}>修正</button>}
                </div>
              </div>
             }
             {
              tabActive === EMAIL &&
              <div className="mt-10px d-flex flex-column justify-content-center align-items-center">
                <h6 className="my-2 fw-bold"> {'電子メールアドレス'}</h6> 
                <div className="d-flex align-item-center">
                  <Typography className="mr5 fz18">{hideMail(email)}</Typography>
                </div>
              </div>
             }
              {/* {isClickEmail && <small>※ メールアドレスは一部伏せ字になっています。</small>} */}
              <div className={`${isMobile ? 'mt-4' : 'mb-10px d-flex flex-column justify-content-center align-items-center'}`}>
                <h6 className="fw-bold my-2">6桁の認証コードを入力して下さい</h6>
                <TextField
                  type="text"
                  hiddenLabel
                  variant="outlined"
                  size="small"
                  style={{width:"60%"}}
                  className="pl-2 code-input"
                  value={temporary_pwd || ''}
                  onChange={(e) =>
                    this.setState({ temporary_pwd: e.target.value })
                  }
                  autoComplete="one-time-code"
                  
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center mb-4" style={{gap:"15px"}}>
                <Button
                  className="btn-pchat border-0 px-5 btn-mb-pchat color-white"
                  onClick={() => signInDraw({ email, temporary_pwd, finalTel, draw_id, user_group_chat_id })}
                >
                  {TEXT_LIST.login}
                </Button>
                 {
                  tabActive === SMS &&
                  <Accordion className="expansion-panel-sms" expanded={this.state.expanPanel} onChange={(_, expanPanel) => this.setState({expanPanel})}>
                    <AccordionSummary expandIcon={<i className="fa fa-chevron-down"></i>}
                       onClick={() => {
                        if(!this.state.isCall) return;
                        onSendSMS({ draw_id, tel: finalTel, method: SMS_METHOD.SPEAK, me_draw });
                        this.setState({isCall: false})
                       }}>
                      <Typography style={{color: '#003459'}}>SMSが届かない場合はこちら</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="flex-column text-left">
                      <Typography className='fs-14px'>
                        SMSの到着までに最大1分ほどかかる場合があります。<span className="text-red bold">1分待っても届かない場合は自動音声による電話認証</span>をお試しください。
                      </Typography>
                      <Typography className='fs-14px'>
                        <span className="fw-bold">以下の電話番号に電話をし、自動音声で案内される6桁の認証コードを入力</span>してください。
                      </Typography>
                      <Typography className='fs-14px'>
                       ※必ず認証を行う電話から発信してください。
                      </Typography>
                    </AccordionDetails>

                    <Button href={`tel:${ipPhone}`} className="btn-call"
                      onClick={() => onSendSMS({ draw_id, tel: finalTel, method: SMS_METHOD.SPEAK, me_draw })}
                    > {ipPhone} 電話発信</Button>
                    
                    
                  </Accordion>
                  // <p>{hideMail(email)}</p>
                 
                 }
                 {
                  tabActive === EMAIL &&
                   <>
                   {/* <p className="fs-12px mb-10px">※自動音声でも認証できない場合は
                     <a
                       className="text-item mt-3 bold c-pointer"
                       onClick={() => {
                         sendPasswordDraw({me_draw, draw_id})
                       }}
                       style={{ color: '#003459' }}
                     >
                       「メール送信」
                     </a>
                     ボタンでよりご連絡下さい。
                   </p> */}
                   <Button className="btn-send-mail" variant="outlined" color="primary"
                     onClick={() => sendPasswordDraw({me_draw, draw_id, user_group_chat_id})}
                   >メール再送信
                   </Button>
                 </>
                 }
              </div>
            </DialogContent>
          )}
        </Dialog>
        {!isMobile && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              maxWidth: 550,
              backgroundColor: '#fff',
              padding: 1,
              zIndex: 1301,
              border: '2px solid red',
            }}
          >
            <Typography fontSize={'14px'}>
              本サイトでは、セキュリティ上、アクセスされた方の情報を記録しています。
            </Typography>
            <Typography fontSize={'14px'}>
              IPアドレス：{this.state.ip}
            </Typography>
            <Typography fontSize={'14px'} className='text-limit-1'>UA：{getUA}</Typography>
          </Box>
        )}

        {isMobile && this.state.openPop && (
          <Stack
            sx={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              maxWidth: 550,
              backgroundColor: '#fff',
              zIndex: 1301,
              p: 1,
              border: '2px solid red',
            }}
          >
            <Stack
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              direction="row"
            >
              <CloseRoundedIcon
                onClick={this.handleChangePop}
                sx={{
                  color: 'red',
                }}
              />
            </Stack>
            <Typography fontSize={'14px'}>
              本サイトでは、セキュリティ上、アクセスされた方の情報を記録しています。
            </Typography>
            <Typography fontSize={'14px'}>
              IPアドレス：{this.state.ip}
            </Typography>
            <Typography fontSize={'14px'} className='text-limit-1'>UA：{getUA}</Typography>
          </Stack>
        )}
      </div>
    );
  };

  render() {
    const { me_draw } = this.props;

    if (!me_draw) return null;

    if (!me_draw.id) {
      return <> {this.dialogSendPassWord()} </>;
    }

    return <Fragment>{this.props.children}</Fragment>;
  }
}
