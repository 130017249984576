import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk"
import reducer from "./reducers/index";
import Pages from "./containers/pages/index";
import '../src/assets/scss/index.scss';
import '../src/assets/scss/common.scss';
import '../src/assets/scss/single.scss';
import "react-datepicker/dist/react-datepicker.css";
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';


if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: "all"
  });
}

if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN_RUM && process.env.REACT_APP_DATADOG_APPLICATION_ID) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN_RUM,
    site: 'datadoghq.com',
    service: 'draw.mina-toku.jp',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow',
  });
  datadogRum.startSessionReplayRecording();
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  reducer,
  compose(
    composeEnhancers(applyMiddleware(thunk))
  )
);

ReactDOM.render(
  <Provider store={store}>
    <Pages />
  </Provider>,
  document.getElementById("root")
);