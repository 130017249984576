import React from 'react';
import { SIDEBAR_RIGHT } from '../../constants';
import drawIcon from "../../assets/images/drawIcon.png";
import './mobile-header-menu.scss';
import { checkRemoveFrame, removeFrameItem } from 'utils/common';
import DragDropFrames from "../frames-sidebar-right/frames-sidebar-right"
import { getUserGroupChatId } from 'constants/utils';

const MobileHeaderMenu =({
  history,
  draw_id,
  cg_id,
  paramUrl,
  handleOpenCgModal,
  isHostSide,
  frame_list=[],
  onremoveItem,
  showMenu = false,
  handleToggleMenu,
}) =>{

  const handleClickItem = (choose) => {
    switch (choose) {
      case SIDEBAR_RIGHT.CG:
        if(isHostSide){
          history.push(
            `/draws/${draw_id}/cg_property/${cg_id}`
          );
        }else{
          const user_group_chat_id = getUserGroupChatId(String(window.location.href))
          history.push(
            `/draws/${draw_id}/user_group/${user_group_chat_id}/cg_property/${cg_id}`
          );
        }
        break;
      case SIDEBAR_RIGHT.DRAW:
      default:
        if(isHostSide){
          history.push(`/draws/${draw_id}`);
        }else{
          const user_group_chat_id = getUserGroupChatId(String(window.location.href))
          history.push(`/confirm/${draw_id}/user_group/${user_group_chat_id}`);
        }
        break;
    }
  };
  const handleClickFrameItem = (choose) => {
    if(isHostSide){
      history.push(
        `/draws/${draw_id}/frame_id/${choose}`
      );
    }else{
      const user_group_chat_id = getUserGroupChatId(String(window.location.href))
      history.push(
        `/confirm/${draw_id}/user_group/${user_group_chat_id}/frame_id/${choose}`
      );
    }
  };

  const handleRemoveItem =async (frame_id)=>{
    const isConfirm = await checkRemoveFrame()
    if(isConfirm){
      const remove_data = await removeFrameItem(draw_id,frame_id)
      await onremoveItem(remove_data)
      return true
    }else{
      return false
    }
   
  }
  
  return (
    <div>
        {
            showMenu &&
            <div className="headerMenu">
                <div
                    onClick={() => handleClickItem(SIDEBAR_RIGHT.DRAW)}
                    className={`d-flex flex-column d-flex flex-column align-items-center items ${
                    paramUrl?.draw_id && !paramUrl?.cg_id && !paramUrl?.frame_id || paramUrl === "guest" ? 'item-active' : ''}`}
                    style={{marginRight:'10px'}}
                >
                    <img width={30} className='drawIcon' src={drawIcon} alt="drawIcon" />
                    <span className="fs-12px">
                    <span style={{display:'inline-block'}}>デジタル</span><span style={{display:'inline-block'}}>サイン</span>
                    </span>
                </div>
                {
                frame_list.length > 0 && <DragDropFrames frame_list={frame_list} cg_id={cg_id} isHostSide={isHostSide} paramUrl={paramUrl} handleClickFrameItem={handleClickFrameItem} handleRemoveItem={handleRemoveItem} draw_id={draw_id} />
                }
                { isHostSide &&
                <div className="d-flex flex-column item">
                    <i className="fa-solid fa-plus pb-1" onClick={handleOpenCgModal}></i>
                </div>
                }
            </div>
        }
        <div className="headerMenuToggle">
            {
                !showMenu ? 
                <p onClick={handleToggleMenu} style={{paddingTop:"4px",paddingBottom:'3px'}}>メニュー &#8744;</p>
                :
                <p onClick={handleToggleMenu}>閉じる &#8743;</p>
            }
        </div>

    </div>
  );
};
export default MobileHeaderMenu;
