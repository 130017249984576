import {combineReducers} from "redux";
import {loading} from "./loading";
import {firstLoad} from "./first_load";
import {snackbar} from "./snackbar";
import {me} from "./me";
import {me_draw} from "./me_draw";
import {me_step} from "./me_step";
import { windowSize } from "./window-size";
import {loading_status} from "./loading_status";
import {is_show_question} from "./modal_question";


const basic = combineReducers({
  loading,
  snackbar,
  me,
  me_draw,
  me_step,
  windowSize,
  firstLoad,
  loading_status,
  is_show_question
});
export default basic;