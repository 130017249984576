import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  findProcessDrawDetail,
  processAgreed,
  processConfirmed,
} from '../../../../constants/utils';
import { TOOL } from '../../../../reducers/konva-canvas/konva-canvas';
import { ImageConfirm } from '../confirm/confirm.components';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import moment from 'moment';

const Confirmed = memo(() => {
  const { draw_detail_id } = useParams();
  const history = useHistory();
  const scrollRef = useRef()

  // Get these infomation from API if user process on multi devices in same time
  const document_list = useSelector((a) => a.document).list;

  const confirmedList = useMemo(() => {
    return document_list.filter(
      (item) =>
        item.process === processAgreed.type ||
        item.process === processConfirmed.type
    );
  }, [document_list]);

  const confirmedDrawDetail = useMemo(() => {
    return confirmedList.find((item) => item.id === draw_detail_id);
  });

  useEffect(() => {
    setCurrent(confirmedList.findIndex((a) => a.id === draw_detail_id));
    // resetState()
  }, [draw_detail_id, confirmedList]);

  const [current, setCurrent] = useState(0);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [scale, setScale] = useState(1);

  if (!confirmedDrawDetail) return null;

  const { attachment, elements } = confirmedDrawDetail;
  const processing = findProcessDrawDetail(confirmedDrawDetail.process);

  const renderCanvas = () => {
    if (
      confirmedDrawDetail.process === processConfirmed.type ||
      confirmedDrawDetail.process === processAgreed.type
    ) {
      return (
        <ImageConfirm
          base64={attachment.url}
          elements={elements}
          tool={TOOL.DRAG}
          stageX={stageX}
          stageY={stageY}
          setStageX={setStageX}
          setStageY={setStageY}
          stageScale={scale}
          id={draw_detail_id}
          scrollRef={scrollRef}

        />
      );
    }
    return null;
  };

  const renderFooter = () => {
    const backgroundColor =
      confirmedDrawDetail.process === processConfirmed.type
        ? '#f2a64f'
        : '#ed4a0a';

    if (
      confirmedDrawDetail.process === processConfirmed.type ||
      confirmedDrawDetail.process === processAgreed.type
    ) {
      return (
        <div
          className="row w-100 align-items-center justify-content-end position-fixed px-4"
          style={{
            backgroundColor: backgroundColor,
            height: 50,
            bottom: 0,
          }}
        >
          <div
            className="cursor-pointer text-white text-center d-inline-flex align-items-center justify-content-center"
            style={{
              backgroundColor: processing.color,
              border: '2px solid white',
              width: 120,
            }}
            onClick={() => history.goBack()}
          >
            {processing?.title}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <div className="d-flex w-100 p-2">
        <div
          className="cursor-pointer"
          onClick={() => {
            setScale(scale * 1.2);
          }}
        >
          <ZoomInIcon
            className="icon_filter_default"
            sx={{
              color: '#000',
            }}
          />
        </div>
        <div
          className="ms-2 cursor-pointer"
          onClick={() => {
            setScale(scale / 1.2);
          }}
        >
          <ZoomOutIcon
            className="icon_filter_default"
            sx={{
              color: '#000',
            }}
          />
        </div>
        <div
          className="ms-2 cursor-pointer"
          onClick={() => {
            // setTool(TOOL.DRAG)
          }}
        >
          <OpenWithIcon className="icon_filter_default" />
        </div>
      </div>
      <div
        style={{
          width: 1050,
          height: window.innerHeight - 150,
          overflowY: 'auto',
        }}
        ref={scrollRef}
      >
        {renderCanvas()}
      </div>
      <div className="d-flex justify-content-center">
        <div
          className={
            'btn_paging text-dark mx-1 mb-2 d-flex align-items-center justify-content-center'
          }
          onClick={() => {
            if (current > 0) {
              history.replace(`/confirmed/${confirmedList[current - 1].id}`);
            }
          }}
        >
          <ArrowBackIosNewRoundedIcon />
        </div>
        {confirmedList.map((item, index) => (
          <div
            key={index}
            className={
              'btn_paging text-dark mx-1 mb-2 d-flex align-items-center justify-content-center ' +
              (item.id === confirmedDrawDetail.id ? 'active' : '')
            }
            onClick={() => {
              history.replace(`/confirmed/${item.id}`);
            }}
          >
            {index + 1}
          </div>
        ))}
        <div
          className={
            'btn_paging text-dark mx-1 mb-2 d-flex align-items-center justify-content-center'
          }
          onClick={() => {
            if (current < confirmedList.length - 1) {
              history.replace(`/confirmed/${confirmedList[current + 1].id}`);
            }
          }}
        >
          <ArrowForwardIosRoundedIcon />
        </div>
      </div>
      {renderFooter()}
      <div style={{ position: 'fixed', bottom: 60, right: 10 }}>
        {`${processing?.title} ` +
          moment(new Date(Date.parse(confirmedDrawDetail.processedAt))).format(
            'YYYY-MM-DD HH:mm:ss'
          ) ?? ''}
      </div>
    </div>
  );
});

export default Confirmed;
