import { Dialog, DialogContent, TextField } from '@mui/material';
import { COOKIE_DOMAIN, PCHAT_DOMAIN } from 'constants/setting';
import { swalFire } from 'constants/swal';
import { swalQuestion } from 'constants/swal';
import { removeCookie } from 'constants/utils';
import { getCookieXToken, requestGraphQL } from 'constants/utils';
import React, { useEffect, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { CHECK_EXIST_MAIL_GUEST, CHECK_EXIST_USER_IN_ROOM } from 'share/query';
import Swal from 'sweetalert2';

const LoginMultiGuest = () => {
  const { room_id } = useParams();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [isShowInput, setIsShowInput] = useState(false);

  const getMeDraw = async()=>{
    // const client = createClientDraw();
    const token = getCookieXToken()
    
    if(token && String(token).length > 0 && room_id){
      await requestGraphQL(CHECK_EXIST_USER_IN_ROOM,{room_id:room_id}).then(async(data) => {
        const { checkExistUserInRoom } = data;
        const {isOwnerRoom} = checkExistUserInRoom  ?? {};
        if(isOwnerRoom) {
          return swalFire({
            title: `このURLにはアクセスできません。`,
            icon: "warning",
            timer: "99999999",
            showConfirmButton: false,
          })
        }
        if(checkExistUserInRoom === false || !checkExistUserInRoom){
          // setIsShowInput(false)
          await swalQuestion({
            html: `<p style="font-size:13px; white-space: nowrap; font-weight: bold">こちらは現在ログインしているアカウントではアクセスできないURLです。</p><p style="font-size:13px;font-weight: bold">ログアウトしますか?</p>`,
            confirmButtonText: 'はい',
            cancelButtonText: 'いいえ',
            allowOutsideClick: false,
            width: 590,
          }).then(result => {
            
            if (result.isConfirmed) {
              setIsShowInput(true)
              removeCookie('x-token', COOKIE_DOMAIN);
              window.location.reload();
            }else{
              window.location.replace(`${PCHAT_DOMAIN}/r`);
            }
          });
        }else{
          history.push(`/confirm/${checkExistUserInRoom.draw_id}/user_group/${checkExistUserInRoom.user_id}`);
          window.location.reload();
        }
        return;

      })
      .catch((e) => {
        return;
      });
    }else{
      setIsShowInput(true)
    }    
  }
  useEffect(() => {
    getMeDraw()
  }, [])
  
  return (
    <Dialog
      open={true}
      sx={{
        textAlign: 'center',
      }}
      className="modal-otp"
      style={{opacity : `${isShowInput ? '1' : '0'}`}}
    >
      <DialogContent style={{ padding: '40px 20px' }}>
        <div className="mb-20px">
          <h6 className="mb-20px">プレミアムチャット</h6>
          <h6 className="mb-20px">ログインメールアドレスを入力してください</h6>
          <TextField
            className="mb-20px"
            style={{ width: '50%' }}
            type="email"
            required
            placeholder="Ex: Premiumchat@gmail.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <button
          className={`btn-pchat border-0 `}
          style={{ padding: '15px', width: '60%' }}
          onClick={async () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(email)) {
              const {checkExistMailGuest} = await requestGraphQL(CHECK_EXIST_MAIL_GUEST, {
                room_id,
                email,
              });
              const {draw_id, user_id} = checkExistMailGuest ?? {};
              if (checkExistMailGuest) {
                history.push(`/confirm/${draw_id}/user_group/${user_id}`);
                window.location.reload();
              } else {
                return await Swal.fire({
                  icon: 'error',
                  text: '指定のチャットルームURLにアクセスする権限が無いようです。',
                  showConfirmButton: true,
                })
              }
            } else {
              await Swal.fire({
                icon: 'error',
                title: 'Invalid email address',
                showConfirmButton: false,
              });
            }
          }}
        >
          メール送信
        </button>
      </DialogContent>
    </Dialog>
  );
};
export default React.memo(LoginMultiGuest);
