import React, { useCallback, useEffect, useMemo } from 'react'
import "./cg-modal.css"
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useParams,useHistory } from 'react-router-dom'
import { clientUrl, convertSameFileName, createClient, createClient3D, requestGraphQL , createClientDraw} from 'constants/utils'
import { AddNewFrameItem, checkExpired, copyUrl, getBrowser, getErrorMessage, getTitleSPByType,handleSortArray, login3DWithDraws, postMessages } from 'utils/common'

import { CG_GET_PROPERTY_VIEW, CONVERT_DATA_CSV_TO_BASE64, GET_CGS_FRAMES_INDEX, GET_DRAW_INFO, GET_RECORDS_BY_PROPERTY_ID, GET_USER_PROPERTY_BY_PROPERTY_ID } from 'share/query'
// import { AddNewFrameItem, checkExpired, copyUrl, getBrowser, getErrorMessage, getTitleSPByType, handleSortArray, login3DWithDraws, postMessages } from 'utils/common'

import { useDispatch } from 'react-redux'
import { basic_loading, basic_set_is_modal_question, basic_set_open_snackbar } from 'actions/basic'
import { Accordion, AccordionDetails, AccordionSummary, Button, Tooltip, Typography } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAccordion from 'custom-components/CustomAccordion/CustomAccordion'
import { TEXT_LIST } from '../../constants'
import { ADD_NEW_RECORD_3D, DRAW_REGISTER_USER_3D, UPDATE_CG_3D_URL, UPDATE_RECORD_3D, UPDATE_RECORD_NAME_3D, UPDATE_RECORD_SAVE_3D,UPLOAD_DRAW_DETAILS , UPLOAD_DRAW_DETAILS_BY_USER } from 'share/mutation'
import { swalFire, swalQuestion } from 'constants/swal'
import { PCHAT_DOMAIN } from 'constants/setting'
import RecordModal from 'custom-components/cg-record-modal/cg-record-modal'
import moment from 'moment'
import AccordionDraw from './DrawForCg/AccordionDraw'
import { draw_set_frame_list, draw_set_info, getDrawDetailsAction , userGetDrawDetailsAction} from 'actions/draw'
import CDocumentContent from 'ccontainers/common/cdocument-content'
import { DocumentContent } from 'containers/pages/user/draw/draw.components'
import DropzoneCustom from 'containers/common/dropzoneCustom'
import { property_set_drag_file } from 'actions/property'
import { handleDropFiles } from 'ccontainers/common/cdocument'
import { handleUserDropFiles } from 'ccontainers/cpages/user/draw/cdraw.components'
import { CSVLink } from "react-csv";
import AccordionSchedule from './DrawForCg/AccordionSchedule'
import SidebarRight from 'custom-components/sidebarRight'
import CloseSidebar from 'custom-components/closeSidebar'
import DetailIcon from '../../assets/images/detail.png'
import CG3DForm from 'custom-components/CG3DForm/CG3DForm'
import { document_set_list, document_set_storage } from 'actions/document'
import { isMobile } from 'react-device-detect'
import MobileHeaderMenu from 'custom-components/mobile-header-menu/mobile-header-menu'
import { saveAs } from 'file-saver';

const iconv = require('iconv-lite');


export default function CgModal() {
    const [drawInfo, setdrawInfo] = useState({})
    const [check, setCheck] = useState(null)
    const [propertyData, setpropertyData] = useState({})
    // const [originPropertyData, setoriginPropertyData] = useState({})
    const [userProperty, setuserProperty] = useState({})
    const [combos, setcombos] = useState([])
    const [materials, setmaterials] = useState([])
    const [expanded, setexpanded] = useState("")
    const [expandChild, setexpandChild] = useState("")
    const [extensions, setextensions] = useState([])
    const [presentBoards, setpresentBoards] = useState([])
    const [currentDetailActive, setcurrentDetailActive] = useState("")
    const [isValidBar, setisValidBar] = useState(false)
    const [isAgreed, setisAgreed] = useState(false)
    const [isSaveRecord, setisSaveRecord] = useState(false)
    const [isOpenRecordModal, setisOpenRecordModal] = useState(null)
    const [recordList, setrecordList] = useState([])
    const [temporaryRecordList, settemporaryRecordList] = useState([])
    const [newRecordName, setnewRecordName] = useState("")
    const [selectedRecord, setselectedRecord] = useState(null)
    const [totalPriceDetailModal, settotalPriceDetailModal] = useState(0)
    const [listMaterialWhenNotSelectRecord, setlistMaterialWhenNotSelectRecord] = useState([])
    const [isOpenIframeModal, setisOpenIframeModal] = useState(false)
    const [isShowMobileMenu, setisShowMobileMenu] = useState(true)
    const [temporaryExtensionList, settemporaryExtensionList] = useState([])


    

    const params = useParams();
    const { draw_id, cg_id } = useParams();
    const history = useHistory()
    const me = useSelector((a)=>a.basic.me)
    const meDraw = useSelector((a)=>a.basic.me_draw)
    const frame_list = useSelector((a)=>a.draw.frame_list)
    const property_sidebar = useSelector((state) => state?.property?.property_sidebar);
    const isShowQuestion = useSelector((state) => state?.basic?.is_show_question);

    const dispatch = useDispatch()


    const getPropertyView = async (id)=>{
        const client = createClient3D()
        let propertyData = await client.request(CG_GET_PROPERTY_VIEW,{id});
        if(propertyData.getPropertyView){

            // const 
            let filterCombos = [...propertyData.getPropertyView.bundle.combos] || []
            if(filterCombos.length > 0){
                for(let i =0; i < propertyData.getPropertyView.bundle.combos.length; i++){
                    const filter = propertyData.getPropertyView.bundle.combos[i].combo_materials.filter((m)=> m.category !== null)
                    filterCombos[i].combo_materials = filter
                }
            }
        
            setmaterials(propertyData.getPropertyView.bundle?.materials || [])
            setcombos(filterCombos)
            setextensions(propertyData.getPropertyView.bundle?.extensions || [])
            setpresentBoards(propertyData.getPropertyView.present_boards || [])
            setpropertyData(propertyData.getPropertyView)
            // const userPropertyData = await client.request(GET_USER_PROPERTY_BY_PROPERTY_ID, {property_id: propertyData.getPropertyView.id});
            // const {getUserPropertyByPropertyId} = userPropertyData;
            // setuserProperty(getUserPropertyByPropertyId)
            await handleLoadIframe(propertyData.getPropertyView)
        }
    }

    const getRecordList = async(id,drawId)=>{
        const client = createClient3D()
        const recordData = await client.request(GET_RECORDS_BY_PROPERTY_ID,{property_id:id,draw_id:drawId});
        if(recordData.getRecordByPropertyId){
            setrecordList(recordData.getRecordByPropertyId)

            const index = recordData.getRecordByPropertyId.findIndex((el)=> el.is_save === true)
            if(index > -1){
                setisAgreed(true)
            }
        }
    }
    
    const handlePropertyMapBundleData =async (propertyData) => {
        let property = propertyData;
        const get_combo_active = '';
        const get_materials = [];
        const get_extensions = [];
        const {combos, materials, extensions} = property.bundle;
        
        // On Change combo
        
          //On Change material;
        if (get_materials && get_materials.length) {
            for (const get_material of get_materials) {
            
            const {category_group, material_active, saved, agreed} = get_material
            
            const cgd_active = category_group.category_group_detail_active || '';
            const material_index = materials.findIndex(material => material.id === material_active);
            
            if (material_index > -1 && cgd_active) {
                property.bundle.materials[material_index].category_group.category_group_detail_active = cgd_active;
                property.bundle.materials[material_index].saved = !!saved; //Set saved materials
                property.bundle.materials[material_index].agreed = !!agreed; //Set agreed materials
                
                const {category_group_details} = materials[material_index].category_group;
                const cgd_index = category_group_details.findIndex(cgd => cgd.id === cgd_active);
                
                if (cgd_index > -1) {
                    await handleUpdatePropertyData(material_active, category_group_details[cgd_index],property)
                    // onChangeColor(dispatch, material_active, category_group_details[cgd_index]);
                }
            }
            }
        }
        setmaterials(property.bundle?.materials)
        setpropertyData(property)
        
          //On change extension
        //   if (get_extensions && get_extensions.length) {
        //     for (const get_extension of get_extensions) {
              
        //       const get_node_type_active = get_extension.node_type_active || '';
        //       const extension_index = extensions.findIndex(extension => extension.id === get_extension.extension_active);
              
        //       if (extension_index > -1 && get_node_type_active) {
        //         property_view.bundle.extensions[extension_index].node_type_active = get_node_type_active;
                
        //         onChangeExtension(dispatch, get_extension.extension_active, get_node_type_active);
        //       }
        //     }
        //   }
          
        //   dispatch(property_set_view(property_view));
        
        // property_sidebar.action = true;
        // property_sidebar.on_scene_load_complete = true;
        // dispatch(property_set_sidebar(property_sidebar));
    };

    const getDrawInfo = async (drawId)=>{
        dispatch(basic_loading(true))
        const client = createClient();
        const drawData = await client.request(GET_DRAW_INFO,{drawId});
        if(drawData.getDrawById.id){
            const roomIsSelected = drawData.getDrawById?.room_by_user?.find(({id}) => id === drawData.getDrawById?.room_id);
            setCheck(roomIsSelected)
            setdrawInfo(drawData.getDrawById)
            if(me){
                console.log("======= admin login =========")
                await login3DWithDraws(me,drawData.getDrawById.cg_u_id,dispatch)
            }else{
                console.log("======= guest login =========")
                await login3DWithDraws({id:drawData.getDrawById.to_user.id,email: drawData.getDrawById.to_user.email},drawData.getDrawById.cg_u_id,dispatch)
            }
            
            await getPropertyView(cg_id)
            await getRecordList(cg_id,drawId)

            dispatch(getDrawDetailsAction(drawId,meDraw))
        }
    }
    const handleLoadIframe = async (propertyData)=>{
       await onLoadIframe(propertyData)
    }
    const onLoadIframe = async (property_view) => {
        const iframe_url = process.env.REACT_APP_IFRAME
        const new_url = new URL(iframe_url);
        const check_browser = getBrowser();
        
        let iframe = document.createElement('iframe');
        iframe.key = new Date();
        iframe.id = "3d-render";
        iframe.className = "3d-render";
        iframe.src = iframe_url;
        iframe.title = "prefab 3D";
        
        if (check_browser === 'Apple Safari') {
          iframe.allow = 'fullscreen ' + new_url.origin;
        } else {
          iframe.allow = "xr-spatial-tracking";
          iframe.fullScreen = true;
        }
        
        document.getElementById('cg-3d').appendChild(iframe);    
        window.addEventListener('message', function (e) {
    
          if (e.data && e.data.iframe_loaded && !e.data.on_scene_load_complete) {
            iframe.addEventListener("load", eventLoad());
          }
          
          // Receive data from iframe.
          if (e.data && e.data.on_scene_load_complete) {
            handlePropertyMapBundleData(property_view);
            setisValidBar(true)
          }

          if(e.data && e.data.data === true){
            const detail = [
              {
                  base64:e.data.base64,
                  date:String(new Date()),
                  file_name:"screentshot3D.png",
                  name:"screentshot3D.png",
                  size:null,
                  type:'image/png'
              }
            ]
            storeScreenImage(detail)
          }
        });
        
        function eventLoad(status) {
          const {sp_root_url, sp_detail_url} = property_view.bundle;
          const url = sp_root_url;
          const url_detail = sp_detail_url;
          const time = status === 'loaded' ? 800 : 1500;
          
          const load_url = getTitleSPByType('load_url');
          const data = {type: load_url, url: url, url_detail: url_detail};
          postMessages(data);
          
          setTimeout(() => {
            dispatch(basic_loading(false));
          }, time);
        }
    };

    const handleChangeExpandChild = (data)=>{
        setexpandChild(data === expandChild ? "": data)
        setcurrentDetailActive("")
    }

    const handleCloseRecordModal = ()=>{
        setisOpenRecordModal(null)
    }

    const handleUpdatePropertyData = async(material_id,category_group_detail,propertyData)=>{
        setcurrentDetailActive(category_group_detail.category.id)
        const material_index = propertyData.bundle?.materials.findIndex(material => material.id === material_id);
        const { category } = category_group_detail;
        const { attachment } = category;
        let materialTemp = propertyData.bundle.materials;
        materialTemp[material_index].category_group.category_group_detail_active = category_group_detail.id;
        setpropertyData({
            ...propertyData,
            bundle: {
                ...propertyData.bundle,
                materials: materialTemp
            }
        })
        
        const type = getTitleSPByType('texture');
        const data = {
            type: type,
            id: category.id,
            src: attachment.file_url,
            material_name: propertyData.bundle.materials[material_index].name || ''
        };
        postMessages(data);
        // onChangeTexture(data)

    }


    const handleUpdateDataOnSave =(data)=>{
        setpropertyData(data.propertyData);
        setuserProperty(data.userProperty)
    }


    const handleChangeCombo = async(propertyData,combo) => {
        let property = propertyData;
        property.bundle.combo_active = combo.id;
        let material_active_list = []
        for(let j =0; j < combo.combo_materials.length; j++){
            let material_data = {
                category_id : combo.combo_materials[j]?.category_id,
                material_id: combo.combo_materials[j]?.material_id,
                property_id: combo.combo_materials[j]?.material.property_id,
                category_group_id: combo.combo_materials[j]?.material.category_group_id,
            }
            material_active_list.push(material_data)
        }
        settemporaryRecordList(material_active_list)
        
        
        setpropertyData(property);
        const {combo_materials} = combo;
        
        const type = getTitleSPByType('combo');
        const set_combo = combo_materials.map((combo_material) => ({
          id: combo_material.category.id,
          src: combo_material.category.attachment.file_url,
          material_name: combo_material.material.name || ''
        }));
        const data = {type, combo: set_combo};
        await postMessages(data);
    };

    const handleChangeExtension = (extension,node_type_name,node_type_new_name)=>{
        setcurrentDetailActive(node_type_new_name)
        let property = propertyData;
        const extension_index = property.bundle.extensions.findIndex(ex => ex.id === extension.id);
        property.bundle.extensions[extension_index].node_type_active = node_type_name;
        setpropertyData(property)
        let extensions = [...temporaryExtensionList];
        const extensionIndex = extensions.findIndex(ex => ex.id === extension.id);
        if(extensionIndex == -1){
            extensions.push({
                ...extension,
                node_type_active:node_type_name
            })
        }else{
            extensions[extensionIndex] = {
                ...extension,
                node_type_active:node_type_name
            }
        }        
        settemporaryExtensionList(extensions);
        
        const {node_types} = property.bundle.extensions[extension_index];
        const node_types_hide = node_types.filter(node_type =>node_type.name !== node_type_name).map(node_type => node_type.name);
        const type = getTitleSPByType('node_type');
        const data = {type, show: [node_type_name], hide: node_types_hide};
        postMessages(data);
        setisSaveRecord(true)
    }

    const handleChangePresent = (id)=>{
        setcurrentDetailActive(id)
        let property = propertyData;
        let present_boards = propertyData.present_boards;
        const index = present_boards.findIndex(present_board => present_board.id === id);
        
        if(index < 0) return;
    
        present_boards = present_boards.map((present_board) => ({...present_board, active: false}));
        present_boards[index].active = true;
    
        property.present_boards = present_boards;
        setpropertyData(property)
        
        const type = getTitleSPByType('popup');
        const data = {type: type, value: {popup_url: present_boards[index].attachment.file_url}};
        postMessages(data);
    }

    const handleDistance = (text,status)=>{
        setcurrentDetailActive(text);
        let value = 'false';
        if (status === 'title_active') {
            value = 'true';
        } else if (status === 'title_no_active') {
            value = 'false';
        }
        const distance = getTitleSPByType('distance');
        const data = {type: distance, value: value};
        postMessages(data);
    }

    const handleAddTemporaryRecordItem = (material) =>{
        let list = temporaryRecordList;
        // let expire = checkExpired(material.end_date)
        let item = {};
        const index = list.findIndex((el)=> el.material_id === material.id);
        item.property_id = material.property_id;
        const {category_group_details,category_group_detail_active} = material.category_group;
        const cgd_index = category_group_details.findIndex(cgd => cgd.id === category_group_detail_active);
        const cgd = category_group_details[cgd_index];
        item.material_id = material.id;
        item.category_group_id = material.category_group_id;
        item.category_id = cgd.category.id;
        // const upMaterialInput = {
        //     material_active: material.id,
        //     category_group_detail_active: cgd.id,
        //     category_group_detail_price_active: cgd.category_group_detail_price.id,
        // };
        // item.upMaterialInput = upMaterialInput;
        if(index > -1){
            list[index] = item;
        }else{
            list.push(item);
        }
        // console.log(temporaryRecordList)
        settemporaryRecordList([...list])
    }

    const handleAddNewRecord = async (dataName,status)=>{
        dispatch(basic_loading(true))
        const client3D = createClient3D();
        try {
            let variables = {
                record_name: dataName,
                property_id: propertyData.id,
                materialInput: temporaryRecordList,
                draw_id: drawInfo.id,
                extensionInput: temporaryExtensionList.length > 0 ? temporaryExtensionList : null
            }
            // STATUS = FALSE => CREATE NEW RECORD 
            if(!status){
                const addData = await client3D.request(ADD_NEW_RECORD_3D, variables);
                if(addData.addNewRecord){
                    const array = [];
                    settemporaryRecordList(array);
                    let list = recordList;
                    list.push(addData.addNewRecord)
                    setrecordList([...list])
                    setisOpenRecordModal(null)
                    swalFire({
                        title: `${dataName} が保存されました`,
                    });
                    setisSaveRecord(false)
                    setselectedRecord(addData.addNewRecord)
                    handleChangeRecord(addData.addNewRecord)
                }
            }else{
                variables.id = selectedRecord.id
                const addData = await client3D.request(UPDATE_RECORD_3D, variables);
                if(addData.updateRecord){
                    let list = [...recordList];
                    const index = list.findIndex((el)=> el.id === selectedRecord.id)
                    if(index > -1){
                        list[index] = addData.updateRecord;
                        setrecordList([...list])
                    }
                    setisOpenRecordModal(null)
                    swalFire({
                        title: `${addData.updateRecord.record_name}が更新されました`,
                    });
                    setisSaveRecord(false)
                    setselectedRecord(addData.updateRecord)
                    handleChangeRecord(addData.updateRecord)
                }
            }
            
            dispatch(basic_loading(false))
        } catch (error) {
            dispatch(basic_loading(false))
            const msg = getErrorMessage(error);
            swalFire({
                title: msg,
                icon:"error"
            })
        }
    }
    const handleChangeRecordName =async(data)=>{
        const client3D = createClient3D();
        try {
            const old_name = selectedRecord?.record_name;
            const updateData = await client3D.request(UPDATE_RECORD_NAME_3D, {record_name:data,id:selectedRecord.id});
            if(updateData.updateRecordName){
                let list = recordList;
                const index = list.findIndex((el)=> el.id === selectedRecord.id)
                if(index > -1){
                    list[index].record_name = data;
                    setrecordList([...list])
                }
                setisOpenRecordModal(null)
                swalFire({
                    title: `${old_name} を ${selectedRecord?.record_name} に変更しました`,
                });
            }
        } catch (error) {
            // dispatch(basic_loading(false))
            const msg = getErrorMessage(error);
            swalFire({
                title: msg,
            })
        }
    }

    const handleUpdateSaveRecord =async (status) =>{
        // UPDATE_RECORD_SAVE_3D
        const client3D = createClient3D();
        const res = await swalQuestion({
            confirmButtonText: 'OK',
            text: !status ? '確定解除してもよろしいですか？' : '確定してよろしいですか？'
        })
        if(res?.isConfirmed){
            try {
                const updateData = await client3D.request(UPDATE_RECORD_SAVE_3D, {isSave:status,id:selectedRecord.id});
                if(updateData.updateRecordSave){
                    let list = [...recordList];
                    const index = list.findIndex((el)=> el.id === selectedRecord.id)
                    if(index > -1){
                        list[index].is_save = status;
                        setrecordList([...list])
                        setisAgreed(status)
                    }
                    swalFire({
                        title: `${selectedRecord.record_name} 確定しました`,
                    });
                }
            } catch (error) {
                const msg = getErrorMessage(error);
                swalFire({
                    title: msg,
                })
            }
        }
    }

    const handleChangeRecord = (record) =>{
        let material_active_list = [];
        if(record.material_data){
            for(let i = 0; i < record.material_data.length ; i++){
                let material_data = {
                    category_id : record.material_data[i]?.category_id,
                    material_id: record.material_data[i]?.material_id,
                    property_id: record.material_data[i]?.property_id,
                    category_group_id: record.material_data[i]?.category_group_id,
                }
                material_active_list.push(material_data)
            }
        }
        
        settemporaryRecordList(material_active_list)

        if(record.extension_data && record.extension_data.length > 0){
            settemporaryExtensionList(record.extension_data);
            for(let i=0; i< record.extension_data.length; i++){
                const {node_types} = record.extension_data[i];
                const node_types_hide = node_types.filter(node_type =>node_type.name !== record.extension_data[i].node_type_active).map(node_type => node_type.name);
                const type = getTitleSPByType('node_type');
                const data = {type, show: [record.extension_data[i].node_type_active], hide: node_types_hide};
                postMessages(data);
            }
            if(propertyData.bundle.extensions && propertyData.bundle.extensions.length > 0){
                let extensions = [...propertyData.bundle.extensions]
                let property = propertyData;
                for(let i =0; i < propertyData.bundle.extensions.length; i++){
                    const index = record.extension_data.findIndex(ex => ex.id === propertyData.bundle.extensions[i].id)
                    if(index > -1){
                        extensions[i].node_type_active = record.extension_data[index].node_type_active
                    }else{
                        extensions[i].node_type_active = null
                    }
                }
                property.bundle.extensions = extensions
                setpropertyData(property)
            }
        }else{
            settemporaryExtensionList([]);
            if(propertyData.bundle.extensions && propertyData.bundle.extensions.length > 0){
                let extensions = [...propertyData.bundle.extensions]
                let property = propertyData;
                for(let i =0; i < propertyData.bundle.extensions.length; i++){
                    extensions[i].node_type_active = null
                }
                property.bundle.extensions = extensions
                setpropertyData(property)
            }
        }

        const type = getTitleSPByType('combo');
        const set_combo = record.material_data.map((material) => ({
          id: material.category_id,
          src: material.url,
          material_name: material.name || ''
        }));
        const data = {type, combo: set_combo};
        postMessages(data);
        
    }

    const caculateTotalPrice = (temporaryRecordList)=>{
        let item_price = 0;
        let array = [];
        if(temporaryRecordList){
            for(let i = 0; i< temporaryRecordList.length; i++){
                for(let j = 0; j < materials.length ; j++){
                    if(temporaryRecordList[i].material_id === materials[j].id){
                        for(let k = 0; k < materials[j].category_group.category_group_details.length; k++){
                            if(materials[j].category_group.category_group_details[k].category && materials[j].category_group.category_group_details[k].category.id === temporaryRecordList[i].category_id){
                                let price_amount = parseFloat(materials[j].area) * parseFloat(materials[j].category_group.category_group_details[k].price);
                                item_price +=price_amount
                                let category_data = {
                                    new_name: materials[j].new_name,
                                    name: materials[j].name,
                                    area: materials[j].area,
                                    object_name: materials[j].category_group.category_group_details[k].category.name,
                                    code: materials[j].category_group.category_group_details[k].category.code,
                                    price: materials[j].category_group.category_group_details[k].price,
                                }
                                array.push(category_data)
                                break;
                            }
                        }
                        
                    }
                }
            }
            setlistMaterialWhenNotSelectRecord(array)
            settotalPriceDetailModal(item_price)
        }else{
            setlistMaterialWhenNotSelectRecord([])
            settotalPriceDetailModal(0)
        }
        
        // return item_price
    }

    useEffect(() => {
        if(temporaryRecordList.length > 0){
            caculateTotalPrice(temporaryRecordList)
        }
    }, [temporaryRecordList])

    useEffect(() => {
        localStorage.removeItem("sp-client-token")
        getDrawInfo(draw_id)
    }, [draw_id,cg_id])

    const handleScreenShot = ()=>{
        const data = {type:"screen", combo: ""};
        postMessages(data);
    }

    const storeScreenImage = async(detail)=>{
        dispatch(basic_loading(true));
        try {
            if(me){
                const result = await requestGraphQL(UPLOAD_DRAW_DETAILS, {drawId:draw_id, details:detail});
                if(result.uploadDrawDetails.details.length) {
                 const payload = result.uploadDrawDetails.details.map((drawDetail) => {
                 const result =  {
                     ...drawDetail,
                     date: moment(drawDetail.createdAt),
                     base64: drawDetail.attachment.url
                 };
                 return result;
                 });
                 const listFile = convertSameFileName(payload);
                 dispatch(document_set_list(listFile));
                 dispatch(document_set_storage(listFile));
             }
             }else{
                 await requestGraphQL(UPLOAD_DRAW_DETAILS_BY_USER, {drawId:draw_id, details:detail})
                 dispatch(userGetDrawDetailsAction(draw_id))
             } 
             dispatch(basic_loading(false));
             swalFire({
                 title: "スクリーンショットがアップロードされました",
             })
        } catch (error) {
            dispatch(basic_loading(false));
            const msg = getErrorMessage(error);
            swalFire({
                title: msg,
                icon:"error"
            })
        }
       
    }


    const onDragFilesEnter = useCallback(() => {
        if(!property_sidebar.dragFile) {
            dispatch(property_set_drag_file({dragFile: true}));
          }
    }, [property_sidebar]);

    const onDragFilesLeave = useCallback(() => {
        if(property_sidebar.dragFile) {
          dispatch(property_set_drag_file({dragFile: false}));
        }
    }, [property_sidebar]);
      
    const onDropFiles = useCallback((files) => {
        {me ? handleDropFiles(files,dispatch) : handleUserDropFiles(files,dispatch)}
        dispatch(property_set_drag_file({dragFile: false}));
    }, [me]);

    const onPasteFile = useCallback((event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;
        let newFiles = []
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1 || items[i].type.indexOf('pdf') !== -1) {
                const imageFile = items[i].getAsFile();
                newFiles.push(imageFile)
            }
        }
        if(newFiles.length > 0){
            onDropFiles(newFiles)
        } 
    }, []);

    const handleAddNewFrame = async(url)=>{
        dispatch(basic_loading(true))
        try {
            const add_data = await AddNewFrameItem(drawInfo,url,dispatch);
            if(add_data && add_data.length > 0){
                const client = createClientDraw()
                const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawInfo.id})
                if(cgData.getCgAndFrameListIndex.length > 0){
                    const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                    dispatch(draw_set_frame_list(sortResult))
                    dispatch(basic_set_open_snackbar({ t: 'success', m: "追加されました" }));
                }else{
                    dispatch(draw_set_frame_list([]))
                }
            }
            dispatch(basic_loading(false))
            setisOpenIframeModal(false)
        } catch (error) {
            dispatch(basic_loading(false))
            const msg = getErrorMessage(error)
            dispatch(basic_set_open_snackbar({t:'error',m:msg}))
        }
    }

    const handleRemoveFrameItem = async(data)=>{
        if(data.status){
            const client = createClientDraw()
            const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawInfo.id})
            if(cgData.getCgAndFrameListIndex.length > 0){
                const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                dispatch(draw_set_frame_list(sortResult))
            }else{
                dispatch(draw_set_frame_list([]))
            }
            dispatch(basic_set_open_snackbar({ t: 'success', m: "削除されました" }));
        }else{
            dispatch(basic_set_open_snackbar({ t: 'error', m: data.data }));
        }
    }

    const generateCsvContent = async (csvRows,file_name) => {
        const client = createClientDraw();

        const data = await client.request(CONVERT_DATA_CSV_TO_BASE64,{data:csvRows});
        if(data.convertDataCsvToBase64){
            
            const output = iconv.decode(data.convertDataCsvToBase64, 'SJIS');
            const convertOutput = iconv.encode(output, 'SJIS');
            const csvData = atob(output);
            const uint8Array = new Uint8Array(csvData.length);
            for (let i = 0; i < csvData.length; ++i) {
            uint8Array[i] = csvData.charCodeAt(i);
            }
            const blob = new Blob([uint8Array]);
            saveAs(blob, file_name);
        }
        
    };



    const create3DUser =async (url)=>{
        const client3D = createClient3D();
        const client = createClientDraw();
        dispatch(basic_loading(true))
        // DRAW_REGISTER_USER_3D
        const variables={
            email: drawInfo.to_user.email,
            full_name: drawInfo.user_address_book.user_name ? drawInfo.user_address_book.user_name : drawInfo.to_user.name,
            url:url,
            host_id: me.id,
            id: drawInfo.to_user.id,
            draw_id: drawInfo.id
        }
        const urlArray = url.split("/");
        const property_index = urlArray.findIndex((el)=> el === "properties");
        const property_id = urlArray[property_index + 1];
        try {
            const data = await client3D.request(DRAW_REGISTER_USER_3D, variables);
            const result = data.drawRegisterUser
            if(result){
              const updateDataResult = await client.request(UPDATE_CG_3D_URL, {property_id: property_id,id:drawInfo.id,u_id:result.id})
                if(updateDataResult.updateCg3DUrl){
                    dispatch(draw_set_info({
                        ...drawInfo,
                        cg_property_id: property_id,
                    }))
                    const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawInfo.id})
                    if(cgData.getCgAndFrameListIndex.length > 0){
                        const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
                        dispatch(draw_set_frame_list(sortResult))
                    }else{
                        dispatch(draw_set_frame_list([]))
                    }
                }
            }
            setisOpenIframeModal(false)
            dispatch(basic_loading(false))

        } catch (error) {
            const msg = getErrorMessage(error);
            if(url.length <= 0){
                dispatch(basic_set_open_snackbar({ t: 'error', m: "URLを入力してください" }));
            }else{
            // dispatch(basic_set_open_snackbar({ t: 'error', m: "正しいURLを入力してください" }));
                dispatch(basic_set_open_snackbar({ t: 'error', m: msg.slice(6) }));
            }
            setisOpenIframeModal(false)
            dispatch(basic_loading(false))
        }
          
    }

  return (
    <DropzoneCustom
      isMultiple={true}
      onDropFiles={(files) => onDropFiles(files)}
      onDragFilesEnter={onDragFilesEnter}
      onDragFilesLeave={onDragFilesLeave}
      >
    <div className='cg-wrapper d-flex'
        ref={(ref) => {
            if (ref) {
                ref.addEventListener('paste', onPasteFile);
            }
        }}
    >
        <div className='cg-3d' id='cg-3d'>
            {
                isMobile && <MobileHeaderMenu
                history={history}
                draw_id={draw_id}
                paramUrl={params}
                isHostSide={me ? true : false}
                frame_list={frame_list}
                handleOpenCgModal={() => setisOpenIframeModal(true)}
                onremoveItem={handleRemoveFrameItem}
                setIsShowModal={()=> dispatch(basic_set_is_modal_question(true))}
                showMenu={isShowMobileMenu}
                handleToggleMenu={()=> setisShowMobileMenu((e)=> !e)}
            />
            }
            {
                isValidBar && <div onClick={handleScreenShot} className='screen'>スクリーンショット</div>
            }
            
            {me ? <CDocumentContent /> : <DocumentContent />}
        </div>
        <div id='sidebar' className={`cg-bar ${isShowQuestion? "question-sidebar":""}`}>
            <div className='cg-bar-list'>
                {
                    drawInfo?.name && 
                    <div className="cg-bar-item" style={{minHeight:"54px", pointerEvents: "auto", cursor: "auto"}}>
                        <Typography className="cg-info">{drawInfo.name}</Typography>
                        <Tooltip placement="bottom" arrow title="チャット画面に移動します">
                            <div 
                                onClick={() =>{
                                const {id, property_id, property_job_type_id} = drawInfo?.room ?? {};
                                if(!id) return alert("お部屋をお選びください")
                                const url = `${PCHAT_DOMAIN}/r/${property_id}/tab-id/${property_job_type_id}/general/${id}`
                                return window.open(url, "_blank")
                                }}
                                style={{display:"flex",alignItems:"center",marginRight:"10px"}}
                            >
                                {drawInfo?.room?.id && <i className="fa-regular fa-comment-dots cursor-pointer fs-18px"></i>}
                            </div>
                        </Tooltip>
                        <div className='cg-copy' onClick={() => copyUrl(`${clientUrl}/room/${drawInfo.room_id}`,dispatch)}>
                            <LinkIcon
                                style={{
                                    transform: "rotate(-45deg)",
                                    margin:  "2px 4px",
                                }} 
                            />
                        </div>
                    </div>
                }
                <div className="d-flex">
                    <div className="sidebar-left" style={{width:"83%"}}>
                        <div className=''>  
                            <Tooltip title={!isValidBar && <>三角の３Dボタンをクリックして<br/>読み込みを行なってください。</>} 
                            placement="left"  className={`${!isValidBar && 'pointer-events-all cursor-no-drop'}`}>
                            <div id='cg-tooltip-disable'>
                            {temporaryRecordList && temporaryRecordList.length > 0 ? 
                                <div className={`cg-bar-back-draw`}
                                style={{minHeight:"54px",backgroundColor:"white",border:"none",justifyContent:"space-around",alignItems:"center",cursor:"initial"}}>
                                    <p className="" style={{fontSize:"14px",color:"black"}}>オプション料金</p>
                                    <div style={{display:"flex",gap:"15px",alignItems:"center"}}>
                                        <p className="" style={{fontSize:"14px",color:"black"}}>
                                            ¥ {totalPriceDetailModal}
                                        </p>
                                        <img style={{cursor:"pointer",width:"27px"}} src={DetailIcon} alt='detail' onClick={()=>setisOpenRecordModal("view_detail")} />
                                    </div>
                                </div> : null
                            }
                            {
                                recordList.length > 0 &&
                                <Accordion
                                expanded={expanded === "records" ? true : false}
                                sx={{margin: 0,backgroundColor:"#414bb3"}}
                                className={`${!isValidBar ? 'pointer-events-none cg-bar-disable' : 'cg-bar-ac'} records`}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        onClick={() => setexpanded(expanded === "records" ? "" : "records")}
                                        className='cg-item'
                                        style={{justifyContent:"center"}}
                                    >
                                        <Typography className="ac-parent-title">
                                            保存リスト
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className='cg-details container'>
                                        <table className='table record-item-list' style={{width:"fit-content"}}>
                                            <tbody className='record-list-item-body'>
                                            {recordList.map((el,index)=>{
                                                let item_price = 0;
                                                
                                                let csvDatas = [
                                                    ["マテリアル","素材名","広さ","価格"]
                                                ];

                                                el.material_data.forEach((e)=>{
                                                    item_price += parseFloat(e.price) * parseFloat(e.area);
                                                    csvDatas.push([`${String(e.new_name.length > 0 ? e.new_name : e.name)}`,`${String(e.object_name)}`,`${String(e.area + "m2")}`,`${String(parseFloat(e.price) * parseFloat(e.area))}円`])
                                                })
                                                return (
                                                    <tr key={index} className={`${selectedRecord && selectedRecord.id === el.id ? "active" : ""}`}
                                                        onClick={()=>{
                                                            setisSaveRecord(false)
                                                            setselectedRecord(el)
                                                            handleChangeRecord(el)
                                                        }}
                                                    >
                                                        <td>
                                                            <p style={{fontSize:"13px",paddingTop:"4px"}} 
                                                            >{el.record_name}</p>
                                                        </td>
                                                        <td>
                                                            <Tooltip placement="bottom" arrow title="保存名変更">
                                                                <i className="fa-solid fa-pen-to-square"
                                                                    onClick={()=>{
                                                                        setisSaveRecord(false)
                                                                        setselectedRecord(el)
                                                                        setisOpenRecordModal("edit")
                                                                        handleChangeRecord(el)
                                                                    }}
                                                                ></i>
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip placement="bottom" arrow title="見積表示">
                                                                <i className="fa-solid fa-yen-sign"
                                                                    onClick={()=>{
                                                                        setisSaveRecord(false)
                                                                        setselectedRecord(el)
                                                                        setisOpenRecordModal("view")
                                                                        handleChangeRecord(el)
                                                                    }}
                                                                ></i>
                                                            </Tooltip>
                                                            
                                                        </td>
                                                        <td>
                                                            <p style={{fontSize:"13px",paddingTop:"4px",cursor:'text'}} 
                                                                onClick={()=>{
                                                                    setisSaveRecord(false)
                                                                    setselectedRecord(el)
                                                                    handleChangeRecord(el)
                                                                }}
                                                            >{item_price}</p>
                                                        </td>
                                                        <td>
                                                            <Tooltip placement="bottom" arrow title="見積CSVダウンロード">
                                                                <div>
                                                                    <div onClick={async()=>{
                                                                        await generateCsvContent(csvDatas,`${el.record_name}.csv`)
                                                                    }}><i className="fa-solid fa-download"></i></div>
                                                                </div>
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            {
                                                                el.is_save && <i className="fa-regular fa-square-check"></i>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                        
                                        
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {/* MATERIAL AND COMBO  */}
                            {
                                combos && combos?.length > 0 || materials && materials?.length > 0 ?
                                <Accordion
                                    expanded={expanded === "materials" ? true : false}
                                    sx={{margin: 0}}
                                    className={`${!isValidBar ? 'pointer-events-none cg-bar-disable' : 'cg-bar-ac'}`}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        onClick={() => setexpanded(expanded === "materials" ? "" : "materials")}
                                        className='cg-item'
                                        style={{justifyContent:"center"}}
                                    >
                                        <Typography className="ac-parent-title">
                                            色・設備 選択
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className='cg-details' style={{maxHeight:"450px",overflow:"scroll"}}>
                                        <div>
                                            { combos && combos.length > 0 ? (
                                                <CustomAccordion
                                                    className="container"
                                                    key="combos"
                                                    combos={combos}
                                                    onChangeExpandChild={handleChangeExpandChild}
                                                    material={{}}
                                                    id="combos"
                                                    expandChild={expandChild}
                                                >
                                                    {combos.map((combo) => {
                                                        let is_active = true;
                                                        for(let i = 0; i < temporaryRecordList.length; i++){
                                                            const index = combo.combo_materials.findIndex((el)=>el.category.id === temporaryRecordList[i].category_id && el.material_id === temporaryRecordList[i].material_id)
                                                            if(index === -1){
                                                                is_active = false
                                                            }
                                                        }
                                                        if(combo.combo_materials.length !== temporaryRecordList.length){
                                                            is_active = false
                                                        }
                                                        return (
                                                            <div className={`cg-child-item col-6 ${is_active ? "active":""}`} key={combo.id} onClick={()=>{
                                                                handleChangeCombo(propertyData,combo)
                                                                // setselectedRecord(null)
                                                                setisSaveRecord(true)
                                                            }}>
                                                                <p className='combos-item'>{combo.name}</p>
                                                            </div>
                                                        )
                                                    })}
                                                </CustomAccordion>
                                            ) : null}
                                            {/*Show materials*/}
                                            {
                                                materials.length > 0 &&
                                                materials.map((material)=>{
                                                    if (!material.category_group || !material.show) return null;
                                                    const {category_group} = material;
                                                    const {category_group_details} = category_group;
                                                    // const name = material.new_name ? material.new_name : material.name;
                                                    // const expired = checkExpired(end_date);
                                                    return (
                                                        <CustomAccordion id="material" key={material.id} className="container" material={material} expandChild={expandChild} onChangeExpandChild={handleChangeExpandChild} combos={[]} temporaryRecordList={temporaryRecordList}>
                                                            {category_group_details ? category_group_details.map(cgd => {
                                                                if(cgd.category){
                                                                    const {attachment, name} = cgd.category;
                                                                    const cgd_price = cgd.category_group_detail_price || {};
                                                                    let is_active = false;
                                                                    temporaryRecordList.forEach((item,index)=>{
                                                                        if(item.category_id === cgd.category.id && material.id === item.material_id){
                                                                            is_active = true
                                                                        }
                                                                    })
                                                                    return (
                                                                        <div className={'cg-child-item col-6'} key={cgd.category.id}                                                   
                                                                        >
                                                                            <div className={is_active ? "active":""}  
                                                                            onClick={() => {
                                                                                handleUpdatePropertyData(material.id,cgd,propertyData)
                                                                                // handleAddTemporaryItem(material)
                                                                                handleAddTemporaryRecordItem(material)
                                                                                // setselectedRecord(null)
                                                                                setisSaveRecord(true)
                                                                            }}
                                                                            >
                                                                                <img alt="" src={attachment.file_url}/>
                                                                                <span className="">{name}</span>
                                                                                <span className="">{TEXT_LIST.price}: {cgd_price.price || 0}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                
                                                            }) : null}
                                                        </CustomAccordion>
                                                        
                                                    )
                                                })
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                                : null
                            }

                            {/* EXTENSIONS  */}
                            {
                                extensions && extensions?.length > 0 &&
                            <Accordion
                                expanded={expanded === "extensions" ? true : false}
                                sx={{margin: 0}}
                                className={`${!isValidBar ? 'pointer-events-none cg-bar-disable' : 'cg-bar-ac'}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    onClick={() => setexpanded(expanded === "extensions" ? "" : "extensions")}
                                    className='cg-item'
                                    style={{justifyContent:"center"}}
                                >
                                    <Typography className="ac-parent-title">
                                        設備セレクト
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className='cg-details'>
                                    <div>
                                        {
                                            extensions.length > 0 &&
                                            extensions.map((extension)=>{
                                                const {node_types, node_type_active} = extension;
                                                return (
                                                    <CustomAccordion id="extensions" key={extension.id} className="container" material={{}} userProperty={userProperty} expandChild={expandChild} onChangeExpandChild={handleChangeExpandChild} propertyData={propertyData} onSaveData={handleUpdateDataOnSave} combos={{}} extension={extension}>
                                                        {node_types ? node_types.map(node_type => {
                                                            const active = node_type_active && node_type_active === node_type.name ? ' active' : '';
                                                            const node_type_name = node_type.new_name ? node_type.new_name : node_type.name;
                                                            return (
                                                                <div className={`cg-child-item col-6 ${currentDetailActive.length <= 0 ? active: currentDetailActive === node_type_name ? "active":""}`} key={node_type_name} onClick={()=>handleChangeExtension(extension,node_type.name,node_type_name)}>
                                                                    <p className='combos-item'>{node_type_name}</p>
                                                                </div>
                                                            )
                                                        }) : null}
                                                    </CustomAccordion>
                                                    
                                                )
                                            })
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            }

                            {/* presentation  */}
                            {
                                presentBoards && presentBoards?.length > 0 &&
                            <Accordion
                                expanded={expanded === "presentation" ? true : false}
                                sx={{margin: 0}}
                                className={`${!isValidBar ? 'pointer-events-none cg-bar-disable' : 'cg-bar-ac'}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    onClick={() => setexpanded(expanded === "presentation" ? "" : "presentation")}
                                    className='cg-item'
                                    style={{justifyContent:"center"}}
                                >
                                    <Typography className="ac-parent-title">
                                        プレゼンボード
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className='cg-details'>
                                    <div>
                                        {
                                            presentBoards.map((present_board) => {
                                                const {id, active, attachment} = present_board;
                                                if (!attachment) return null;
                                                return (
                                                <button
                                                    key={id}
                                                    className={`cg-child-item col-6 ${currentDetailActive.length <= 0 ? active: currentDetailActive === id ? "active":""}`}
                                                    onClick={() => handleChangePresent(id)}
                                                >
                                                    {present_board.name}
                                                </button>
                                                )
                                            })
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            }

                            {/* DISTANCE  */}
                            <Accordion
                                expanded={expanded === "distance" ? true : false}
                                sx={{margin: 0}}
                                className={`${!isValidBar ? 'pointer-events-none cg-bar-disable' : 'cg-bar-ac'}`}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    onClick={() => setexpanded(expanded === "distance" ? "" : "distance")}
                                    className='cg-item'
                                    style={{justifyContent:"center"}}
                                >
                                    <Typography className="ac-parent-title">
                                        計測
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails className='cg-details container'>
                                    <div className='d-flex justify-content-space-evenly'>
                                        <Button className={`cg-distance-item ${currentDetailActive === "start_distance" ? "active":""}`} onClick={()=>handleDistance("start_distance","title_active")}>測定開始</Button> 
                                        <Button className={`cg-distance-item ${currentDetailActive === "end_distance" ? "active":""}`} onClick={()=>handleDistance("end_distance","title_no_active")}>測定終了</Button>                          
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            
                            </div>
                            </Tooltip>
                        
                            {/* Accordion draw */}
                            <AccordionDraw
                                expanded={expanded}
                                setexpanded={setexpanded}
                            />

                            {/* Accordion schedule */}
                            <AccordionSchedule
                                expanded={expanded}
                                setexpanded={setexpanded}
                                draw_id={draw_id}
                            />
                            
                        </div>
                        

                        {/* bottom */}
                        <div className='bottom-accordion'>
                            {
                                temporaryRecordList.length > 0 && isSaveRecord || temporaryExtensionList.length > 0 && isSaveRecord ?
                                <Button onClick={()=>{
                                    const date = moment();
                                    const currentDate = date.format('YYYY/MM/DD HH:mm');
                                    setnewRecordName(currentDate)
                                    setisOpenRecordModal("add")
                                }} className='btn btn-active'>
                                    一時保存
                                </Button>
                                :
                                <Button className='btn'>一時保存</Button>
                            }
                            
                            {
                            isAgreed ?
                            <>
                                {
                                    me && selectedRecord && selectedRecord.is_save ? 
                                    <Button onClick={()=>{
                                        handleUpdateSaveRecord(false)
                                    }}className='btn btn-active'>確定解除</Button>
                                    :
                                    <Button className='btn'>確定</Button> 
                                }
                            </>
                            :
                            <>
                                {
                                    selectedRecord ?
                                        <Button onClick={()=>{
                                        handleUpdateSaveRecord(true)
                                    }} className='btn btn-active'>確定</Button> 
                                    :
                                    <Button className='btn'>確定</Button> 
                                    }
                                </>
                            } 
                        </div>
                    </div>
                    {
                        !isMobile &&
                        <div className='sidebar-right'>
                            <SidebarRight
                                history={history}
                                draw_id={draw_id}
                                paramUrl={params}
                                isHostSide={me ? true : false}
                                frame_list={frame_list}
                                handleOpenCgModal={() => setisOpenIframeModal(true)}
                                onremoveItem={handleRemoveFrameItem}
                                setIsShowModal={()=> dispatch(basic_set_is_modal_question(true))}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
        {
            isOpenRecordModal && <RecordModal mode={isOpenRecordModal} closeModal={handleCloseRecordModal} recordName={newRecordName} addNewRecord={handleAddNewRecord} selectedRecord={selectedRecord} updateSelectedRecord={handleChangeRecordName} temporaryRecordList={listMaterialWhenNotSelectRecord} total={totalPriceDetailModal} />
        }
        {
            isOpenIframeModal && <CG3DForm closeModal={()=>setisOpenIframeModal(false)} addNewFrame={handleAddNewFrame} create3DUser={create3DUser}/>
        }
        <CloseSidebar />
    </div>
    </DropzoneCustom>
  )

  

}
