import React, {Fragment} from 'react';

class PchatRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loaded: false}
  }
  
  async componentDidMount() {
    const current_url = window.location.href;
    if(!current_url.match(/\/draws/)) {
      return null;
    }
    
    await this.props.onInit();
    this.setState({loaded: true})
  }
  
  render() {
    if (!this.state.loaded || !this.props.me) return <div/>;
    if(window.innerWidth < 550) return <div style={{height:'100vh',width:'100wh',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <p style={{fontSize:'13px',textAlign:'center',fontWeight:'bold'}}>デジタルサインにはPCのブラウザを利用してください。</p>
    </div>
    
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    );
  }
}

export default PchatRoute;