import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
} from '@mui/material';
import * as React from 'react';

const ListUserForConfirm = ({
  listUser,
  setListUser,
  user_group_chats,
  drawDetailId,
  isAll,
  listUserMulti,
}) => {
  const handleSetListUser = React.useCallback(
    (value) => {
      if (isAll) return setListUser(value);
      return setListUser({
        drawDetailId,
        user_group_chats: value,
        listUserMulti,
      });
    },
    [isAll, drawDetailId, listUserMulti]
  );

  const renderBoxSearch = React.useMemo(() => {
    return (
      <Box className="d-flex align-items-center ">
        <p className="mr-2 keep-all">依頼先:</p>
        <TextField
          onChange={(e) => {
            const data = user_group_chats.filter((item) => {
              const { user, user_address_book } = item ?? {};
              if (
                user?.email.includes(e.target.value) ||
                user?.name.includes(e.target.value) ||
                user_address_book?.user_name.includes(e.target.value)
              ) {
                return item;
              }
            });
            if (!e.target.value) {
              handleSetListUser(user_group_chats);
            } else {
              handleSetListUser(data);
            }
          }}
          style={{ fontSize: '12px', width: isAll ? '300px' : '120px' }}
          size="small"
          label="検索"
          variant="outlined"
        />
      </Box>
    );
  }, [user_group_chats]);

  return (
    <Stack className="mt-2">
      {isAll && renderBoxSearch}
      <Box className="d-flex align-items-center ">
        {!isAll && renderBoxSearch}
        <Box className="d-flex align-items-center keep-all">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={listUser?.every(({ checked }) => checked)}
                  onChange={(e) => {
                    let arrRes = [];
                    if (e.target.checked) {
                      arrRes = listUser?.map((item) => {
                        if (!item?.checked) {
                          return {
                            ...item,
                            checked: true,
                          };
                        }
                        return item;
                      });
                    } else {
                      arrRes = listUser?.map((item) => {
                        if (item?.checked) {
                          return {
                            ...item,
                            checked: false,
                          };
                        }
                        return item;
                      });
                    }
                    handleSetListUser(arrRes);
                  }}
                />
              }
              label={`${isAll ? 'チャットルーム全員' : '全て'}`}
              labelPlacement={`${isAll ? 'end' : 'start'}`}
            />
          </FormGroup>
        </Box>
      </Box>
      <Box sx={{ maxHeight: '140px', overflow: 'scroll' }}>
        {listUser &&
          listUser?.map((item) => {
            const { id, user, user_address_book, checked } = item ?? {};
            const userName = user_address_book?.user_name ?? user?.name;
            return (
              <Box
                maxHeight={'400'}
                key={id}
                display={'flex'}
                alignItems={'center'}
                maxWidth={100}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          const res = listUser?.map((item) => {
                            if (id === item?.id) {
                              return {
                                ...item,
                                checked: e.target.checked,
                              };
                            }
                            return item;
                          });
                          handleSetListUser(res);
                        }}
                        checked={!!checked}
                      />
                    }
                  />
                </FormGroup>
                <Avatar
                  className="mr-2"
                  sx={{ width: '30px', height: '30px' }}
                  src={user?.avatar}
                ></Avatar>
                <p className="fs-12px mr-2 keep-all">{userName}</p>
                <p className="fs-12px keep-all">{user?.email}</p>
              </Box>
            );
          })}
      </Box>
    </Stack>
  );
};
export default React.memo(ListUserForConfirm);
