import { Checkbox, FormControlLabel, Modal, Popover, Typography } from "@mui/material";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import "./schedule.scss";
import { useSelector } from "react-redux";
import ScheduleDownloader from "./scheduleDownloader";

export const ModalSchedule = ({
  isModalSchedule,
  handleCloseModalSchedule,
  listDrawSchedule,
  handleChangeListTask,
}) => {
  const date_range_1 = [1, 2, 3, 4, 5, 6];
  const date_range_2 = [7, 8, 9, 10, 11, 12];
  const date_range_3 = [13, 14, 15, 16, 17, 18];
  const date_range_4 = [19, 20, 21, 22, 23, 24];
  const date_range_5 = [25, 26, 27, 28, 29, 30, 31, 32];
  const column_a_month = 5;
  let number_column = 0;
  let dates = [];

  const statusListDrawSchedule = useSelector(
    (state) => state.schedule.status_list_draw_schedule
  );

  const formatDate = (date) => {
    date = date ? moment(date) : null;
    const get_day = date ? date.date() : null;
    const get_month = date ? date.month() + 1 : null;
    const get_year = date ? date.year() : null;
    return { date, get_day, get_month, get_year };
  };

  const dateRangeColumn = (date) => {
    let range_column = 0;

    if (date_range_1.includes(date)) {
      range_column = 1;
    } else if (date_range_2.includes(date)) {
      range_column = 2;
    } else if (date_range_3.includes(date)) {
      range_column = 3;
    } else if (date_range_4.includes(date)) {
      range_column = 4;
    } else if (date_range_5.includes(date)) {
      range_column = 5;
    }
    return range_column;
  };

  const AddLineStyle = () => {
    const th_line = document.getElementById("th-line");
    if (th_line) {
      const x = th_line.offsetWidth;
      const y = th_line.offsetHeight;
      const rotate = (Math.atan2(y, x) * 180) / Math.PI - 0.1;
      const width = Math.ceil(Math.sqrt(x * x + y * y));

      let line = document.getElementById("line");
      line.style.width = `${width}px`;
      line.style.borderBottom = "1px solid #0000009c";
      line.style.transformOrigin = "left";
      line.style.transform = `rotate(${rotate}deg)`;
    }

    return <></>;
  };

  let spec_overviews = [...listDrawSchedule].map((item) => {
    return {
      ...item,
      id: item.id,
      name: item.content,
      start_date:
        item.start_time && moment(item.start_time).format("YYYY-MM-DD"),
      end_date:  item.end_time && moment(item.end_time).format("YYYY-MM-DD"),
    };
  });

  spec_overviews = spec_overviews.sort((a, b) =>
    moment(a.end_date).diff(moment(b.end_date))
  );

  spec_overviews.forEach((spec_overview) => {
    if (spec_overview.start_date) dates.push(moment(spec_overview.start_date));
    if (spec_overview.end_date) dates.push(moment(spec_overview.end_date));
  });

  //Min end date
  const min_date = moment.min(dates);
  const min_year = min_date.year();
  const min_month = min_date.month() + 1;

  //Max end date
  const max_date = moment.max(dates);
  const max_year = max_date.year();
  const max_month = max_date.month() + 1;

  //Create year and month
  let year_ranges = [];
  //EX 2022 !== 2023
  if (min_year !== max_year) {
    for (let y = min_year; y <= max_year; y++) {
      let year = { year: y, months: [] };

      if (y === max_year) {
        for (let m = 1; m <= max_month; m++) {
          year.months.push(m);
        }
      } else if (y > min_year && y < max_year) {
        for (let m = 1; m <= 12; m++) {
          year.months.push(m);
        }
      } else {
        for (let m = min_month; m <= 12; m++) {
          year.months.push(m);
        }
      }

      year_ranges.push(year);
    }
  } //EX 2022 === 2022
  else {
    let year = { year: min_year, months: [] };
    for (let m = min_month; m <= max_month; m++) {
      year.months.push(m);
    }
    year_ranges.push(year);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverSelect, setPopoverSelect] = useState();
  const handleCloseAnchorEl = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleClickAnchorEl = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const popoverCellDetail = useMemo(() => {
    if (!popoverSelect) return;
    const { content, end_time, from_user, to_user } = popoverSelect;
    return (
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseAnchorEl}
      >
        <div className="full p-10px btn-color-light-blue">
          <Typography>{content}</Typography>
          <div>
            <span className="mr-30px">
              担当: {from_user && (from_user.name || from_user.email) || "担当なし"}
            </span>
            <span>
              完了確認: {(to_user && (to_user.name || to_user.email)) || "なし"}
            </span>
          </div>
          <div className="d-flex justify-content-between align-item-center ">
            <p>{moment(end_time).format("YYYY年MM月DD日")} ({checkDate(moment(end_time).format("YYYY-MM-DD"))})</p>
          </div>
        </div>
      </Popover>
    );
  }, [anchorEl, popoverSelect, handleCloseAnchorEl]);

  function checkDate(datetime){
 
    const dayData = [
      "日","月","火","水","木","金","土"
    ]
    const date = moment(datetime);
    const dow = date.day();
    return dayData[dow]
  }
  const caculatorDate = useMemo(()=>{
    let dates =[]
    let months = []
    
    for(let i =0; i < year_ranges.length; i++){
        for(let j = 0; j < year_ranges[i].months.length ; j++){
          months.push({
            amount : moment(`${year_ranges[i].year}-${year_ranges[i].months[j]}`, "YYYY-MM").daysInMonth(),
            year : year_ranges[i].year,
            month: year_ranges[i].months[j],
          })
        }
    }
    for(let i =0; i < months.length ; i ++){
      let day = 7;
      let firstDay  = 1;
      while (day <= Number(months[i].amount)){
        let firstTime = `${months[i].year}-${months[i].month}-${firstDay}`;
        let lastTime = `${months[i].year}-${months[i].month}-${day}`;
        dates.push(<th key={i} height="42px" style={{borderBottom:"1px solid #8f8f8f"}} > <span className={`${checkDate(firstTime) === "日" && "ca_active"} ${checkDate(firstTime) === "土" && "cu_active"}`}>{checkDate(firstTime)}:</span > {months[i].month}/{firstDay}<br/>~<br/> <span className={`${checkDate(lastTime) === "日" && "ca_active"} ${checkDate(lastTime) === "土" && "cu_active"}`}>{checkDate(lastTime)}:</span> {months[i].month}/{day}</th>)
        if(day == Number(months[i].amount)) break;
        firstDay = day +1;
        if(day + 6 > Number(months[i].amount)){
          day =  Number(months[i].amount); 
         
        }else{
          day += 6; 
        }
      }
    }
 
    return dates;
  },[year_ranges])

  const sortedSchedule = useMemo(() => {
    if(listDrawSchedule.length) {
      return [...listDrawSchedule].sort((a, b) =>  moment(a.updatedAt).diff(moment(b.updatedAt)))
    } else {
      return ""
    }
  }, [listDrawSchedule])

  return (
    <Modal open={isModalSchedule} onClose={handleCloseModalSchedule}>
      <div className="cwp">
          <div className="c-close" onClick={handleCloseModalSchedule}>X</div>
          <div className="btn-print-schedule" onClick={handleCloseModalSchedule}>
            <ScheduleDownloader label={"ダウンロード"} rootElementId={"schedule-table"} />
            </div>
          <div className="modal-schedule" role="region" aria-labelledby="caption" tabindex="0">
            <table className="mb-0 border-spacing-0 ctable" id="schedule-table">
              <thead>
               
                <tr className="fs-14px">
                  <th className="ct02">
                      <FormControlLabel
                          className="hidden-when-download"
                          control={
                            <Checkbox
                              defaultValue={statusListDrawSchedule.isShowTaskFinish}
                              onChange={(e) =>
                                handleChangeListTask({ isShowTaskFinish: e.target.checked })
                              }
                              checked={statusListDrawSchedule.isShowTaskFinish}
                            />
                          }
                          label="完了も表示"
                          id="hidden-schedule-checkbox"
                        />
                        {sortedSchedule.length > 0 && <br className="hidden-when-download"/>}
                        {sortedSchedule.length > 0 && <p>{sortedSchedule[0].property_name || ''}</p>} 
                        {sortedSchedule.length > 0 && `最終更新: ${moment(sortedSchedule[sortedSchedule.length-1].updatedAt).format("YYYY/MM/DD HH:mm")}`} 
                  </th>
                  <th rowSpan={4} className="fs-16px" style={{ borderBottom:"1px solid #8f8f8f" }}>
                    担<br/>当<br/>者
                  </th>
                  {year_ranges.map((year_range) => {
                    const { year, months } = year_range;
                    const col_span = months.length * column_a_month;
                    number_column += col_span;

                    return (
                      <th
                        key={year}
                        colSpan={col_span}
                        style={{ background: "#9ac1e5" }}
                      >
                        {year}年
                      </th>
                    );
                  })}
                </tr>
                <tr className="fs-14px">
                 <th
                    id="th-line"
                    rowSpan={3}
                    className="pd0 ct01"
                    width={200}
                    height={88}
                    style={{ borderBottom:"1px solid #8f8f8f",padding:"0px" }}
                  >
                    <div className="div-line">
                      <div className="month">スケジュール</div>
                      <div className="process">タスク</div>
                      <div className="line hidden-when-download" id="line" />
                    </div>
                  </th>
                 </tr>
                <tr className="fs-14px">
                  {year_ranges.map((year_range) => {
                    const { months } = year_range;
                    
                    return months.map((month) => {
                      return (
                        <th
                          style={{ background: "#deeaf7" }}
                          key={year_range + "/" + month}
                          colSpan={column_a_month}
                        >
                          {month}月
                        </th>
                      );
                    });
                  })}
                </tr>
                <tr className="fs-14px">
                  {caculatorDate}
                </tr>
              </thead>
              <tbody className="text-center">
                {spec_overviews.map((spec_overview) => {
                  let { name, start_date, end_date, id, status, from_user } = spec_overview;
                  let run = false;

                  end_date = formatDate(end_date).date;
                  const end_date_day = formatDate(end_date).get_day;
                  const end_date_month = formatDate(end_date).get_month;
                  const end_date_year = formatDate(end_date).get_year;

                  start_date = formatDate(start_date).date;
                  const start_date_day = formatDate(start_date).get_day;
                  const start_date_month = formatDate(start_date).get_month;
                  const start_date_year = formatDate(start_date).get_year;

                  const end_range_column = dateRangeColumn(end_date_day);
                  const start_range_column = dateRangeColumn(start_date_day);

                  return (
                    <tr key={id}>
                      <th className="fs-14px " style={{ background: "#e1efda",zIndex:"2" }}>
                       <div className="d-flex justify-content-between align-item-center">
                          <span>{name}</span>
                          {status && <span className="text-red">完了済</span>}
                       </div>
                      </th>
                      <th className="fs-14px">
                        {from_user && from_user.id && (from_user.name || from_user.email)}
                      </th>
                      {!end_date
                        ? [...new Array(number_column).keys()].map((column) => {
                            return <td key={column} />;
                          })
                        : year_ranges.map((year_range) => {
                            const { months, year } = year_range;

                            return months.map((month) => {
                              return [1, 2, 3, 4, 5].map((column) => {
                                const show_end_date =
                                  end_date_month === month &&
                                  end_date_year === year &&
                                  end_range_column === column
                                    ? end_date.format("M/D")
                                    : null;
                                const show_start_date =
                                  start_date_month === month &&
                                  start_date_year === year &&
                                  start_range_column === column
                                    ? start_date.format("M/D")
                                    : null;

                                if (show_start_date) {
                                  run = true;
                                  let double = '';
                                  if(start_range_column == end_range_column){
                                    double = <span> {show_end_date && "~"} {show_end_date && (<br/>)} {show_end_date} {show_end_date &&`(${checkDate(end_date.format("YYYY-MM-DD"))})`}</span> ;
                                  }
                                  return (
                                    <td
                                      onClick={(e) => {
                                        handleClickAnchorEl(e);
                                        setPopoverSelect(spec_overview);
                                      }}
                                      className={`cell-hover ${status ? 'cell-finish' : null}`}
                                      key={column}
                                    >
                                      {show_start_date} {show_start_date && `(${checkDate(start_date.format("YYYY-MM-DD"))})`} <br/> {double}
                                    </td>
                                  );
                                } else if (show_end_date) {
                                  run = false;
                                  return (
                                    <td
                                      onClick={(e) => {
                                        handleClickAnchorEl(e);
                                        setPopoverSelect(spec_overview);
                                      }}
                                      className={`cell-hover ${status ? 'cell-finish' : null}`}
                                      key={column}
                                    >
                                       {show_end_date} {show_end_date && `(${checkDate(end_date.format("YYYY-MM-DD"))})`} 
                                    </td>
                                  );
                                } else if (run) {
                                  if(start_range_column != end_range_column ||  start_range_column == end_range_column && end_date_month != start_date_month){
                                    return (
                                      <td
                                        onClick={(e) => {
                                          handleClickAnchorEl(e);
                                          setPopoverSelect(spec_overview);
                                        }}
                                        className={`cell-hover ${status ? 'cell-finish' : null}`}
                                        key={column}
                                      />
                                    );
                                  }else{
                                    return <td key={column} />;
                                  }
                                  
                                } else {
                                  return <td key={column} />;
                                }
                              });
                            });
                          })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {popoverCellDetail}
            <div className="hidden" id="skew-line-schedule">
              {setTimeout(() => {
                AddLineStyle();
              }, 0)}
            </div>
          </div>
      </div>
    </Modal>
  );
};
export default React.memo(ModalSchedule);
