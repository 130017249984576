import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
} from '@mui/material';
import { removeHyphenPhoneNumber, requestGraphQL } from 'constants/utils';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { METHOD_RECEIVE_OTP, PLACEHOLDER_CONTENT } from '../../constants';
import './style.scss';
import { REGISTER_ACCOUNT_USER_BY_SHARE_ID_GENERAL } from 'share/mutation';
const RegisterGuest = () => {
  const { room_id } = useParams();
  const history = useHistory();
  const { SMS, EMAIL } = METHOD_RECEIVE_OTP;
  const [user, setUser] = useState({
    email: '',
    email_confirm: '',
    user_name: '',
    phone_number: '',
  });
  const [error, setError] = useState();
  const onChangeLoginInfo = useCallback((e) => {
    const { name, value } = e?.target ?? {};
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const submitRegister = useCallback(async () => {
    const { phone_number, method, ...restUser } = user ?? {};
    const { registerAccountUserByShareIdGeneral } = await requestGraphQL(
      REGISTER_ACCOUNT_USER_BY_SHARE_ID_GENERAL,
      {
        room_id,
        phone_number: removeHyphenPhoneNumber(phone_number),
        method: method ?? METHOD_RECEIVE_OTP.SMS,
        isSendOTP: true,
        ...restUser,
      }
    );
    if (registerAccountUserByShareIdGeneral) {
      const { linkDraw } = registerAccountUserByShareIdGeneral ?? {};
      history.push(linkDraw);
      window.location.reload();
    }
  }, [room_id, user]);
  return (
    <Dialog open={true}>
      <DialogContent style={{ padding: '40px', fontSize: '14px' }}>
        <div className="modal-content ">
          <h4 className="title mb-10px">ブラウザでアクセス</h4>
          <p className="under-title mb-10px">
            チャットを行うためのURLをお送りします。
            <br />
            メールアドレスと氏名を入力して、送信ボタンをクリックして下さい。
          </p>
          <div className="send-mail mb-10px">
            <div className="mb-10px">
              <label>メールアドレス</label>
              <div className="input">
                <Input
                  className="p-10px fs-12px"
                  type="text"
                  name="email"
                  sx={{ width: '100%' }}
                  placeholder={
                    PLACEHOLDER_CONTENT.please_enter_an_email_address_that_you_can_receive
                  }
                  onChange={(e) => onChangeLoginInfo(e)}
                  value={user?.email}
                />
              </div>
            </div>
            <div className="mb-10px">
              <label>確認用メールアドレス</label>
              <div className="input">
                <Input
                  className="p-10px fs-12px"
                  type="text"
                  name="email_confirm"
                  sx={{ width: '100%' }}
                  placeholder={
                    PLACEHOLDER_CONTENT.please_enter_an_email_address_that_you_can_receive
                  }
                  onChange={(e) => onChangeLoginInfo(e)}
                  value={user?.email_confirm}
                />
              </div>
            </div>
            <div className="mb-10px">
              <label>氏名</label>
              <div className="input" style={{ width: '60%' }}>
                <Input
                  type="text"
                  name="user_name"
                  className="mb-10px fs-12px"
                  placeholder={PLACEHOLDER_CONTENT.full_name}
                  onChange={(e) => onChangeLoginInfo(e)}
                  value={user?.user_name}
                />
              </div>
            </div>

            <div className="mb-10px">
              <label>{PLACEHOLDER_CONTENT.phone_number}</label>
              <div className="input" style={{ width: '60%' }}>
                <Input
                  className="mb-10px fs-12px"
                  name="phone_number"
                  placeholder={PLACEHOLDER_CONTENT.phone_number}
                  onChange={(e) => onChangeLoginInfo(e)}
                  value={user?.phone_number}
                  type="tel"
                  maxLength={13}
                />
              </div>
            </div>

            {!error ? null : (
              <p
                className="text-danger"
                dangerouslySetInnerHTML={{ __html: error }}
              />
            )}
          </div>
          <FormControl className="align-items-center mb-10px">
            <RadioGroup
              row
              name="method"
              defaultValue={SMS}
              onChange={(e) => onChangeLoginInfo(e)}
            >
              <FormControlLabel
                value={SMS}
                control={<Radio color="default" />}
                label="SMS(電話番号)"
              />
              <FormControlLabel
                value={EMAIL}
                control={<Radio color="default" />}
                label="メールアドレス"
              />
            </RadioGroup>
          </FormControl>
          <div className="d-flex justify-content-center">
            <Button
              className="btn-register"
              onClick={() => {
                const { email, email_confirm, phone_number, user_name } = user ?? {};
                if(!email?.length || !email_confirm?.length || !phone_number?.length || !user_name?.length) {
                    console.log('1')
                     setError("* 完全な情報を入力してください")
                }else if(email !== email_confirm) {
                    console.log('2')
                     setError("* 確認メールが正しくありません")
                } else {
                    console.log('3')
                    submitRegister();
                }
                
              }}
            >
              ワンタイムパスワード 送信
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default React.memo(RegisterGuest);
