import { Box, Button, Modal, Tooltip } from '@mui/material';
import { downloadCombinePDFAction } from 'actions/draw';
import { sortDocuments } from 'constants/services';
import { findProcessDrawDetail, processAgreed, processCancelRequest, processCancelRequestConfirm, processConfirmed, processRequest, processRequestConfirm, requestGraphQL, uniqueArray } from 'constants/utils';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_SUB_DRAW } from 'share/query';
import { TYPE_PDF } from '../../constants';
import DragDropContextCombine from './dragDropContextCombine';
import './style.scss';
import { basic_set_loading } from 'actions/basic';
import CustomIconConfirm from 'containers/common/customIconConfirm';
import CustomProcessCancel from 'containers/common/customProcessCancel';

const CombineImageIntoPdf = ({ isModalCombinePdf, setModalCombinePdf }) => {
  const [listImage, setListImage] = useState([]);
  const [listImageDrag, setListImageDrag] = useState([]);
  const [imgHover, setImgHover] = useState();
  const [step, setStep] = useState(1);
  const [documentList, setDocumentList] = useState([]);

  const dispatch = useDispatch();
  const list = useSelector((state) => state?.document?.list);
  const me_draw = useSelector(
    (state) => state?.basic?.me ?? state?.basic?.me_draw
  );
  const selectedImageIds = useSelector(
    (state) => state?.konvaCanvas?.selectedImageIds
  );

  useEffect(() => {
    const document_list = [];
    const listImageSelected = [];

    for (const document of list) {
      if (selectedImageIds?.find((item) => item === document?.id)) {
        listImageSelected.push(document);
      }
      const date = moment(document.date).format('YYYY/MM/DD');
      const index = document_list.findIndex((s) => s.date === date);
      if (index > -1) {
        document_list[index].images.push(document);
      } else {
        document_list.push({ date: date, images: [document] });
      }
    }
    setDocumentList(document_list);
    setListImage(listImageSelected);
    setListImageDrag(listImageSelected);
  }, [list, selectedImageIds, isModalCombinePdf]);

  const handleSelecImage = useCallback(
    (image, checked) => {
      let listImageRes = [];
      if (checked) {
        listImageRes = uniqueArray([...listImage, image]);
      } else {
        listImageRes = uniqueArray(
          listImage.filter((item, index) => item?.id !== image?.id)
        );
      }
      setListImage(listImageRes);
      setListImageDrag(listImageRes);
    },
    [listImage]
  );

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const listImageCopy = listImageDrag.slice();
      const [removed] = listImageCopy.splice(result.source.index, 1);
      listImageCopy.splice(result.destination.index, 0, removed);
      setListImageDrag(listImageCopy);
    },
    [listImageDrag]
  );

  const handleRemoveItemDrag = useCallback(
    ({ id }) => {
      const res = listImageDrag.filter((item) => item?.id !== id);
      if (!res) return;
      setListImageDrag(res);
      setListImage(res);
    },
    [listImageDrag]
  );

  const handleBackStep = useCallback(() => {
    setStep(1);
    setListImageDrag(listImage);
  }, [listImage]);

  const downloadCombinePdf = useCallback(async () => {
    const { file_same_name } = listImageDrag?.[0] ?? {};
    const handle = await window.showSaveFilePicker({
      suggestedName: file_same_name,
      types: [
        {
          accept: {
            'text/plain': ['.pdf'],
          },
        },
      ],
    });
    if (!handle) return;
    dispatch(
      downloadCombinePDFAction({
        listImage: listImageDrag,
        handle,
      })
    );
    resetModal();
  }, [listImageDrag]);

  const resetModal = useCallback(() => {
    setStep(1);
    setModalCombinePdf();
    setListImage([]);
    setListImageDrag([]);
  }, [setModalCombinePdf, setStep, setListImage, setListImageDrag]);

  const handleCallSubDrawList = useCallback(async () => {
    const data = [];
    dispatch(basic_set_loading(true));
    for (let item of listImageDrag) {
      data.push(item);
      const { attachment, id } = item ?? {};
      if (attachment?.file_type !== TYPE_PDF) continue;
      const subDraw = await requestGraphQL(GET_SUB_DRAW, {
        draw_parent_id: id,
      });
      const subDrawData = subDraw?.getSubDraws;
      if (!subDrawData || !subDrawData?.length) continue;
      data.push(...subDrawData);
    }
    setListImageDrag(data);
    dispatch(basic_set_loading(false));
  }, [listImageDrag]);

  const dragDropContextItem = useCallback(
    ({ sortedImages }) => {
      return (
        <div className="d-flex flex-wrap">
          {sortedImages.map((image, key) => {
            const { file_same_name, attachment, title, id, process, to_confirmed, user_confirm_draws } = image;
            const background = process && String(process).includes("confirm") ? '#f8a64e' :'#ff0000';
            let badgeName ;
            let isWarning = null ;
            const processDrawDetail = findProcessDrawDetail(process);
            badgeName = processDrawDetail ? processDrawDetail.title : null;
            if(user_confirm_draws?.find(({to_confirmed}) => !to_confirmed)) {
              if(process === processRequestConfirm.type|| process === processConfirmed.type) {
                badgeName = '確認中';
              } else if(process === processRequest.type || process === processAgreed.type) {
                badgeName = '記入中';
              }
            }
            if (image.from_confirmed && !image.to_confirmed && (process === processCancelRequest.type || process === processCancelRequestConfirm.type)) {
              badgeName = '差戻し';
              isWarning = true
            }
            // if(to_confirmed){
            //   badgeName = process === "confirmed" ? '確認済み' : '記入済み'
            // }else{
            //   if(process && process.includes("cancel")){
            //     badgeName = '差戻し'
            //     isWarning = true
            //   }else{
            //     badgeName = process === "request-confirm" ? '要確認' : '要記入'
            //   }  
            // }
            const position = listImage.findIndex((item) => item?.id === id);
            return (
              <Tooltip
                placement="top"
                arrow
                title={imgHover?.id === id && file_same_name}
                key={key}
              >
                <div
                  key={key}
                  className={`cursor-pointer d-flex position-relative mr-2 mt-2`}
                  onMouseOver={(e) => {
                    setImgHover(image);
                  }}
                >
                  {position < 0 && imgHover?.id === id && (
                    <input
                      type="checkbox"
                      className="checkbox-original"
                      onChange={(e) => {
                        handleSelecImage(image, e.target.checked);
                      }}
                      checked={position >= 0}
                    />
                  )}
                  {position >= 0 && (
                    <input
                      type="checkbox"
                      className="checkbox-active"
                      onChange={(e) => {
                        handleSelecImage(image, e.target.checked);
                      }}
                      checked={position >= 0}
                    />
                  )}
                  {
                    process && 
                    <div className="sort-confirmIcon" style={{background:`${background}`}}>
                      {badgeName}
                    </div>
                  }
                  {
                    process && to_confirmed && user_confirm_draws?.every(({to_confirmed}) => to_confirmed) && <CustomIconConfirm />
                  }
                  {
                    process && !to_confirmed && isWarning!= null && <CustomProcessCancel isWarningRed={isWarning} top={40} />
                  }
                  <img
                    src={attachment?.url}
                    alt={title}
                    loading="lazy"
                    width={120}
                    height={120}
                    className="objectFitCover"
                  />
                </div>
              </Tooltip>
            );
          })}
        </div>
      );
    },
    [listImage, imgHover]
  );

  return (
    <Modal id="documents" open={isModalCombinePdf} onClose={resetModal}>
      <Box className="d-flex justify-content-center align-items-center h-100vh w-100vw">
        <Box className={`modal-combine-pdf views ${step === 1 ? 'step1':''}`}>
          <i
            className="fa-solid fa-xmark closeModalIcon"
            onClick={resetModal}
          ></i>
          <h4 className="text-center font-weight-bold mb-3 text-main">
            ファイルをまとめてダウンロード
          </h4>
          <h6 className="text-center text-main mb-3">
            {step === 1
              ? 'まとめたいファイルをチェックして「次へ」をクリック'
              : 'まとめたい順にファイルを左右にドラッグで並べ替えて「まとめてダウンロード」をクリック'}
          </h6>
          {step === 2 && (
            <>
              <p className="mb-2">並び順</p>
              <div className="arrow"></div>
            </>
          )}
          {step == 1 && (
            <>
              {documentList?.map(({ date, images }) => {
                const sortedImages = sortDocuments(images, me_draw?.id);
                return (
                  <div className={date} key={date}>
                    <p className="date">{date}</p>
                    {dragDropContextItem({ sortedImages })}
                  </div>
                );
              })}
              <Box className="box-btn">
                <Button
                  className={`btnItem ${
                    listImage?.length && 'bg-blue pointer-events-all'
                  }`}
                  onClick={() => {
                    setStep(2);
                    handleCallSubDrawList();
                  }}
                >
                  次へ（並び替える）
                </Button>
              </Box>
            </>
          )}

          {step === 2 && (
            <DragDropContextCombine
              listImage={listImage}
              setListImage={setListImage}
              handleBackStep={handleBackStep}
              onDragEnd={onDragEnd}
              handleRemoveItemDrag={handleRemoveItemDrag}
              listImageDrag={listImageDrag}
              downloadCombinePdf={downloadCombinePdf}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};
export default React.memo(CombineImageIntoPdf);
