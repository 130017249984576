import React, { PureComponent, useMemo } from 'react';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { CDrawSideBar } from '../../../../ccontainers/cpages/user/draw/cdraw.components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  TEXT_LIST,
  WIDTH_MOBILE,
  WIDTH_TABLET,
} from '../../../../constants/index';
import { DocumentContent, UserPreviewDialog } from './draw.components';
import { Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import './draw.css';
import DropzoneCustom from 'containers/common/dropzoneCustom';
import CloseSidebar from 'custom-components/closeSidebar';
import { processCancelRequest, processCancelRequestConfirm } from 'constants/utils';

export default class Draw extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openPreview: false,
      width: window.innerWidth,
    };
  }

  handleWindowResize = () => {
    this.setState({ ...this.state, width: window.innerWidth });
  };

  async componentDidMount() {
    const { match, getDrawInfomation, closeSideBar, property_sidebar } =
      this.props;
    if (match.params.draw_id) {
      await getDrawInfomation(match.params.draw_id);

      closeSideBar(false, property_sidebar);
    }
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  render() {
    const {
      property_sidebar,
      closeSideBar,
      changeSidebar,
      history,
      document_list,
      match,
      setOpenConfirmNotification,
      openNotification,
      document_info,
      onDropFiles,
      onDragFilesEnter,
      onDragFilesLeave,
      windowSize,
      firstLoad,
      setFirstLoad,
      handlePasteFile,
      userPrimary,
      me,
      confirmList,
    } = this.props;
    const { status } = property_sidebar;
    const { width } = this.state;

    const isMobile = width < WIDTH_MOBILE ? true : false;
    const isTablet =
      width > WIDTH_MOBILE && width < WIDTH_TABLET ? true : false;

    // const requestConfirmList = document_list.filter(
    //   (item) => item.from_confirmed && !item.to_confirmed&& item?.process !== processCancelRequest.type && item?.process !== processCancelRequestConfirm.type
    // );
    const requestConfirmList = () => {
      // if (!!confirmList?.length) return confirmList;
      let result = document_list?.map((item) => {
        if(!!item?.user_confirm_draws?.length) {
          if(item.from_confirmed) {
            if(item?.user_confirm_draws?.find((t) => (t?.user?.id === me?.id && !t?.to_confirmed && t?.process !== processCancelRequest.type && t?.process !== processCancelRequestConfirm.type))) {
              return item;
            }
          }
        } else {
          if(item.from_confirmed && !item.to_confirmed && item?.process !== processCancelRequest.type && item?.process !== processCancelRequestConfirm.type) {
            return item;
          }
        }
      })
      result = result?.filter((item) => item !== undefined)
      return result;
    };
    if(firstLoad && requestConfirmList()) {
      if(requestConfirmList().length > 0) {
        setFirstLoad();
        this.setState({ openPreview: false });
        if(userPrimary?.user) {
          history.push(
            `/confirm/${match.params.draw_id}/details/${requestConfirmList()[0].id}/user_group/${userPrimary?.id}`
          );
        } else {
          history.push(
            `/confirm/${match.params.draw_id}/details/${requestConfirmList()[0].id}`
          );
        }
        
      }
    }


    return (
      <DropzoneCustom
      isMultiple={true}
      // onDropFiles={(files) => onDropFiles(files, this.props.match.params.draw_id)}
      // onDragFilesEnter={onDragFilesEnter}
      // onDragFilesLeave={onDragFilesLeave}
      >
        <div id="shapespark" className={isMobile ? "" : "w-100 position-fixed top-0"} 
          // ref={(ref) => {
          //   if (ref) {
          //       ref.addEventListener('paste', handlePasteFile);
          //   }
          // }}
          >
          <main className="main">
            {
              isMobile && 
              <CDrawSideBar history={history} />
            }
            <div
              className="render d-flex align-items-center justify-content-around content"
              id="render"
              style={{height: windowSize.height || window.innerHeight}}
            >
              {document_info && document_info.id ? (
                <DocumentContent />
              ) : (
                <div className="text-center">
                  <p style={{ marginBottom: '300px' }}>
                    ファイルをアップロードもしくは選択してください
                  </p>
                </div>
              )}
            </div>
            {!isMobile && <CloseSidebar />}
            {!isMobile && <div
              id="sidebar"
              className={'sidebar ' + (this.props.isShowQuestion ? "question-sidebar":"")}
              style={{
                borderLeft: isTablet ? '0.5px solid rgba(0,0,0,0.1)' : '',
                ...isMobile ? {} : {height: windowSize.height || window.innerHeight}
              }}
            >
              {status ? <CDrawSideBar history={history} /> : null}
            </div>}
          </main>
        </div>
        <Dialog
          open={openNotification && requestConfirmList().length > 0}
          onClose={() => setOpenConfirmNotification(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogContent>
            <Typography
              align="center"
              variant="h5"
              padding={5}
              sx={{
                fontSize: '1.25rem',
              }}
            >
              確認依頼が届いています。
            </Typography>
            <div className="d-flex justify-content-center mb-4">
              <button
                className="btn-main"
                onClick={() => setOpenConfirmNotification(false)}
              >
                キャンセル
              </button>
              <button
                className="btn-main ms-2 btn-primary"
                onClick={() => {
                  setOpenConfirmNotification(false);
                  this.setState({
                    openPreview: true,
                  });
                }}
              >
                確認する
              </button>
            </div>
          </DialogContent>
        </Dialog>
        <Modal
          open={this.state.openPreview && requestConfirmList().length > 0}
          onClose={() => {
            this.setState({ openPreview: false });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: window.innerWidth * 0.9,
              height: isMobile ? 'max-content' : 700,
              bgcolor: '#fff',
              border: '2px solid #000',
              boxShadow: 24,
              p: 3,
            }}
          >
            <Box marginY={3}>
              <UserPreviewDialog list={requestConfirmList()} />
            </Box>
            <div className="d-flex justify-content-center">
              <button
                className="btn-pchat border-0"
                onClick={async () => {
                  if(userPrimary?.user) {
                    history.push(
                      `/confirm/${match.params.draw_id}/details/${requestConfirmList()[0].id}/user_group/${userPrimary?.id}`
                    );
                  } else {
                    history.push(
                      `/confirm/${match.params.draw_id}/details/${requestConfirmList()[0].id}`
                    );
                  }
                  this.setState({ openPreview: false });
                }}
              >
                確認を開始する
              </button>
            </div>
          </Box>
        </Modal>
      </DropzoneCustom>
    );
  }
}
