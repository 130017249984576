import {connect} from "react-redux";
import Property from '../../containers/pages/property'
import { property_set_drag_file, property_set_sidebar, property_set_view} from "../../actions/property";
import { draw_set_frame_list, draw_set_info, getDrawDetailsAction, sub_draw_set_info, uploadFileDrawDetailsAction } from "../../actions/draw";
import { onChangeOpenRegisterUser } from "../common/cregister-user";
import { document_set_dialog, document_set_info } from "../../actions/document";
import { TOOL } from "../../reducers/konva-canvas/konva-canvas";
import { KONVA_CHANGE_TOOL } from "../../constants/store";
import { initKonvaAction, setKonvaElementsAction } from "../../actions/konva-canvas";
import { basic_loading, basic_open_snackbar, basic_set_is_modal_question, basic_set_loading, basic_set_open_snackbar } from "actions/basic";
import { convertBase64PdfToImage, convertToBase64, createClient3D, createClientDraw, onPasteFile, requestGraphQL, sizeImageMax } from "constants/utils";
import { getListDrawSchedule, getListUserBelongDraw } from "actions/schedule";
import { CHECK_PERMISSION_USER_ACCESS_TO_DRAW, GET_CGS_FRAMES_INDEX, GET_SUB_DRAW } from "share/query";
import { handleDropFiles } from "ccontainers/common/cdocument";
import { DRAW_REGISTER_USER_3D, UPDATE_CG_3D_URL } from "share/mutation";
import { AddNewFrameItem, getErrorMessage, handleSortArray, login3DWithDraws } from "utils/common";
import { UPDATE_REAL_TIME_DRAW } from "constants/index";
import { swalFire } from "constants/swal";

let property_sidebar = {};
let property_view = {};
let document_info = null;
let document_list = [];
let selectedImageIds = [];
let statusListDrawSchedule = {};
let drawDetail;
let me;
let loadUi = false;

const mapStateToProps = (state) => {
  property_sidebar = state.property.property_sidebar;
  property_view = state.property.view;
  document_info = state.document.info;
  document_list = state.document.list;
  selectedImageIds = state.konvaCanvas.selectedImageIds;
  statusListDrawSchedule = state.schedule.status_list_draw_schedule;
  drawDetail = state.draw.info;
  me = state.basic.me;
  return {
    me: state.basic.me,
    property_sidebar: state.property.property_sidebar,
    property_view: state.property.view,
    property_info: state.property.info,
    register_draw: state.draw.register_draw,
    draw_info: state.draw.info,
    document_info,
    document_list,
    windowSize: state.basic.windowSize,
    selectedImageIds,
    drawDetail,
    frame_list: state.draw.frame_list,
    isShowQuestion: state.basic.is_show_question,
    loadUi:loadUi,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onInit: async ({drawId, history, me, draw_detail_id}) => {
      if(!me?.company) {
        history.push(`/confirm/${drawId}`)
        window.location.reload();
      }
      const {checkPermissionUserAccessToDraw} = await requestGraphQL(CHECK_PERMISSION_USER_ACCESS_TO_DRAW,{draw_id: drawId});
      if(!checkPermissionUserAccessToDraw) {
        return swalFire({
          title: `このURLにはアクセスできません。`,
          icon: "warning",
          timer: "99999999",
          showConfirmButton: false,
        })
      }
      const {role, isShowTaskFinish} = statusListDrawSchedule;
        await dispatch(getDrawDetailsAction(drawId))
        loadUi = true;
        dispatch(getListDrawSchedule({draw_id: drawId, role, isShowTaskFinish}));
        dispatch(getListUserBelongDraw({draw_id: drawId}));
        if(!document_info.id && document_list.length) {
          const documentSelected = document_list.find((item) => item.id === draw_detail_id) ?? document_list[0];
          dispatch(document_set_info(documentSelected));
          const client = createClientDraw()
          dispatch(basic_set_loading(true));
          await client.request(GET_SUB_DRAW, {draw_parent_id: documentSelected.id}).then((data) => {
            let subData = data.getSubDraws
            if(subData.length > 0){
              subData.unshift(documentSelected)
            } 
            dispatch(sub_draw_set_info(subData));
          })
          dispatch(document_set_dialog(true));
          dispatch({type: KONVA_CHANGE_TOOL, payload: TOOL.DRAG});
          dispatch(initKonvaAction())
          dispatch(setKonvaElementsAction({elements: documentSelected.elements ?? []}));
          dispatch(basic_set_loading(false));
        }
        onChangeClose(dispatch, false);

        localStorage.removeItem("sp-client-token")
        if(drawDetail && drawDetail.cg_u_id && me){
          //handle login 3D if exist
          await login3DWithDraws({id:drawDetail.to_user.id, email: drawDetail.to_user.email},drawDetail.cg_u_id,dispatch)
        }
      
    },
    onChangeSidebar: (property_sidebar, type) => {
      if (property_sidebar.type === type) {
        property_sidebar.status = !property_sidebar.status;
      } else {
        property_sidebar.status = true;
      }
      
      property_sidebar.type = type;
      onChangeClose(dispatch, !property_sidebar.status);
      
      dispatch(property_set_sidebar(property_sidebar));
    },
    onChangePropertyList: (history) => {
      dispatch(property_set_sidebar({}));
      dispatch(property_set_view({}));
      return history.push('/');
    },
    onChangeClose: (property_sidebar, status) => {
      onChangeClose(dispatch, status);
    },
    onChangeColor: (material_id, category_group_detail) => {
      onChangeColor(dispatch, material_id, category_group_detail);
    },
    onChangeCombo: (combo) => {
      onChangeCombo(dispatch, combo);
    },
    onChangeExtension: (extension_id, node_type_name) => {
      onChangeExtension(dispatch, extension_id, node_type_name);
    },
    onCopyUrl: (url) => {
      navigator.clipboard.writeText(url);
      dispatch(
        basic_set_open_snackbar({
          t: 'success',
          m: 'URLがコピーされました',
        })
      );
    },
    onDropFiles: async (files, drawId) => {
      handleDropFiles(files,dispatch,3)
      dispatch(property_set_drag_file({dragFile: false}));
    },
    onDragFilesEnter: () => {
      if(!property_sidebar.dragFile) {
        dispatch(property_set_drag_file({dragFile: true}));
      }
    },
    onDragFilesLeave: () => {
      if(property_sidebar.dragFile) {
        dispatch(property_set_drag_file({dragFile: false}));
      }
    },
    onChangeDistance: (active) => {
      let value = 'false';
      if (active === 'title_active') {
        value = 'true';
      } else if (active === 'title_no_active') {
        value = 'false';
      }
      property_sidebar.distance = active;
      dispatch(property_set_sidebar(property_sidebar));
      
      const data = {type: "distance", value: value};
      postMessage(data);
    },
    focusIframe: () => {
      // document.getElementById('3d-render').contentWindow.focus();
    },
    onChangeOpenRegisterUser: () => {
      onChangeOpenRegisterUser({dispatch});
    },
    handleCreate3DUser:async({url, history}) =>{
      const client3D = createClient3D();
      const client = createClientDraw();
      // DRAW_REGISTER_USER_3D
      const variables={
        email: drawDetail?.to_user?.email,
        full_name: drawDetail?.user_address_book?.user_name ? drawDetail?.user_address_book?.user_name : drawDetail?.to_user?.name,
        url:url,
        host_id: me.id,
        id: drawDetail?.to_user?.id,
        draw_id: drawDetail?.id
      }
      const urlArray = url.split("/");
      const property_index = urlArray.findIndex((el)=> el === "properties");
      const property_id = urlArray[property_index + 1];
      try {
        await client3D.request(DRAW_REGISTER_USER_3D, variables).then((data) => {
          const result = data.drawRegisterUser
          if(result){
            client.request(UPDATE_CG_3D_URL, {property_id: property_id,id:drawDetail.id,u_id:result.id}).then((data) => {
              if(data.updateCg3DUrl){
                dispatch(draw_set_info({
                  ...drawDetail,
                  cg_property_id: property_id, 
                }))
                history.push(`/draws/${drawDetail?.id}/cg_property/${property_id}`);
              }
            }).catch((err)=>{
              const msg = getErrorMessage(err);
              dispatch(basic_set_open_snackbar({ t: 'error', m: msg.slice(6) }));
            })
          }
        })
      } catch (error) {
        if(url.length <= 0){
          dispatch(basic_set_open_snackbar({ t: 'error', m: "URLを入力してください" }));
        }else{
          dispatch(basic_set_open_snackbar({ t: 'error', m: "正しいURLを入力してください" }));
        }
      }
    },
    handlePasteFile:async(event) =>{
      
      // handleDropFiles(files,dispatch,3)
      onPasteFile(event,dispatch,true)
      dispatch(property_set_drag_file({dragFile: false}));
    },
    handleRemoveFrameItem:async(data) =>{
      if(data.status){
        const client = createClientDraw()
        const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawDetail.id})
        if(cgData.getCgAndFrameListIndex.length > 0){
            const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
            dispatch(draw_set_frame_list(sortResult))
        }else{
          dispatch(draw_set_frame_list([]))
        }
        dispatch(basic_set_open_snackbar({ t: 'success', m: "削除されました" }));
      }else{
          dispatch(basic_set_open_snackbar({ t: 'error', m: data.data }));
      }
    },
    handleAddNewFame: async(url)=>{
      dispatch(basic_loading(true))
      try {
        const add_data = await AddNewFrameItem(drawDetail,url,dispatch);
        if(add_data && add_data.length > 0){
          const client = createClientDraw()
          const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:drawDetail.id})
          if(cgData.getCgAndFrameListIndex.length > 0){
              const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
              dispatch(draw_set_frame_list(sortResult))
              dispatch(basic_set_open_snackbar({ t: 'success', m: "追加されました" }));
          }
        }
        dispatch(basic_loading(false))
      } catch (error) {
        dispatch(basic_loading(false))
        const msg = getErrorMessage(error)
        dispatch(basic_set_open_snackbar({t:'error',m:msg}))
      }
      
    },
    onCloseQuestion: async()=>{
      dispatch(basic_set_is_modal_question(true))
    }, 
  }
};

const onChangeColor = (dispatch, material_id, category_group_detail) => {
  const material_index = property_view.bundle.materials.findIndex(material => material.id === material_id);
  const { category } = category_group_detail;
  const { attachment } = category;
  
  property_view.bundle.materials[material_index].category_group.category_group_detail_active = category_group_detail.id;
  dispatch(property_set_view(property_view));
  
  // const type = getTitleSPByType('texture');
  const data = {
    type: "type",
    // type: type,
    id: category.id,
    src: attachment.file_url,
    material_name: property_view.bundle.materials[material_index].name || ''
  };
  postMessage(data);
};

const onChangeCombo = (dispatch, combo) => {
  property_view.bundle.combo_active = combo.id;
  dispatch(property_set_view(property_view));
  const {combo_materials} = combo;
  
  // const type = getTitleSPByType('combo');
  const set_combo = combo_materials.map((combo_material) => ({
    id: combo_material.category.id,
    src: combo_material.category.attachment.file_url,
    material_name: combo_material.material.name || ''
  }));
  
  const data = {type:"type", combo: set_combo};
  // const data = {type, combo: set_combo};
  postMessage(data);
};

const onChangeExtension = (dispatch, extension_id, node_type_name) => {
  const extension_index = property_view.bundle.extensions.findIndex(extension => extension.id === extension_id);
  property_view.bundle.extensions[extension_index].node_type_active = node_type_name;
  dispatch(property_set_view(property_view));
  
  const {node_types} = property_view.bundle.extensions[extension_index];
  const node_types_hide = node_types.filter(node_type => node_type.name !== node_type_name).map(node_type => node_type.name);
  
  // const type = getTitleSPByType('node_type');
  const data = {type: "type", show: [node_type_name], hide: node_types_hide};
  // const data = {type, show: [node_type_name], hide: node_types_hide};
  postMessage(data);
};

const onChangeClose = (dispatch, status) => {
  const sidebar = document.getElementById('sidebar');
  const render = document.getElementById('render');
  if (!sidebar || !render) return;
  
  if (status) {
    sidebar.style.display = 'none';
    render.style.width = '100%';
    property_sidebar.status = false;
    dispatch(property_set_sidebar(property_sidebar));
  }
  else {
    // sidebar.style.display = 'flex';
    render.style.width = 'calc(100% - 280px)';
    sidebar.style.display = 'unset';
  }
};

const postMessage = (data) => {
  document.getElementById('3d-render').contentWindow.postMessage(data, '*');
};

const CProperty = connect(mapStateToProps, mapDispatchToProps)(Property);

export default CProperty;