import React, { useMemo } from 'react';
import { TEXT_LIST } from '../../constants/index';
import { SortColumn } from '../../constants/helper';
import moment from 'moment';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Pagination,
  Typography,
  DialogTitle,
  TextField,
  Dialog,
  Box,
  Button,
  Chip,
  Tooltip,
  Autocomplete,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { redirectToLoginPage } from '../../constants/utils';

class Draws extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      url3D: '',
    };
  }

  async componentDidMount() {
    const { me } = this.props;
    if (me) {
      await this.props.onInit({ me });

      this.setState({
        loaded: true,
        url3D: `/draws/`,
      });
    }
  }

  formatDate(date) {
    return moment(date).format('yyyy-MM-DD/HH:mm') || '';
  }

  render() {
    const {
      register_draw,
      list,
      onChangeRemove,
      filter,
      paging,
      onSort,
      onChangeOpenRegisterDraw,
      onChangeOpenRegisterUser,
      onChangePage,
      onChangeFilter,
      me,
      onCopyUrl,
      onChangeMailConfirmed,
      onSelectRoomForSendMessageConfirm,
      room_select,
      navigateToChatRoom,
    } = this.props;

    if (!this.state.loaded || !filter) return <div />;

    const first_index = 10 * paging.current_page - 10 + 1 || 1;

    const renderValueSelectRoom = ({room_by_user, room_id}) => {
       const valueDefault = room_by_user?.find(
        (item) => item?.id === room_id
      );
      return valueDefault ?? null;
    };

    return (
      <div className="container pt-4">
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <h4>{me.name} プロジェクト一覧</h4>
          <button
            className="btn-main ml-3"
            onClick={() => redirectToLoginPage()}
          >
            {TEXT_LIST.logout}
          </button>
        </Box>
        <div className="w-100 text-right pt-3 pb-3">
          <TextField
            id="standard-basic"
            variant="standard"
            placeholder={'検索'}
            onChange={(e) => onChangeFilter('name', e.target.value)}
          />
          <button
            className="btn-main ml-3"
            onClick={() => onChangeOpenRegisterDraw({ register_draw })}
          >
            新規作成
          </button>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" className="d-flex">
                プロジェクト名
                <SortColumn data={{ name: 'name', filter, onSort }} />
              </th>
              {process.env.REACT_APP_CURR_ENV !== "production" && <th scope="col">チャットルーム名</th>}
              <th scope="col">作成日</th>
              <th scope="col">更新日</th>
              <th scope="col">お客様名</th>
              <th scope="col">
                <span>メールアドレス</span>
                <Tooltip
                  title="メールアドレスの認証前はメールアドレスの訂正が可能です。"
                  arrow
                >
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </th>
              <th scope="col">
                <span>認証状況</span>
                <Tooltip title="メールアドレスの認証状況です。" arrow>
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </th>
              <th scope="col">{TEXT_LIST.delete}</th>
              <th scope="col">URL</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '0.9rem' }}>
            {list.map((draw, index) => {
              const current_index = first_index + index;
              const {
                id,
                name,
                createdAt,
                updatedAtDetail,
                to_user,
                user_name,
                user_address_book,
                room_by_user,
                room_id
              } = draw;
              const roomIsSelected = room_by_user?.find(({isSelect}) => isSelect);
              return (
                <tr key={id}>
                  <td>{current_index}</td>
                  <td>
                    <a
                      target={'_blank'}
                      href={this.state.url3D + id}
                      className="d-inline-block px-2 cursor-pointer text-underline"
                    >
                      {name}
                    </a>
                  </td>
                  {/* {process.env.REACT_APP_CURR_ENV !== 'production' && (
                    <td className='d-flex align-items-center'>
                      <Autocomplete
                        size="small"
                        autoHighlight
                        options={room_by_user || []}
                        className='mr-2'
                        sx={{ width: 150, fontSize: '0.1rem' }}
                        getOptionLabel={(option) =>
                          option?.property?.variety_house?.name
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{...params.InputProps, sx: { fontSize: '0.9rem' } }}
                            InputLabelProps={{ sx: { fontSize: '0.9rem' } }}
                            label="チャットルーム名選択"
                          />
                        )}
                        onChange={(_, value) =>
                          onSelectRoomForSendMessageConfirm({
                            value,
                            draw_id: id,
                          })
                        }
                        value={roomIsSelected}
                      />
                     {
                      roomIsSelected &&
                      <div onClick={() => navigateToChatRoom(roomIsSelected)}>
                        <i className="fa-regular fa-comment-dots cursor-pointer fs-18px"></i>
                      </div>
                     }
                    </td>
                  )} */}
                  <td>{this.formatDate(createdAt)}</td>
                  <td>{this.formatDate(updatedAtDetail)}</td>
                  <td>
                    {to_user && to_user.name ? (
                      to_user.name
                    ) : (
                      user_address_book && user_address_book.user_name ?  user_address_book.user_name :
                      <button
                        className="btn-main"
                        onClick={() => onChangeOpenRegisterUser(draw)}
                      >
                        {' '}
                        招待{' '}
                      </button>
                    )}
                  </td>
                  <td>
                    {to_user?.email}　
                    {!to_user?.mailconfirmed && (
                      <Button
                        size="small"
                        color="info"
                        variant="outlined"
                        onClick={() =>
                          onChangeMailConfirmed({
                            id: user_address_book?.id,
                            email: to_user?.email,
                          })
                        }
                      >
                        訂正
                      </Button>
                    )}
                  </td>
                  <td>
                    {to_user?.mailconfirmed ? (
                      <Chip color="primary" size="small" label="認証済み" />
                    ) : (
                      <>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          label="未認証"
                        />
                      </>
                    )}
                  </td>
                  <td className="text-primary">
                    {to_user ? (
                      '削除不可'
                    ) : (
                      <button
                        className="btn-main text-danger"
                        onClick={() => onChangeRemove(id, name)}
                      >
                        {TEXT_LIST.delete}
                      </button>
                    )}
                  </td>
                  <td>
                    {to_user ? (
                      <Button
                        className="btn-main btn-pchat text-white"
                        onClick={() => onCopyUrl(id)}
                      >
                        コピー
                      </Button>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          className="d-flex justify-content-center"
          page={paging.current_page || 1}
          count={paging.total_page}
          variant="outlined"
          shape="rounded"
          onChange={(event, value) => onChangePage(value)}
        />
        {this.registerDrawDialog()}
      </div>
    );
  }

  registerDrawDialog() {
    const {
      register_draw,
      onChangeOpenRegisterDraw,
      onChangeValueRegister,
      registerDraw,
    } = this.props;
    if (!register_draw || !register_draw.open_dialog) return <div />;

    const { name } = register_draw;
    return (
      <div>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="text-center">プロジェクト作成</DialogTitle>
          <DialogContent>
            <DialogContentText>
              プロジェクト名をご入力ください
              <br />
            </DialogContentText>
            <div className="w-100 pt-3 d-flex">
              <label className="m-auto pr-3 fs-1rem">プロジェクト名</label>
              <TextField
                size="small"
                sx={{ width: '300px' }}
                onChange={(e) =>
                  onChangeValueRegister(register_draw, 'name', e.target.value)
                }
                value={register_draw.name}
              />
            </div>
          </DialogContent>
          <DialogActions className="m-auto pb-4">
            <button
              className="btn-main mr-3"
              onClick={() => onChangeOpenRegisterDraw({ register_draw })}
            >
              {TEXT_LIST.cancel}
            </button>
            <button className="btn-main" onClick={() => registerDraw({ name })}>
              {TEXT_LIST.create}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Draws;
