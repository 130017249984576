import { ImageListItemBar } from "@mui/material";
import React, { memo } from "react";

export default memo(({processing}) => {
    return  <ImageListItemBar
          title={processing?.title}
          position="top"
          sx={{
            background: processing?.badge ? processing?.badge : processing?.color,           
            fontSize: "14px",
            height: "24px",
            lineHeight: "24px",
            textAlign: "center",
            top: "10px",
            left: "auto",
            right: "10px"
          }}
        />
})