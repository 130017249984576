import { Box, Button, ImageListItem, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import spacely from "../../assets/images/spacely.png";
import matterport from "../../assets/images/matterport.png";
import cgIcon from "../../assets/images/cg.png";
import youtube from "../../assets/images/youtube.png";
import unknown from "../../assets/images/unknown.png";
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage, handleSortArray, updateFrameIndex } from 'utils/common';
import { basic_loading, basic_set_open_snackbar } from 'actions/basic';
import { draw_set_frame_list } from 'actions/draw';
import { createClientDraw, getUserGroupChatId } from 'constants/utils';
import { GET_CGS_FRAMES_INDEX } from 'share/query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isMobile } from 'react-device-detect';
const imgs = {
    'spacely':spacely,
    'matterport':matterport,
    'youtube':youtube,
    'youtu.be':youtube,
    '3dcg':unknown,
    '3d-cg':unknown,
    'gousei':unknown,
  }
const DragDropFrames = ({
  handleClickFrameItem,
  frame_list = [],
  handleRemoveItem,
  paramUrl,
  draw_id,
  cg_id,
  isHostSide,
  handleRemoveCg
}) => {
  const [imgHover, setImgHover] = useState();
  const dispatch = useDispatch();
  const loading = useSelector((a)=>a.basic.loading)
  const me = useSelector((a)=>a.basic.me)
  const drawInfo = useSelector((a)=>a.draw.info)
  const history = useHistory()

  const getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: 'none',
      ...draggableStyle,
    };
  };

  const getListStyle = () => ({
    display: 'flex',
    padding: '0px',
    flexDirection: isMobile ? 'row' : 'column',
    alignItems: isMobile ? 'baseline' : 'center',
    // overflow: 'auto',
    marginBottom: isMobile ? '0px' : '5px',
    // flexDirection:'column',
    maxHeight:'calc(100vh - 260px)',
    gap:isMobile ? '8px' : '0px',
    // overflowY:'scroll',
    // overflowX:'hidden'
  });

  const onDragEnd = async(dropData)=>{ 
    if (!dropData.destination || dropData.destination.index == dropData.source.index || loading) {
        return;
    }
    dispatch(basic_loading(true))
    const temporaryList = [...frame_list];
    let newSortArray = temporaryList.slice();
    const [removed] = newSortArray.splice(dropData.source.index, 1);
    newSortArray.splice(dropData.destination.index, 0, removed);
    dispatch(draw_set_frame_list(newSortArray))

    
    const data = await updateFrameIndex(draw_id,dropData.destination.index,dropData.source.index,dropData.draggableId);
    if(data.status){
      try {
        const client = createClientDraw()
        const cgData = await client.request(GET_CGS_FRAMES_INDEX,{draw_id:draw_id})
        if(cgData.getCgAndFrameListIndex.length > 0){
            const sortResult = cgData.getCgAndFrameListIndex.sort(handleSortArray)
            dispatch(draw_set_frame_list(sortResult))
        }else{
          dispatch(draw_set_frame_list([]))
        }
      } catch (error) {
        const msg = getErrorMessage(error)
        dispatch(basic_set_open_snackbar({ t: 'error', m: msg }));
        dispatch(basic_loading(false))
      }
    }else{
        dispatch(basic_set_open_snackbar({ t: 'error', m: data.data }));
    }
    dispatch(basic_loading(false))   

    
  }

  const handleClickCg = (id)=>{
    if(isHostSide){
      window.location.replace(`${window.location.origin}/draws/${draw_id}/cg_property/${id}`)
    }else{
      const user_group_chat_id = getUserGroupChatId(String(window.location.href))
      window.location.replace(`${window.location.origin}/confirm/${draw_id}/user_group/${user_group_chat_id}/cg_property/${id}`)
    }
  }

  const dragDropContextItem = useMemo(() => { 
    return (
      <>
        {frame_list.length > 0 && frame_list.map((frame, key) => {
          return (
            <Draggable key={frame.id} draggableId={frame.id} index={key} isDragDisabled={isMobile ? true : false}>
              
              {(provided, snap) => 
                <div
                  
                  key={frame.id}
                  className={`d-flex flex-column item ${frame.cg_property_id ? 'align-items-center p-10':'frame_item'}  ${
                  paramUrl === frame.id ? 'item-active' : ''
                  } ${
                    frame.cg_property_id && paramUrl.cg_id === frame.cg_property_id ? 'item-active' : ''
                    }`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                      snap.isDragging,
                      provided.draggableProps.style
                  )}
                  onMouseOver={(e) => {
                      setImgHover(frame);
                  }}
                >
                  {
                    frame.cg_property_id ? 
                    
                    <Tooltip placement="left" arrow title="CG（動画・VR）">
                      <img width={31} className='drawIcon' src={cgIcon} alt="cgIcon" onClick={()=>{
                      if(frame.cg_property_id){
                        handleClickCg(frame.cg_property_id)
                      }
                    }}/>
                    </Tooltip>
                    :
                    <img width={31} style={{margin:"auto"}} className='drawIcon' src={imgs[frame.type]} alt={frame.type} onClick={() => handleClickFrameItem(frame.id)}/>
                  }
                  {
                    frame.cg_property_id ? 
                    
                    <Tooltip placement="left" arrow title="CG（動画・VR）">
                      <span className="fs-14px" onClick={()=>{
                      if(frame.cg_property_id){
                        handleClickCg(frame.cg_property_id)
                      }
                    }}>CG</span>
                    </Tooltip>
                    :
                    <span style={{fontSize:"10px",paddingTop: isMobile ? '3px' : '0px'}} onClick={() => handleClickFrameItem(frame.id)}>
                    {frame.type}
                    </span>
                  }
                  
                  
                  {paramUrl !== frame.id && !frame.cg_property_id && <p className='remove-frame' onClick={()=>handleRemoveItem(frame.id)}><i className="fa-solid fa-xmark"></i></p>}
                  {frame.cg_property_id && isHostSide && <p className='remove-cg' style={{position:"absolute",top:'0px',right:'0px'}} onClick={async()=>{
                    const isDelete = await handleRemoveCg(frame.cg_property_id)
                    if(frame.cg_property_id && paramUrl.cg_id === frame.cg_property_id && isDelete){
                        history.push(`/${me.id ? 'draws' : 'confirm'}/${draw_id}`);
                    }
                  }}><i className="fa-solid fa-xmark"></i></p>}
                </div>
              }
            </Draggable>
          );
        })}
      </>
    );
  }, [frame_list, imgHover,paramUrl,draw_id]);

  return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical" isDropDisabled={isMobile ? true : false}>
          {(provided, snap) => (
            <div
              ref={provided.innerRef}
              style={getListStyle()}
              {...provided.droppableProps}
              className='dragdrop-wp'
              id='dragdrop-wp'
            >
              {dragDropContextItem}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
  );
};
export default React.memo(DragDropFrames);
